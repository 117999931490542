import { BehaviorSubject, Observable } from 'rxjs';

import { Store } from '../abstracts/store.abstract';

export class TestingStoreService extends Map<string, unknown> implements Store {
    private readonly _subject$: BehaviorSubject<Map<string, unknown>> = new BehaviorSubject(new Map());

    public override set(key: string, value: unknown): this {
        super.set(key, value);
        this._subject$.next(this);
        return this;
    }

    public override delete(key: string): boolean {
        const result = super.delete(key);
        this._subject$.next(this);
        return result;
    }

    public override get(key: string): unknown {
        return super.get(key) as string;
    }

    public override clear(): void {
        super.clear();
        this._subject$.next(this);
    }

    public asObservable$(): Observable<Map<string, unknown>> {
        return this._subject$.asObservable();
    }
}
