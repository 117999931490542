import { Directive, inject, OnDestroy, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { filter, Subject, takeUntil } from 'rxjs';

@Directive({
    selector: '[erToUpperCase]',
    standalone: true
})
export class ErToUpperCaseDirective implements OnInit, OnDestroy {
    private readonly _ngControl = inject(NgControl);
    private readonly _destroy$: Subject<null> = new Subject();

    public ngOnInit(): void {
        this._ngControl.control?.valueChanges
            .pipe(
                filter(value => typeof value === 'string'),
                filter(value => value !== value.toUpperCase()),
                takeUntil(this._destroy$)
            )
            .subscribe(value => {
                this._ngControl.control?.patchValue(value.toUpperCase());
            });

        this._ngControl.control?.updateValueAndValidity();
    }

    public ngOnDestroy(): void {
        this._destroy$.next(null);
        this._destroy$.complete();
    }
}
