import { Observable } from 'rxjs';

import { IViewModel } from '../../../../domain/interfaces';
import {
    IActivateAccountPayload,
    IForgotPasswordPayload,
    IResetPasswordPayload,
    IRetrieveUsernameIdPayload,
    IUser
} from '../interfaces';

export abstract class AuthenticationManagementRepositoryAbstract {
    public abstract resetPassword$(payload: IResetPasswordPayload): Observable<IViewModel<boolean> | null>;
    public abstract forgetPassword$(payload: IForgotPasswordPayload): Observable<IViewModel<boolean> | null>;
    public abstract createPassword$(payload: IActivateAccountPayload): Observable<IViewModel<boolean> | null>;
    public abstract retrieveUsernameId$(payload: IRetrieveUsernameIdPayload): Observable<IViewModel<IUser> | null>;
}
