import { inject, Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { isEmpty, isNil } from 'ramda';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { mapOnError } from '../../../../domain/rxjs/operators/map-on-error.operator';
import { EUserErrors } from '../../domain/enums/exceptions';
import { AuthenticationManagementUseCaseAbstract } from '../../domain/usecases/authentication-management.usecase.abstract';

@Injectable({
    providedIn: 'root'
})
export class ErEmailValidator {
    private readonly _userManagementUseCase = inject(AuthenticationManagementUseCaseAbstract);

    public available$(): AsyncValidatorFn {
        return (control: AbstractControl): Observable<ValidationErrors | null> =>
            of(control.value).pipe(
                switchMap(value =>
                    isNil(value) || isEmpty(value) ? of(true) : this._userManagementUseCase.isUsernameAvailable$(value)
                ),
                mapOnError([EUserErrors.API_ERROR], () => true),
                map((isValid: boolean) => (isValid ? null : { usernameAlreadyExist: true }))
            );
    }
}
