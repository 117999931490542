<button
    class="relative"
    [attr.data-test]="buttonDataTest"
    [disabled]="isDisabled || isLoading"
    [ngClass]="[buttonClass]"
    [type]="type"
    (click)="onClick()"
>
    <span [ngClass]="{ 'text-transparent': isLoading }">
        <ng-content></ng-content>
    </span>

    <er-loader *ngIf="isLoading" class="absolute" size="small" theme="light" data-test="button-loading-loader"></er-loader>
</button>
