import { formatNumber } from '@angular/common';
import { inject, Pipe, PipeTransform } from '@angular/core';
import { LocaleServiceAbstract } from '@edenred-falcon/shared/angular/i18n';
import { map, Observable, of, switchMap } from 'rxjs';

@Pipe({ name: 'erNumber', standalone: true })
export class ErDecimalPipe implements PipeTransform {
    private readonly _localeService = inject(LocaleServiceAbstract);

    public transform(value: number | Observable<number>, digitsInfo = '1.2-2'): Observable<string> {
        const value$ = value instanceof Observable ? value : of(value);
        return this._localeService.getLocale$().pipe(switchMap(locale => this._format$(value$, locale, digitsInfo)));
    }

    private _format$(value$: Observable<number>, locale: string, digitsInfo: string): Observable<string> {
        return value$.pipe(map(value => formatNumber(value, locale, digitsInfo)));
    }
}
