import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class FileManagerService {
    constructor(private readonly _http: HttpClient) {}

    public download$(url: string, filename: string): Observable<boolean> {
        return this._http.get(url, { responseType: 'blob' }).pipe(
            tap((blob: Blob) => {
                saveAs(blob, filename);
            }),
            map(() => true),
            catchError(() => of(false))
        );
    }
}
