/* eslint-disable @typescript-eslint/no-explicit-any */
import { Directive, inject, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';

import { ErExpandableIndicatorForComponent } from '../components/indicator/expandable-indicator-for.component';
import { ErExpandableDirective } from './expandable.directive';

@Directive({
    selector: '[erExpandableIndicatorFor]'
})
export class ErExpandableIndicatorForDirective implements OnInit, OnDestroy {
    @Input() public erExpandableIndicatorFor: unknown;

    private _subscription: Subscription;

    private readonly _expandable = inject(ErExpandableDirective);
    private readonly _viewContainerRef = inject(ViewContainerRef);
    private readonly _templateRef = inject(TemplateRef<any>);

    public ngOnInit(): void {
        this._viewContainerRef.clear();

        const componentRef = this._viewContainerRef.createComponent(ErExpandableIndicatorForComponent, {
            injector: this._viewContainerRef.injector,
            projectableNodes: [this._templateRef.createEmbeddedView({}).rootNodes]
        });

        this._subscription = this._expandable.getRow$().subscribe((row: unknown) => {
            componentRef.instance.expanded = this.erExpandableIndicatorFor === row;
        });
    }

    public ngOnDestroy(): void {
        this._subscription.unsubscribe();
    }
}
