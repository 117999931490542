import { Injectable } from '@angular/core';

import { Mapper } from '../../../../../../infrastructure/services/mapper.abstract.service';
import { IUser } from '../../../../domain/interfaces';
import { IUserEntity } from '../../interfaces';

@Injectable({
    providedIn: 'root'
})
export class UserMapper extends Mapper<IUserEntity, IUser> {
    public map(entity: IUserEntity): IUser {
        return super.sanitize({
            id: entity.id,
            username: entity.username
        });
    }
}
