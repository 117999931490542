import { NgClass, NgIf } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CheckboxControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';

@Component({
    selector: 'er-checkbox',
    templateUrl: './checkbox.component.html',
    styleUrl: './checkbox.component.css',
    standalone: true,
    imports: [MatIconModule, NgClass, NgIf],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: ErCheckboxComponent
        }
    ]
})
export class ErCheckboxComponent extends CheckboxControlValueAccessor implements OnInit {
    @Input({ required: true }) public formControl: FormControl<boolean> = new FormControl();
    @Input() public undetermined = false;
    @Input() public required: boolean;
    @Output() public toggleValue: EventEmitter<boolean> = new EventEmitter(false);
    @Output() public undeterminedChange: EventEmitter<boolean> = new EventEmitter();
    @ViewChild('input') public input: ElementRef;

    public toggle($event: Event): void {
        // Handle click on a link with an href
        if (($event.target as HTMLLinkElement)?.href) {
            return;
        }

        $event.stopImmediatePropagation();
        $event.preventDefault();

        if (!this.formControl.disabled) {
            const checked: boolean = this.undetermined ? false : !this.formControl.value;
            if (!checked) {
                this.undetermined = false;
                this.undeterminedChange.emit(this.undetermined);
            }
            this.formControl.setValue(checked);
            this.formControl.markAsDirty();
            this.input.nativeElement.focus();
            this.toggleValue.emit(this.formControl.value);
        }
    }

    public get ticked(): boolean {
        return this.formControl.value || !this.undetermined;
    }

    public get isDisabled(): boolean {
        return this.formControl.disabled;
    }

    public get isInvalid(): boolean {
        return this.formControl.dirty && this.formControl.status === 'INVALID';
    }

    public ngOnInit(): void {
        if (!this.formControl.value) {
            this.formControl.setValue(false);
        }

        if (this.required) {
            this.formControl.setValidators([Validators.requiredTrue]);
            this.formControl.updateValueAndValidity();
        }
    }
}
