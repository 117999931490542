import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    forwardRef,
    inject,
    ViewEncapsulation
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DateTime } from 'luxon';

import { ErDatePickerBase } from '../date-picker-base';

@Component({
    selector: 'er-date-picker',
    templateUrl: './date-picker.component.html',
    styleUrls: ['./date-picker.component.css'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ErDatePickerComponent),
            multi: true
        }
    ]
})
export class ErDatePickerComponent extends ErDatePickerBase<DateTime> implements ControlValueAccessor {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    public selectedDate: DateTime = null;

    public get value(): DateTime {
        return this.selectedDate;
    }

    private readonly _changeDetectorRef = inject(ChangeDetectorRef);

    public format(value: DateTime): string {
        return value?.toLocaleString(this.dateFormat);
    }

    public writeValue(date: DateTime): void {
        this.selectedDate = date;
        this._changeDetectorRef.markForCheck();
    }

    public onDateChange(date: DateTime): void {
        this.selectedDate = date;
        this.onChange(this.selectedDate);
        this._changeDetectorRef.markForCheck();
    }
}
