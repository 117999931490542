import { NgClass } from '@angular/common';
import { Component, inject, Input, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

import { ErLabelComponent } from '../label/label.component';

type Icon = 'euro' | 'dollar';

enum MaterialIcons {
    'euro' = 'euro-rg-24',
    'dollar' = 'attach-money-rg-24'
}

@Component({
    selector: 'er-input-currency',
    templateUrl: './input-currency.component.html',
    styleUrl: './input-currency.component.css',
    encapsulation: ViewEncapsulation.None,
    imports: [ErLabelComponent, MatFormFieldModule, MatInputModule, MatIconModule, NgClass],
    standalone: true
})
export class ErInputCurrencyComponent implements ControlValueAccessor {
    @Input() public icon: Icon = 'euro';
    @Input() public inputClass = '';
    public isDisabled = false;
    public value = '';

    public parseInt = parseInt;

    public control = inject(NgControl, { self: true, optional: true });

    public onChange: (value: number) => void = () => null;
    public onTouched: () => void = () => null;

    constructor() {
        if (this.control) {
            this.control.valueAccessor = this;
        }
    }

    public writeValue(value: string): void {
        this.value = value;
    }

    public registerOnChange(fn: (value: number) => void): void {
        this.onChange = fn;
    }

    public getIcon(): string {
        return MaterialIcons[this.icon];
    }

    public registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    public setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }
}
