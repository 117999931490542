import { NgClass, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
    selector: 'er-checker',
    templateUrl: './checker.component.html',
    imports: [MatIconModule, NgClass, NgIf],
    standalone: true
})
export class ErCheckerComponent {
    @Input({ required: true }) public valid: boolean;
    @Input({ required: true }) public dirty = false;
}
