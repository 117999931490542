import { inject } from '@angular/core';
import { Router } from '@angular/router';

import { FeatureFlagService } from '../services/feature-flag.service';

export const isFeatureEnabled = (feature: string): Promise<boolean> | boolean => {
    const featureFlagService = inject(FeatureFlagService);
    const router = inject(Router);

    return featureFlagService.isEnabled(feature) === false ? router.navigate(['/']) : true;
};
