import { inject, Injectable } from '@angular/core';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';

import { TitleService } from './title.service';

@Injectable()
export class TitleTranslateStrategy extends TitleStrategy {
    private readonly _titleService = inject(TitleService);

    public updateTitle(snapshot: RouterStateSnapshot): void {
        const label = this.buildTitle(snapshot);

        if (label === undefined) {
            return;
        }

        this._titleService.updateTitle({ label });
    }
}
