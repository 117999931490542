import { inject, Injectable } from '@angular/core';
import { MapGeocoder, MapGeocoderResponse } from '@angular/google-maps';
import { filter, map, Observable, of, switchMap } from 'rxjs';

import { ApiLoaderService } from './api-loader.service';

@Injectable({
    providedIn: 'root'
})
export class GeocoderService {
    private readonly _apiLoaderService = inject(ApiLoaderService);
    private readonly _mapGeocoder = inject(MapGeocoder);

    public findLocationByAddress$(request: google.maps.GeocoderRequest): Observable<google.maps.LatLng | undefined> {
        return this._apiLoaderService.load$().pipe(
            filter((apiLoaded: boolean) => apiLoaded === true),
            switchMap(() => this._mapGeocoder.geocode(request)),
            switchMap((response: MapGeocoderResponse) => of(response.results[0])),
            map((result: google.maps.GeocoderResult) => result?.geometry.location)
        );
    }

    public findPlaceByLocation$(lat: number, lng: number): Observable<string> {
        return this._apiLoaderService.load$().pipe(
            filter((apiLoaded: boolean) => apiLoaded === true),
            switchMap(() => this._mapGeocoder.geocode({ location: { lat, lng } })),
            switchMap((response: MapGeocoderResponse) => of(response.results)),
            map(results => results.map(result => result.formatted_address).at(0)),
            map(result => result ?? 'My position')
        );
    }
}
