import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ContentChildren,
    DestroyRef,
    EventEmitter,
    forwardRef,
    inject,
    Input,
    OnChanges,
    OnInit,
    Output,
    QueryList,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject, combineLatest, delay, map, Observable } from 'rxjs';

import { ErDropdownComponent } from '../dropdown/dropdown.component';
import { ErOptionComponent } from './option/option.component';
import { OPTION_PARENT_COMPONENT } from './tokens/option-parent.interface';

@Component({
    selector: 'er-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.css'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ErSelectComponent),
            multi: true
        },
        {
            provide: OPTION_PARENT_COMPONENT,
            useExisting: forwardRef(() => ErSelectComponent)
        }
    ]
    // changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErSelectComponent<T> implements ControlValueAccessor, OnChanges, AfterViewInit, OnInit {
    @ViewChild(ErDropdownComponent) public ErDropdownComponent: ErDropdownComponent;
    @ContentChildren(ErOptionComponent, { descendants: true })
    public queryListOptionComponent: QueryList<ErOptionComponent<T>>;

    @Input() public label: string;
    @Input() public placeholder: string;
    @Input() public multiple = false;
    @Input() public multipleToggle = true;
    @Input() public multipleClearLabel = 'clear';
    @Input() public multipleSelectAllLabel = 'select all';
    @Input() public multipleAllLabel = 'all';

    @Output() public selectionChange: EventEmitter<T | T[]> = new EventEmitter<T | T[]>();

    public value$: BehaviorSubject<T | T[] | null> = new BehaviorSubject<T | T[] | null>([]);
    public disabled: boolean;

    private readonly _destroyRef = inject(DestroyRef);
    private readonly _changeDetectorRef = inject(ChangeDetectorRef);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
    public onChange = (changes: unknown): void => {};
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public onTouched = (): void => {};

    public ngOnInit(): void {
        this.value$.pipe(takeUntilDestroyed(this._destroyRef), delay(10)).subscribe(() => {
            this._changeDetectorRef.markForCheck();
        });
    }

    public ngAfterViewInit(): void {
        combineLatest(this.queryListOptionComponent.toArray().map(optionComp => optionComp.markForCheck.asObservable()))
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe(() => {
                this._changeDetectorRef.markForCheck();
            });
    }
    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.multiple) {
            this.writeValue(this.multiple ? [] : null);
            this.queryListOptionComponent?.forEach((optionComponent: ErOptionComponent<T>) => {
                optionComponent.selected = false;
            });
        }
    }

    public close(): void {
        if (!this.multiple && this.ErDropdownComponent?.opened$.getValue()) {
            this.ErDropdownComponent.toggle();
        }
        this._changeDetectorRef.markForCheck();
    }

    public toggleOptions(checked: boolean): void {
        this.queryListOptionComponent.forEach(option => {
            option.selected = checked;
        });
        const values = this.queryListOptionComponent.filter(option => option.selected).map(option => option.value);

        this.writeValue(values);
        this.onTouched();
        this.onChange(values);
        this.selectionChange.emit(values);
    }

    public get selectedOptionCount(): number {
        return this.multiple ? (this.value$.getValue() as T[]).length : 1;
    }

    public get everyOptionsSelected(): boolean {
        return this.queryListOptionComponent.toArray().every(option => option.selected === true);
    }

    public get someOptionsSelected(): boolean {
        return this.queryListOptionComponent.toArray().some(option => option.selected === true);
    }

    public get labels(): string[] {
        return this.queryListOptionComponent
            .filter((optionComponent: ErOptionComponent<T>) => {
                const value = this.value$.getValue();
                if (this.multiple && Array.isArray(value)) {
                    return value.includes(optionComponent.value);
                }
                return value === optionComponent.value;
            })
            .map((optionComponent: ErOptionComponent<T>) => optionComponent.label);
    }

    public registerOnChange(onChange: () => unknown): void {
        this.onChange = onChange;
    }

    public registerOnTouched(onTouched: () => unknown): void {
        this.onTouched = onTouched;
    }

    public writeValue(value: T | T[] | null): void {
        if (this.multiple) {
            let values: T[] = [];
            if (value !== null) {
                values = Array.isArray(value) ? value : [value];
            }
            this.value$.next(values);
        } else {
            this.value$.next(value);
            this.close();
        }
    }

    public setDisabledState?(disabled: boolean): void {
        this.disabled = disabled;
        this._changeDetectorRef.markForCheck();
    }

    public selectedOptionsCount$(): Observable<number> {
        return this.value$.pipe(map(value => (Array.isArray(value) ? value.length : 0)));
    }
}
