import { DOCUMENT } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { Observable, switchMap } from 'rxjs';

import { AutocompleteQuery, AutocompleteResponse, google } from '../interfaces';
import { AutocompleteQueryMapper } from '../mappers/autocomplete-query.mapper';
import { ApiLoaderService } from './api-loader.service';

@Injectable({
    providedIn: 'root'
})
export class PlacesService {
    private readonly _apiLoaderService = inject(ApiLoaderService);
    private readonly _autocompleteQueryMapper = inject(AutocompleteQueryMapper);
    private readonly _document = inject(DOCUMENT);

    private readonly _container: HTMLDivElement = this._document.createElement('div');

    public autocomplete$(autocompleteQuery: AutocompleteQuery): Observable<AutocompleteResponse> {
        const request = this._autocompleteQueryMapper.map(autocompleteQuery);

        return this._apiLoaderService.load$().pipe(switchMap(() => this._queryPrediction$(request)));
    }

    public getPlaceById$(
        placeDetailsRequest: google.maps.places.PlaceDetailsRequest
    ): Observable<{ result: google.maps.places.PlaceResult; status: google.maps.places.PlacesServiceStatus }> {
        return this._apiLoaderService.load$().pipe(switchMap(() => this._findPlaceById$(placeDetailsRequest)));
    }

    private _queryPrediction$(request: google.maps.places.AutocompletionRequest): Observable<{
        predictions: google.maps.places.AutocompletePrediction[];
        status: google.maps.places.PlacesServiceStatus;
    }> {
        return new Observable(observer => {
            new google.maps.places.AutocompleteService().getPlacePredictions(request, (predictions, status) => {
                if (status === 'OK') {
                    observer.next({ predictions: predictions as google.maps.places.AutocompletePrediction[], status });
                } else {
                    observer.error({ predictions, status });
                }
                observer.complete();
            });
        });
    }

    private _findPlaceById$(request: google.maps.places.PlaceDetailsRequest): Observable<{
        result: google.maps.places.PlaceResult;
        status: google.maps.places.PlacesServiceStatus;
    }> {
        return new Observable(observer => {
            new google.maps.places.PlacesService(this._document.createElement('div')).getDetails(
                request,
                (result, status) => {
                    if (status === 'OK') {
                        observer.next({ result: result as google.maps.places.PlaceResult, status });
                    } else {
                        observer.error({ result, status });
                    }
                    observer.complete();
                }
            );
        });
    }
}
