import { inject, Injectable } from '@angular/core';
import { IMetadata, Presenter } from '@edenred/core/domain';
import { ITermsOfUse } from '@edenred/core/modules/authentication-management/domain/interfaces';

import { IResponse, ITermsOfUseEntity } from '../interfaces';
import { TermsOfUseMapper } from '../mappers/interfaces/terms-of-use.mapper';

@Injectable({
    providedIn: 'root'
})
export class GetTermsOfUsePresenter extends Presenter<IResponse<ITermsOfUseEntity>, ITermsOfUse> {
    private readonly _termsOfUseMapper = inject(TermsOfUseMapper);

    protected getData(response: IResponse<ITermsOfUseEntity>): ITermsOfUse {
        return this._termsOfUseMapper.map(response.data as ITermsOfUseEntity);
    }

    protected getMetadata(): IMetadata | null {
        return null;
    }
}
