import { InjectionToken, ModuleWithProviders, NgModule } from '@angular/core';

import { CoreConfig } from './infrastructure/config/core.config';

export const CORE_CONFIG_TOKEN = new InjectionToken<CoreConfig>('CoreConfig');

@NgModule()
export class CoreModule {
    public static forRoot(config: CoreConfig): ModuleWithProviders<CoreModule> {
        return {
            ngModule: CoreModule,
            providers: [{ provide: CORE_CONFIG_TOKEN, useValue: config }]
        };
    }
}
