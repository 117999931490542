<mat-accordion>
    <mat-expansion-panel
        class="!bg-transparent !shadow-none"
        [expanded]="expanded"
        (closed)="toggleOpenState(false)"
        (opened)="toggleOpenState(true)"
    >
        <mat-expansion-panel-header
            class="!flex-row-reverse items-center justify-center font-sans text-sm font-medium uppercase hover:!bg-transparent"
            [ngClass]="{ 'sm-justify-center': justify === 'center' }"
        >
            <mat-panel-title data-test="accordion-title">{{ title }}</mat-panel-title>
        </mat-expansion-panel-header>

        <ng-content></ng-content>
    </mat-expansion-panel>
</mat-accordion>
