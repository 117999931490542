import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { FatalErrorException } from '../../../../../domain/exceptions/fatal-error/fatal-error.exception';
import { ECreatePasswordErrors } from '../../../domain/enums/exceptions';

@Injectable({
    providedIn: 'root'
})
export class CreatePasswordExceptionHandler {
    public handle(response: HttpErrorResponse): ECreatePasswordErrors {
        switch (response.status as HttpStatusCode) {
            case HttpStatusCode.Unauthorized:
                return ECreatePasswordErrors.OTP_EXPIRED;
            case HttpStatusCode.UnprocessableEntity:
                return ECreatePasswordErrors.USER_ALREADY_EXISTS;
            default:
                throw new FatalErrorException();
        }
    }
}
