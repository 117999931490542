import { recursiveAssign } from '@edenred/utilities';

import { environment as base } from './environment.base';

export const environment = {
    ...recursiveAssign(base, {
        apis: {
            users: {
                host: 'https://my-uta.dev.eu.uta.io/v2'
            },
            userManagement: {
                host: 'https://my-uta.dev.eu.uta.io/v2'
            },
            reCaptcha: {
                /**
                 * @see https://developers.google.com/recaptcha/docs/faq#id-like-to-run-automated-tests-with-recaptcha.-what-should-i-do
                 */
                siteKey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'
            }
        },
        errorReporting: {
            sentry: {
                enabled: true,
                environment: 'develop'
            }
        },
        production: false
    })
};
