import { getLocaleFirstDayOfWeek, registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import localeIt from '@angular/common/locales/it';
import localeLt from '@angular/common/locales/lt';
import localeNl from '@angular/common/locales/nl';
import localePl from '@angular/common/locales/pl';
import localeRo from '@angular/common/locales/ro';
import { APP_INITIALIZER, Provider } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import {
    LuxonDateAdapter,
    MAT_LUXON_DATE_ADAPTER_OPTIONS,
    MAT_LUXON_DATE_ADAPTER_OPTIONS_FACTORY
} from '@angular/material-luxon-adapter';
import { LocaleServiceAbstract } from '@edenred-falcon/shared/angular/i18n';
import { Info, Settings, StringUnitLength } from 'luxon';
import { distinctUntilChanged } from 'rxjs';

import { ER_DATE_FORMAT } from '../config/date-format.config';
import { IntlPaginatorService } from '../services/intl-paginator.service';

export class LocaleLuxonDateAdapter extends LuxonDateAdapter {
    public override getFirstDayOfWeek(): number {
        try {
            return getLocaleFirstDayOfWeek(this.locale);
        } catch (e) {
            // locale data is not registered. Return the default value.
            return MAT_LUXON_DATE_ADAPTER_OPTIONS_FACTORY().firstDayOfWeek;
        }
    }

    public override getDayOfWeekNames(style: StringUnitLength): string[] {
        if (style === 'narrow') {
            return Info.weekdays('short', { locale: this.locale });
        }
        return Info.weekdays(style, { locale: this.locale });
    }
}

export const localeProviders = (supportedLocales: string[]): Provider[] => {
    registerLocaleData(localeDe);
    registerLocaleData(localeEn);
    registerLocaleData(localeFr);
    registerLocaleData(localeNl);
    registerLocaleData(localeIt);
    registerLocaleData(localeLt);
    registerLocaleData(localePl);
    registerLocaleData(localeRo);
    registerLocaleData(localeEs);

    return [
        { provide: MAT_DATE_LOCALE, useValue: supportedLocales.at(0) }, // only use to init DateAdapter
        { provide: MAT_DATE_FORMATS, useValue: ER_DATE_FORMAT },
        {
            provide: DateAdapter,
            useClass: LocaleLuxonDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_LUXON_DATE_ADAPTER_OPTIONS]
        },

        { provide: MatPaginatorIntl, useClass: IntlPaginatorService },
        {
            provide: APP_INITIALIZER,
            multi: true,
            useFactory: (localeService: LocaleServiceAbstract, dateAdapter: DateAdapter<LuxonDateAdapter>) => () => {
                localeService
                    .getLocale$()
                    .pipe(distinctUntilChanged())
                    .subscribe(locale => {
                        Settings.defaultLocale = locale;
                        dateAdapter.setLocale(locale);
                    });
            },
            deps: [LocaleServiceAbstract, DateAdapter<LuxonDateAdapter>]
        }
    ];
};
