export class EnumHelper {
    public static findKeyByValue(value: string, customEnum: { [key: string]: string }): string | null {
        return Object.keys(customEnum).find(key => customEnum[key] === value) ?? null;
    }

    public static findByValue(
        value: string,
        customEnum: Record<string, string>,
        noMatchingValue: unknown = null
    ): unknown {
        const enumKey = this.findKeyByValue(value, customEnum);
        return enumKey ? customEnum[enumKey] : noMatchingValue;
    }
}
