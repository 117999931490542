import { BehaviorSubject, Observable } from 'rxjs';

import { Store } from '../domain';

export class InMemoryStoreService<T = string> extends Map<T, unknown> implements Store<T> {
    private readonly _subject$: BehaviorSubject<Map<T, unknown>> = new BehaviorSubject(new Map());

    public override set(key: T, value: unknown): this {
        super.set(key, value);
        this._subject$.next(this);
        return this;
    }

    public override delete(key: T): boolean {
        const result = super.delete(key);
        this._subject$.next(this);
        return result;
    }

    public override get(key: T): unknown {
        return super.get(key) as string;
    }

    public override has(key: T): boolean {
        return super.has(key);
    }

    public override clear(): void {
        super.clear();
        this._subject$.next(this);
    }

    public asObservable$(): Observable<Map<T, unknown>> {
        return this._subject$.asObservable();
    }
}
