import { Directive, HostBinding, HostListener, inject, Input } from '@angular/core';
import { FileManagerService } from '@edenred/utilities';
import { lastValueFrom } from 'rxjs';

@Directive({
    selector: '[erDownload]',
    exportAs: 'erDownload',
    standalone: true
})
export class ErDownloadDirective {
    private _error = false;

    @HostBinding('class.disabled')
    public loading = false;

    public fileManager = inject(FileManagerService);

    @Input() public href: string;
    @Input() public erDownload: string;

    @HostListener('click')
    public async download(): Promise<void> {
        if (!this.loading) {
            this.loading = true;
            this._error = !(await lastValueFrom(this.fileManager.download$(this.href, this.erDownload)));
            this.loading = false;
        }
    }

    public isLoading(): boolean {
        return this.loading;
    }

    public hasError(): boolean {
        return this._error;
    }
}
