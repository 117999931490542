import { NgModule } from '@angular/core';

import { ErActionLoaderDirective } from './directives/action-loader.directive';
import { ErDestructiveDirective } from './directives/destructive.directive';
import { ErDownloadDirective } from './directives/download.directive';
import { ErEnterToClickDirective } from './directives/enter-to-click.directive';
import { ErGhostDirective } from './directives/ghost.directive';
import { ErLargeDirective } from './directives/large.directive';
import { ErLayoutContainerDirective } from './directives/layout-container.directive';
import { ErPasswordToggleDirective } from './directives/password-toggle.directive';
import { ErPreventPasteDirective } from './directives/prevent-paste.directive';
import { ErPrimaryDirective } from './directives/primary.directive';
import { ErSecondaryDirective } from './directives/secondary.directive';
import { ErSmallDirective } from './directives/small.directive';
import { ErTertiaryDirective } from './directives/tertiary.directive';
import { ErWarningDirective } from './directives/warning.directive';

@NgModule({
    imports: [
        ErActionLoaderDirective,
        ErSmallDirective,
        ErLargeDirective,
        ErPrimaryDirective,
        ErPasswordToggleDirective,
        ErDownloadDirective,
        ErPreventPasteDirective,
        ErLayoutContainerDirective,
        ErSecondaryDirective,
        ErTertiaryDirective,
        ErGhostDirective,
        ErDestructiveDirective,
        ErWarningDirective,
        ErEnterToClickDirective
    ],
    exports: [
        ErSmallDirective,
        ErLargeDirective,
        ErPrimaryDirective,
        ErPasswordToggleDirective,
        ErDownloadDirective,
        ErPreventPasteDirective,
        ErLayoutContainerDirective,
        ErSecondaryDirective,
        ErTertiaryDirective,
        ErGhostDirective,
        ErDestructiveDirective,
        ErWarningDirective,
        ErEnterToClickDirective,
        ErActionLoaderDirective
    ]
})
export class ErDirectivesModule {}
