import { Provider } from '@angular/core';
import { MAT_RIPPLE_GLOBAL_OPTIONS, MATERIAL_SANITY_CHECKS } from '@angular/material/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

export const materialProviders: Provider[] = [
    {
        provide: MAT_RIPPLE_GLOBAL_OPTIONS,
        useValue: { disabled: true }
    },
    { provide: MAT_SNACK_BAR_DATA, useValue: {} },
    { provide: MATERIAL_SANITY_CHECKS, useValue: false }
];
