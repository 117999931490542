import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { BreakpointService } from '@edenred/utilities';
import { Observable } from 'rxjs';

import { EButtonStyle } from '../../../enums/button-style.enum';

@Component({
    selector: 'er-tile-header',
    templateUrl: './tile-header.component.html'
})
export class ErTileHeaderComponent {
    @Input() public emphasized = false;
    @Input({ required: true }) public title!: string;

    @Input() public actionCaption: string;
    @Input() public actionStyle: string = EButtonStyle.PRIMARY;
    @Input() public actionIcon: string;
    @Input() public buttonDataTest = 'action-button';
    @Input() public isIconVisibleOnDesktop: boolean;

    @Input() public isActionDisabled: boolean;
    @Input() public isActionVisible: boolean;

    @Input() public hasDivider: boolean;

    @Output() public action: EventEmitter<unknown> = new EventEmitter();

    public EButtonStyle = EButtonStyle;

    public isDesktop$: Observable<boolean>;

    private readonly _breakpointService = inject(BreakpointService);

    public isDesktopScreen$(): Observable<boolean> {
        return this._breakpointService.isDesktopScreen$();
    }
}
