<header
    *ngIf="title"
    class="flex flex-col gap-5 rounded-t-lg border-grey-20 p-4"
    [ngClass]="{
        'bg-grey-50 text-white': emphasized,
        'border-b-0': !hasDivider,
        'border-b-[1px]': hasDivider
    }"
>
    <div class="flex flex-row items-center justify-between">
        <h5 class="m-0 font-sans font-medium text-grey-70" [ngClass]="{ 'text-white': emphasized }">
            {{ title }}
        </h5>
        <button
            *ngIf="actionCaption && isActionVisible !== false"
            class="w-auto {{ actionStyle }}"
            [attr.data-test]="buttonDataTest"
            [disabled]="isActionDisabled"
            [ngClass]="{ '!h-7 min-h-0 !w-7 !px-0 !py-0 !no-underline': (isDesktopScreen$() | async) === false }"
            (click)="action.emit()"
        >
            <mat-icon
                *ngIf="(actionIcon && isIconVisibleOnDesktop) || (isDesktopScreen$() | async) === false"
                [svgIcon]="actionIcon"
            ></mat-icon>
            <span *ngIf="isDesktopScreen$() | async">{{ actionCaption }}</span>
        </button>
    </div>
    <ng-content></ng-content>
</header>
