import { MatDateFormats } from '@angular/material/core';
import { DateTimeFormatOptions } from 'luxon';

export const ER_DATE_FORMAT: MatDateFormats = {
    parse: {
        dateInput: 'yyyy-LL-dd'
    },
    display: {
        dateInput: 'yyyy-LL-dd',
        monthLabel: 'LLLL',
        monthYearLabel: 'LLLL yyyy',
        dateA11yLabel: 'c',
        monthYearA11yLabel: 'LLLL yyyy'
    }
};

export const CUSTOM_DATE_FORMAT: { [key: string]: DateTimeFormatOptions } = {
    MONTH_YEAR: { year: 'numeric', month: 'numeric' },
    MONTH_DAY: { month: 'short', day: 'numeric' },
    MONTH_YEAR_DAY: { month: 'short', day: '2-digit', year: '2-digit' },
    DATE_TWO_DIGIT: { month: '2-digit', day: '2-digit', year: '2-digit' },
    DATE_TWO_DIGIT_SHORT: { month: '2-digit', year: '2-digit' }
};
