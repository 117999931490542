import { TextHelper } from './text.helper';

export class IdentityHelper {
    public static truncate(
        firstName: string | null | undefined,
        lastName: string | null | undefined,
        length = 20
    ): string {
        const safeFirstName = firstName ?? '';
        const safeLastName = lastName ?? '';
        let truncatedString = `${safeFirstName} ${safeLastName}`.trim();

        if (truncatedString.length > length) {
            const firstNameFirstLetter = firstName?.charAt(0);
            truncatedString = `${firstNameFirstLetter} ${lastName?.slice(0, length - 2)}`;
        }

        return TextHelper.toTitleCase(truncatedString);
    }
}
