import { Component, Input } from '@angular/core';

import { ErLargeDirective } from '../../directives/large.directive';

@Component({
    selector: 'er-empty-table',
    templateUrl: './empty-table.component.html',
    imports: [ErLargeDirective],
    standalone: true
})
export class ErEmptyTableComponent {
    @Input({ required: true }) public title: string;
}
