import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export const DESKTOP_BREAKPOINT = 768;
export const TABLET_BREAKPOINT = 1280;

@Injectable({
    providedIn: 'root'
})
export class BreakpointService {
    private readonly _desktopBreakpointMediaquery = `(min-width: ${DESKTOP_BREAKPOINT}px)`;
    private readonly _tabletBreakpointMediaquery = `(min-width: ${TABLET_BREAKPOINT}px)`;

    constructor(private readonly _breakpointObserver: BreakpointObserver) {}

    public isDesktopScreen$(): Observable<boolean> {
        return this._breakpointObserver
            .observe(this._desktopBreakpointMediaquery)
            .pipe(map((value: BreakpointState) => value.matches));
    }

    public isDesktopScreen(): boolean {
        return this._breakpointObserver.isMatched(this._desktopBreakpointMediaquery);
    }

    public isTabletScreen$(): Observable<boolean> {
        return this._breakpointObserver
            .observe(this._tabletBreakpointMediaquery)
            .pipe(map((value: BreakpointState) => value.matches));
    }

    public isTabletScreen(): boolean {
        return this._breakpointObserver.isMatched(this._tabletBreakpointMediaquery);
    }
}
