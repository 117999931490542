<div class="grid min-h-[75px] gap-0 bg-white md:col-span-12 md:grid-cols-12">
    <div class="mb-5 self-center md:col-span-3 md:mb-0">
        <label class="mb-5 mr-5 font-bold"><ng-content></ng-content></label>

        <p class="mt-2 text-grey-50" erSmall><ng-content select="[hint]"></ng-content></p>
    </div>

    <div class="hidden text-right text-grey-50 md:col-span-1 md:my-auto md:block" [ngClass]="{ 'text-grey-20': disabled }">
        {{ unit ? (minValue | erCurrency : unit : minValueFormat | async) : minValue }}
    </div>

    <div class="slider-wrapper relative h-auto w-full items-center p-0 md:col-span-6 md:mx-5 md:my-auto md:h-auto">
        <mat-slider
            *ngIf="step"
            class="!block"
            discrete
            thumbLabel
            [disabled]="disabled"
            [displayWith]="isLabelFormatted ? getFormattedLabel : getLabel"
            [max]="maxValue"
            [min]="minValue"
            [ngClass]="{ 'mat-slider-max-value': value === maxValue, 'mat-slider-min-value': value === minValue }"
            [step]="step"
            [tabindex]="0"
            data-test="mat-slider"
        >
            <input matSliderThumb [value]="value" (valueChange)="onValueChange($event)" data-test="mat-slider-thumb" />
        </mat-slider>

        <!-- This mask prevent user clicking unwanted values -->
        <div class="pointer-events-none absolute -inset-x-6 top-1/2 z-[1] flex h-full -translate-y-1/2 flex-row px-2">
            <div class="w-6 shrink-0"></div>
            <div class="pointer-events-none flex w-full flex-row">
                <div
                    *ngFor="let item of sliderMask"
                    class="mat-slider-ticks w-full first:w-1/2 last:w-1/2"
                    [ngClass]="item > (maxSelectedValue || maxValue) ? 'pointer-events-auto' : 'pointer-events-none'"
                ></div>
            </div>
            <div
                class="w-6 shrink-0"
                [ngClass]="(maxSelectedValue || maxValue) < maxValue ? 'pointer-events-auto' : 'pointer-events-none'"
            ></div>
        </div>
    </div>

    <div class="my-auto ml-8 hidden text-right text-grey-50 md:col-span-1 md:block" [ngClass]="{ 'text-grey-20': disabled }">
        {{ unit ? (maxValue | erCurrency : unit : maxValueFormat | async) : maxValue }}
    </div>
</div>
<div class="grid md:col-span-12 md:grid-cols-12">
    <div class="col-span mt-2 md:col-span-3 md:-mt-5">
        <p class="mt-0 text-grey-50" erSmall><ng-content select="[hint]"></ng-content></p>
    </div>
</div>
