import { inject, Injectable } from '@angular/core';

import { Mapper } from '../../../../../../infrastructure/services/mapper.abstract.service';
import { IForgotPasswordPayload } from '../../../../domain/interfaces';
import { IForgotPasswordQuery } from '../../interfaces';
import { PlanCodeMapper } from '../plan-code.mapper';

@Injectable({
    providedIn: 'root'
})
export class ForgotPasswordQueryMapper extends Mapper<IForgotPasswordPayload, IForgotPasswordQuery> {
    private readonly _planCodeMapper = inject(PlanCodeMapper);

    public map(payload: IForgotPasswordPayload): IForgotPasswordQuery {
        return this.sanitize({
            username: payload.email,
            plan_code: this._planCodeMapper.map(payload.tenant)
        });
    }
}
