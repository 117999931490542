import { NgClass, NgIf } from '@angular/common';
import { Component, HostBinding, Input } from '@angular/core';
import { MatBadgeModule, MatBadgeSize } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { isNil } from 'ramda';

import { ErDirectivesModule } from '../../directives.module';
import { EButtonSize } from '../../enums';
import { ErLoaderComponent } from '../loader/loader.component';

@Component({
    selector: 'er-button-icon',
    templateUrl: './button-icon.component.html',
    styleUrl: './button-icon.component.css',
    imports: [ErDirectivesModule, ErLoaderComponent, MatIconModule, MatButtonModule, MatBadgeModule, NgClass, NgIf],
    standalone: true
})
export class ErButtonIconComponent {
    @Input() public icon?: string;
    @Input() public size? = EButtonSize.BASE;
    @Input() public style = 'secondary';
    @Input() public disabled = false;
    @Input() public badge: string | number | null;
    @Input() public badgeSize: MatBadgeSize = 'medium';
    @Input() public isLoading: boolean;
    @Input() public loaderTheme: 'placeholder';
    @Input() public loaderSize: 'small';

    public EButtonSize = EButtonSize;
    public isNil = isNil;

    @HostBinding('style.pointer-events')
    public get pointerEvents(): string {
        return this.disabled ? 'none' : 'auto';
    }
}
