import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, delay, map, Observable } from 'rxjs';

import { BreakpointService } from './breakpoint.service';

/**
 * Desktop Layout
 * ,-------------,-------------------------------------------,
 * |             |                   Header                  |
 * |             |-------------------------------------------|
 * |             | ,---------------------------------------, |
 * |   Sidenav   | |                 Content               | |
 * |             | |                                       | |
 * |             | '---------------------------------------' |
 * |             |-------------------------------------------|
 * |             |                   Footer                  |
 * '-------------'-------------------------------------------'
 *
 * Mobile Layout
 * ,-------------------------,     ,-------------,-----------,
 * |===       Header         |     |             |er         |
 * |-------------------------|     |             |-----------|
 * | ,---------------------, |     |             |---------, |
 * | |       Content       | |     |   Sidenav   |nt       | |
 * | |                     | |     |             |         | |
 * | '---------------------' |     |             |---------' |
 * |-------------------------|     |             |-----------|
 * |          Footer         |     |             |er         |
 * '-------------------------'     '-------------'-----------'
 */

@Injectable({
    providedIn: 'root'
})
export class LayoutService {
    private readonly _isSidebarCollapsed$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private readonly _isSidebarVisible$: BehaviorSubject<boolean> = new BehaviorSubject(true);
    private readonly _isHeaderVisible$: BehaviorSubject<boolean> = new BehaviorSubject(true);
    private readonly _isFooterVisible$: BehaviorSubject<boolean> = new BehaviorSubject(true);
    private readonly _isContentSpaced$: BehaviorSubject<boolean> = new BehaviorSubject(true);

    constructor(private readonly _breakpointService: BreakpointService) {}

    public isSidebarVisible$(): Observable<boolean> {
        return this._isSidebarVisible$.pipe(delay(0));
    }

    public showSidebar(): void {
        this._isSidebarVisible$.next(true);
    }

    public hideSidebar(): void {
        this._isSidebarVisible$.next(false);
    }

    public isHeaderVisible$(): Observable<boolean> {
        return this._isHeaderVisible$.pipe(delay(0));
    }

    public hideHeader(): void {
        this._isHeaderVisible$.next(false);
    }

    public showHeader(): void {
        this._isHeaderVisible$.next(true);
    }

    public isFooterVisible$(): Observable<boolean> {
        return this._isFooterVisible$.pipe(delay(0));
    }

    public hideFooter(): void {
        this._isFooterVisible$.next(false);
    }

    public showFooter(): void {
        this._isFooterVisible$.next(true);
    }

    public collapseSidebar(isCollapsed: boolean): void {
        this._isSidebarCollapsed$.next(isCollapsed);
    }

    public isSidebarCollapsed$(): Observable<boolean> {
        return this._isSidebarCollapsed$.pipe(delay(0));
    }

    public isSidebarCollapsable$(): Observable<boolean> {
        return this._breakpointService.isDesktopScreen$().pipe(map(isDesktop => !isDesktop));
    }

    public isContentSpaced$(): Observable<boolean> {
        return this._isContentSpaced$.pipe(delay(0));
    }

    public removeContentSpacing(): void {
        this._isContentSpaced$.next(false);
    }

    public addContentSpacing(): void {
        this._isContentSpaced$.next(true);
    }

    public toggleFullscreen(isFullscreen: boolean): void {
        if (isFullscreen) {
            this.removeContentSpacing();
            this.hideHeader();
            this.hideFooter();
            this.hideSidebar();
        } else {
            this.addContentSpacing();
            this.showHeader();
            this.showFooter();
            this.showSidebar();
        }
    }

    public isFullscreen$(): Observable<boolean> {
        return combineLatest([
            this.isSidebarVisible$(),
            this.isHeaderVisible$(),
            this.isFooterVisible$(),
            this.isContentSpaced$()
        ]).pipe(map(values => values.every(value => value === false)));
    }
}
