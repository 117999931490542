import { Directive, HostBinding, Input } from '@angular/core';
import { DateTime } from 'luxon';

import { ErDatePickerHeaderComponent } from './date-picker-header/date-picker-header.component';

@Directive()
export abstract class ErDatePickerBase<T> {
    public onChange: (value: T) => void;
    public onTouched: () => void;

    @Input() public dateFormat: Intl.DateTimeFormatOptions = {
        year: '2-digit',
        month: 'numeric',
        day: 'numeric'
    };

    @Input() public label: string;
    @Input() public min: DateTime;
    @Input() public max: DateTime;

    @HostBinding('class.disabled')
    public disabled = false;

    @Input() public header = ErDatePickerHeaderComponent;

    public opened = false;

    public abstract get value(): T;
    public abstract format(value: T): string;

    /* istanbul ignore next */
    public toggle(): void {
        if (!this.disabled) {
            this.opened = !this.opened;
        }
        this.onTouched();
    }

    public registerOnChange(fn: () => void): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }
}
