import { HttpBackend, HttpClient } from '@angular/common/http';
import { importProvidersFrom, inject, ModuleWithProviders, NgModule } from '@angular/core';
import { MissingTranslationHandler, TranslateCompiler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';

import { TranslateServiceAbstract } from '../../services/translate/abstracts/translate.service.abstract';
import { MissingTranslationHandlerService } from './providers/missing-translation-handler.service';
import { TranslateService } from './services/translate.service';

@NgModule({
    imports: [TranslateModule],
    exports: [TranslateModule]
})
export class ErNgxTranslateModule {
    public translateService = inject(TranslateServiceAbstract);

    public static forRoot(defaultLanguage: string, prefix?: string): ModuleWithProviders<ErNgxTranslateModule> {
        return {
            ngModule: ErNgxTranslateModule,
            providers: [
                { provide: TranslateServiceAbstract, useClass: TranslateService },
                importProvidersFrom(
                    TranslateModule.forRoot({
                        loader: {
                            provide: TranslateLoader,
                            useFactory: (backend: HttpBackend) =>
                                new TranslateHttpLoader(new HttpClient(backend), prefix),
                            deps: [HttpBackend]
                        },
                        compiler: {
                            provide: TranslateCompiler,
                            useClass: TranslateMessageFormatCompiler
                        },
                        missingTranslationHandler: {
                            provide: MissingTranslationHandler,
                            useClass: MissingTranslationHandlerService
                        },
                        defaultLanguage
                    })
                )
            ]
        };
    }
}
