import { ModuleWithProviders, NgModule } from '@angular/core';

import { ErNgxTranslateModule } from './modules/ngx-translate/ngx-translate.module';
import { TestingTranslateModule } from './modules/testing/translate.testing.module';
import { LocaleServiceAbstract } from './services/locale/abstracts/locale.service.abstract';
import { LocaleService } from './services/locale/locale.service';
import { StoreServiceAbstract } from './services/store/abstracts/store.abstract.service';
import { LocalStorageService } from './services/store/local-storage.service';
import { SUPPORTED_LOCALES } from './tokens/locale';

@NgModule({
    imports: [ErNgxTranslateModule],
    exports: [ErNgxTranslateModule]
})
export class TranslateModule {
    public static forRoot(config: {
        defaultLanguage: string;
        supportedLocales: string[];
        testing?: boolean;
        prefix?: string;
    }): ModuleWithProviders<TranslateModule> {
        return {
            ngModule: TranslateModule,
            providers: [
                config.testing
                    ? TestingTranslateModule.forRoot().providers ?? []
                    : ErNgxTranslateModule.forRoot(config.defaultLanguage, config.prefix).providers ?? [],
                { provide: SUPPORTED_LOCALES, useValue: config.supportedLocales },
                { provide: LocaleServiceAbstract, useClass: LocaleService },
                { provide: StoreServiceAbstract, useClass: LocalStorageService }
            ]
        };
    }
}
