import { Pipe, PipeTransform } from '@angular/core';
import { map, Observable, of } from 'rxjs';

export interface ErConcatOptions {
    prefix: string;
    suffix: string;
}

type ErConcatInputType = string | number;

@Pipe({ name: 'erConcat', standalone: true })
export class ErConcatPipe implements PipeTransform {
    private readonly _options: ErConcatOptions;
    private readonly _defaultOptions: ErConcatOptions = {
        prefix: '',
        suffix: ''
    };

    public transform(
        input: ErConcatInputType | Observable<ErConcatInputType>,
        options: Partial<ErConcatOptions>
    ): Observable<string> {
        const _options = this._buildOptions(options);
        return this._observe$(input).pipe(map(output => this._format(output, _options)));
    }

    private _buildOptions(customOptions: Partial<ErConcatOptions>): ErConcatOptions {
        return { ...this._defaultOptions, ...customOptions };
    }

    private _observe$(input: ErConcatInputType | Observable<ErConcatInputType>): Observable<ErConcatInputType> {
        return input instanceof Observable ? input : of(input);
    }

    private _format(input: ErConcatInputType, options: ErConcatOptions): string {
        return `${options.prefix}${input}${options.suffix}`;
    }
}
