import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { GoogleMapsModule } from '@angular/google-maps';

import { ErMapsComponent } from './components/maps/maps.component';
import { MAPS_CONFIG_TOKEN, MapsConfig } from './maps.config';
import { GeocoderService, PlacesService } from './services';

@NgModule({
    declarations: [ErMapsComponent],
    imports: [CommonModule, GoogleMapsModule],
    exports: [ErMapsComponent],
    providers: [GeocoderService, PlacesService]
})
export class ErMapsModule {
    public static forRoot(config: MapsConfig): ModuleWithProviders<ErMapsModule> {
        return {
            ngModule: ErMapsModule,
            providers: [{ provide: MAPS_CONFIG_TOKEN, useValue: config }]
        };
    }
}
