import { Injectable } from '@angular/core';
import { Mapper } from '@edenred/core/infrastructure';

import { AutocompleteQuery, google } from '../interfaces';

@Injectable({
    providedIn: 'root'
})
export class AutocompleteQueryMapper extends Mapper<AutocompleteQuery, google.maps.places.AutocompletionRequest> {
    public map(placesAutocompleteQuery: AutocompleteQuery): google.maps.places.AutocompletionRequest {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return super.sanitize({
            bounds: null,
            input: placesAutocompleteQuery.input,
            location: placesAutocompleteQuery.location
                ? new google.maps.LatLng(placesAutocompleteQuery.location.latitude, placesAutocompleteQuery.location.longitude)
                : null,
            offset: null,
            radius: placesAutocompleteQuery.radius,
            componentRestrictions: {
                country: placesAutocompleteQuery.country
            },
            origin: null,
            sessionToken: null,
            types: placesAutocompleteQuery.types
        });
    }
}
