/* istanbul ignore file */
import { EventEmitter } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, map, Observable } from 'rxjs';

import { LanguageHelper } from '../../../helpers/language.helper';
import { LocaleServiceAbstract } from '../abstracts/locale.service.abstract';

export class TestingLocaleService implements LocaleServiceAbstract {
    private readonly _supportedLocales: string[] = ['en-GB', 'de-DE', 'fr-FR'];
    private readonly _locale$: BehaviorSubject<string> = new BehaviorSubject(this._supportedLocales.at(0) ?? '');

    public setLangEvent: EventEmitter<string> = new EventEmitter();
    public setLocaleEvent: EventEmitter<string> = new EventEmitter();

    constructor() {
        this.getLang$()
            .pipe(distinctUntilChanged())
            .subscribe(lang => {
                this.setLangEvent.emit(lang);
            });
        this.getLocale$()
            .pipe(distinctUntilChanged())
            .subscribe(locale => {
                this.setLocaleEvent.emit(locale);
            });
    }

    public getLocale$(): Observable<string> {
        return this._locale$.asObservable();
    }

    public getLocale(): string {
        return this._locale$.getValue();
    }

    public getLang$(): Observable<string> {
        return this._locale$.asObservable().pipe(map(locale => LanguageHelper.getLangOfLocale(locale)));
    }

    public getSupportedLocales(): string[] {
        return this._supportedLocales;
    }

    public getLang(): string {
        return LanguageHelper.getLangOfLocale(this._locale$.getValue());
    }

    public setLocale(locale: string): void {
        const validLocale = this._supportedLocales.find(e => e === locale) ?? this._supportedLocales.at(0) ?? '';
        this._locale$.next(validLocale);
    }
}
