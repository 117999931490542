import { inject } from '@angular/core';
import { ETenant } from '@edenred/core/modules/authentication-management/domain/enums';

import { TermsRepositoryAbstract } from '../../../domain/repositories/terms.repository.abstract';
import { OrchestratorAbstract } from '../../orchestrators';
import { EasyfuelTermsRepository } from '../easyfuel-terms.repository';
import { FalconTermsRepository } from '../falcon-terms.repository';

export const termsRepositoryFactory = (): TermsRepositoryAbstract => {
    const orchestrator = inject(OrchestratorAbstract);
    switch (orchestrator.getTenant()) {
        case ETenant.EASYFUEL:
            return inject(EasyfuelTermsRepository);
        default:
            return inject(FalconTermsRepository);
    }
};
