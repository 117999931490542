import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { ErAlertComponent } from './alert.component';
import { ErAlertService } from './services/alert.service';

@NgModule({
    imports: [ErAlertComponent, MatSnackBarModule],
    exports: [ErAlertComponent, MatSnackBarModule]
})
export class ErAlertModule {
    public static forRoot(): ModuleWithProviders<ErAlertModule> {
        return {
            ngModule: ErAlertModule,
            providers: [ErAlertService]
        };
    }
}
