export enum ECreatePasswordErrors {
    /** One-time password expired. */
    OTP_EXPIRED,
    /** User already exists. */
    USER_ALREADY_EXISTS
}

export enum EUserErrors {
    USER_NOT_FOUND,
    API_ERROR
}
