import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { EUserErrors } from '../../../domain/enums/exceptions';

@Injectable({
    providedIn: 'root'
})
export class RetrieveUsernameIdExceptionHandler {
    public handle(_response: HttpErrorResponse): EUserErrors {
        switch (_response.status as HttpStatusCode) {
            case HttpStatusCode.NotFound:
                return EUserErrors.USER_NOT_FOUND;
            default:
                return EUserErrors.API_ERROR;
        }
    }
}
