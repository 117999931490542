import { Injectable } from '@angular/core';
import { from, merge, Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class GeolocationService {
    public geolocationPermission$: Observable<PermissionStatus> = navigator.permissions?.query
        ? from(navigator.permissions.query({ name: 'geolocation' }))
        : throwError(() => new Error('browser.permissions.api.not.supported'));

    public geolocationGranted$: Observable<PermissionStatus> = this.geolocationPermission$.pipe(
        filter((status: PermissionStatus) => status.state === 'granted')
    );

    public geolocationDenied$: Observable<PermissionStatus> = this.geolocationPermission$.pipe(
        filter((status: PermissionStatus) => status.state === 'denied'),
        switchMap(() => throwError(() => new Error('browser.permissions.geolocation.denied')))
    );

    public geolocationPrompt$: Observable<PermissionStatus> = this.geolocationPermission$.pipe(
        filter((status: PermissionStatus) => status.state === 'prompt')
    );

    public get geolocationPermitted$(): Observable<boolean> {
        return merge(this.geolocationGranted$, this.geolocationDenied$, this.geolocationPrompt$).pipe(
            map(() => true),
            catchError(() => of(false))
        );
    }

    public get currentPosition$(): Observable<GeolocationPosition> {
        return new Observable(observer => {
            window.navigator.geolocation.getCurrentPosition(
                (position: GeolocationPosition) => {
                    observer.next(position);
                    observer.complete();
                },
                (error: GeolocationPositionError) => observer.error(error),
                {
                    enableHighAccuracy: true,
                    timeout: 200,
                    maximumAge: 0
                }
            );
        });
    }
}
