import { ErrorHandler, inject, Injectable } from '@angular/core';

import { CUSTOM_ERROR_HANDLER } from '../..';

@Injectable({
    providedIn: 'root'
})
export class CustomErrorHandler implements ErrorHandler {
    private readonly _handlers = inject(CUSTOM_ERROR_HANDLER, { optional: true });

    public handleError(error: unknown): void {
        this._handlers?.forEach((errorHandler: ErrorHandler) => {
            errorHandler.handleError(error);
        });
    }
}
