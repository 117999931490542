import { Provider } from '@angular/core';

import { AuthenticationManagementRepositoryAbstract } from './domain/repositories/authentication-management.repository.abstract';
import { MockAuthenticationManagementRepository } from './domain/repositories/authentication-management.repository.mock';
import { AuthenticationManagementUseCase } from './domain/usecases/authentication-management.usecase';
import { AuthenticationManagementUseCaseAbstract } from './domain/usecases/authentication-management.usecase.abstract';
import { AuthenticationManagementRepository } from './infrastructure/repositories/authentication-management.repository';
import { ActivateAccountBodyMapper } from './infrastructure/repositories/mappers/bodies/activate-account-body.mapper';
import { UserMapper } from './infrastructure/repositories/mappers/entities/user.mapper';
import { ForgotPasswordQueryMapper } from './infrastructure/repositories/mappers/queries/forgot-password-query.mapper';
import { ResetPasswordQueryMapper } from './infrastructure/repositories/mappers/queries/reset-password-query.mapper';
import { RetrieveUsernameIdQueryMapper } from './infrastructure/repositories/mappers/queries/retrieve-username-id-query.mapper';
import { RetrieveUsernameIdPresenter } from './infrastructure/repositories/presenters/retrieve-username-id.presenter';
import { ErEmailValidator } from './infrastructure/validators/email.validator';

const providers: Provider[] = [
    {
        provide: AuthenticationManagementUseCaseAbstract,
        useFactory: (userManagementRepository: AuthenticationManagementRepositoryAbstract) =>
            new AuthenticationManagementUseCase(userManagementRepository),
        deps: [AuthenticationManagementRepositoryAbstract]
    },

    /* Mappers */
    UserMapper,
    ActivateAccountBodyMapper,
    ForgotPasswordQueryMapper,
    ResetPasswordQueryMapper,
    RetrieveUsernameIdQueryMapper,

    /* Presenters */
    RetrieveUsernameIdPresenter,

    /* Validators */
    ErEmailValidator
];

export const userManagementProviders: Provider[] = [
    ...providers,
    { provide: AuthenticationManagementRepositoryAbstract, useClass: AuthenticationManagementRepository }
];

export const userManagementTestingProviders: Provider[] = [
    ...providers,
    { provide: AuthenticationManagementRepositoryAbstract, useClass: MockAuthenticationManagementRepository }
];
