import { HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';

import { CORE_CONFIG_TOKEN } from '../../../core.module';
import { PermanentStore } from '../../../modules/store/domain';
import { ACCESS_TOKEN_STORE_KEY } from '../../tokens';

export const bearerTokenInterceptor = (request: HttpRequest<any>, next: HttpHandlerFn): Observable<any> => {
    const { flex } = inject(CORE_CONFIG_TOKEN);
    const accessTokenKey = inject(ACCESS_TOKEN_STORE_KEY, { optional: true });
    const accessToken = inject(PermanentStore).get(accessTokenKey) as string;

    let nextRequest = request;

    if (flex?.domains.find(domain => request.url.includes(domain))) {
        return next(request.clone({ withCredentials: true }));
    }

    if (accessToken) {
        nextRequest = request.clone({ setHeaders: { Authorization: `Bearer ${accessToken}` } });
    }

    return next(nextRequest);
};
