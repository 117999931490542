import { NgClass, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
    selector: 'er-multiline',
    templateUrl: './multiline.component.html',
    styleUrl: './multiline.component.css',
    imports: [MatIconModule, NgClass, NgIf],
    standalone: true
})
export class ErMultilineComponent {
    @Input() public icon: string;
    @Input() public greyed = false;
    @Input() public iconColor: string;
}
