import { Injectable } from '@angular/core';

import { IAddress } from '../../../domain';
import { Mapper } from '../../services/mapper.abstract.service';
import { IAddressEntity } from '../interfaces';

@Injectable({
    providedIn: 'root'
})
export class AddressMapper extends Mapper<IAddressEntity, IAddress> {
    public map(entity: IAddressEntity): IAddress {
        return {
            addressLine1: entity.address_line_1,
            addressLine2: entity.address_line_2,
            addressLine3: entity.address_line_3,
            postCode: entity.post_code,
            city: entity.city,
            country: entity.country,
            countryCode: entity.country_code
        };
    }
}
