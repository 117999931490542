<ng-container *ngIf="!(shouldHideWhenDisabled && disableSwitch)">
    <a
        #trigger="matMenuTrigger"
        class="!md:text-sm flex cursor-pointer flex-row items-center !p-0 !text-xs"
        erGhost
        role="switch"
        [attr.aria-checked]="trigger.menuOpen"
        [matMenuTriggerFor]="lang"
        [ngClass]="{ 'pointer-events-none': disableSwitch }"
        data-test="locale-switch"
    >
        <span [ngClass]="{ '!text-grey-70': disableSwitch, '!text-primary': !disableSwitch }">
            {{ selectedLang.substring(0, 2) }}
        </span>
        <mat-icon
            class="!flex items-center justify-center"
            [ngClass]="{ '!hidden': disableSwitch }"
            [svgIcon]="trigger.menuOpen ? 'arrow-drop-up-rg-24' : 'arrow-drop-down-rg-24'"
            aria-label="Arrow to open menu"
        ></mat-icon>
    </a>
    <mat-menu #lang="matMenu" class="no-radio mt-3" xPosition="before">
        <button
            *ngFor="let language of languages"
            class="rounded-none border-0 focus:shadow-none focus:ring-opacity-0"
            mat-menu-item
            [class.font-bold]="selectedLang === language.code"
            (click)="setLang(language.code)"
        >
            {{ language.label | translate }}
        </button>
    </mat-menu>
</ng-container>
