import { Component, Inject } from '@angular/core';

import { COMPONENTS_ASSETS_PATH } from '../../../tokens/components.token';

@Component({
    selector: 'er-tile-no-data-available',
    templateUrl: './tile-no-data-available.component.html',
    styleUrls: ['./tile-no-data-available.component.css']
})
export class ErTileNoDataAvailableComponent {
    public icon = `${this._assetsPath}/icons/no_data.svg`;

    constructor(@Inject(COMPONENTS_ASSETS_PATH) private readonly _assetsPath: string) {}
}
