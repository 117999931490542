import { Injectable } from '@angular/core';
import { Mapper } from '@edenred/core/infrastructure';

import { google, IPointOfInterest } from '../interfaces';

@Injectable({
    providedIn: 'root'
})
export class MarkerMapper extends Mapper<IPointOfInterest, google.maps.Marker> {
    public map(poi: IPointOfInterest): google.maps.Marker {
        return new google.maps.Marker({
            icon: {
                url: poi.icon.url,
                scaledSize: new google.maps.Size(poi.icon.size.width, poi.icon.size.height)
            },
            position: new google.maps.LatLng(poi.position.latitude, poi.position.longitude)
        });
    }
}
