import { HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';

export const xCorrelationIdInterceptor = (request: HttpRequest<any>, next: HttpHandlerFn): Observable<any> => {
    const nextRequest = request.clone({
        setHeaders: {
            'X-Correlation-Id': `FM-A010687-${DateTime.now().toFormat('yyLLdd-HHmmss-SSS')}-${Math.floor(
                Math.random() * 9000 + 1000
            )}`
        }
    });

    return next(nextRequest);
};
