import { HttpInterceptorFn } from '@angular/common/http';

import { bearerTokenInterceptor, credentialsInterceptor, xCorrelationIdInterceptor, xPaginationInterceptor } from '.';

export const httpInterceptors: HttpInterceptorFn[] = [
    bearerTokenInterceptor,
    xCorrelationIdInterceptor,
    credentialsInterceptor,
    xPaginationInterceptor
];
