import { Injectable } from '@angular/core';

declare const window: {
    dataLayer: unknown[];
} & Window;

@Injectable({
    providedIn: 'root'
})
export class GtmService {
    public pushOnDataLayer(tag: object): void {
        const dataLayer = this.getDataLayer();

        dataLayer.push(tag);
    }

    public getDataLayer(): unknown[] {
        window.dataLayer = window.dataLayer || [];

        return window.dataLayer;
    }
}
