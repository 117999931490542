import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';

@Component({
    selector: 'er-accordion-compact',
    templateUrl: './accordion-compact.component.html',
    imports: [MatExpansionModule, NgClass],
    standalone: true
})
export class ErAccordionCompactComponent {
    @Input({ required: true }) public buttonTitle: string;
    @Input({ required: true }) public buttonClass: string;
    @Input({ required: true }) public expanded = false;

    @Output() public toggle = new EventEmitter<boolean>();

    public isToggling = false;

    public slideToggle(): void {
        this.isToggling = true;
        this.expanded = !this.expanded;
        this.toggle.emit(this.expanded);
        this.isToggling = false;
    }
}
