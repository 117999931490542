interface Message {
    code: string;
    level: string;
    text: string;
}

interface Meta {
    status: string;
    messages: Message[];
}

export interface FlexDTO<T = object> {
    meta: Meta;
    data: T;
}

interface Data<T> {
    content: T;
    // eslint-disable-next-line id-denylist
    number: number;
    size: number;
    totalElements: number;
    totalPages: number;
}

export interface FlexPagedDTO<T = object> {
    meta: Meta;
    data: Data<T>;
}
