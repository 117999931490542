import { Component, inject, Input } from '@angular/core';
import { BreakpointService } from '@edenred/utilities';
import { Observable } from 'rxjs';

import { ILabels } from '../../../interfaces';

@Component({
    selector: 'er-table-filters',
    templateUrl: './table-filters.component.html'
})
export class ErTableFiltersComponent {
    @Input({ required: true }) public labels: ILabels = { clear: 'Clear', done: 'Done', title: 'Filters' };

    private readonly _breakpointService = inject(BreakpointService);

    public isDesktopScreen$(): Observable<boolean> {
        return this._breakpointService.isDesktopScreen$();
    }
}
