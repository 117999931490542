import { ModuleWithProviders, NgModule } from '@angular/core';

import { InMemoryStore } from './services/abstracts/in-memory-store.abstract';
import { PermanentStore } from './services/abstracts/permanent-store.abstract';
import { InMemoryStoreService } from './services/in-memory-store.service';
import { LocalStorageStoreService } from './services/local-storage-store.service';

@NgModule()
export class StoreModule {
    public static forRoot(
        store: InMemoryStore = new InMemoryStoreService(),
        permanentStore: PermanentStore = new LocalStorageStoreService()
    ): ModuleWithProviders<StoreModule> {
        return {
            ngModule: StoreModule,
            providers: [
                {
                    provide: InMemoryStore,
                    useValue: store
                },
                {
                    provide: PermanentStore,
                    useValue: permanentStore
                }
            ]
        };
    }
}
