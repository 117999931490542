import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
    selector: 'er-li',
    templateUrl: './li.component.html',
    imports: [MatIconModule, NgIf],
    standalone: true
})
export class ErLiComponent {
    @Input({ required: true }) public icon: string;
    @Input({ required: true }) public color: string;
}
