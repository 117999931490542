import { ComponentRef, Directive, inject, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

import { ErPasswordToggleComponent } from '../components/password-toggle/password-toggle.component';

@Directive({
    selector: '[erPasswordToggle]',
    standalone: true
})
export class ErPasswordToggleDirective implements OnInit {
    private _wrapperContainer: ComponentRef<ErPasswordToggleComponent>;

    private readonly _templateRef = inject(TemplateRef<unknown>);
    private readonly _viewContainerRef = inject(ViewContainerRef);

    public ngOnInit(): void {
        this._wrapperContainer = this._viewContainerRef.createComponent(ErPasswordToggleComponent);
        this._wrapperContainer.instance.template = this._templateRef;
    }
}
