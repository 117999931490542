import { Directive } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Directive({
    selector: '[erExpandable]'
})
export class ErExpandableDirective {
    private readonly _row$: BehaviorSubject<unknown> = new BehaviorSubject<unknown>(null);

    public setRow(row: unknown): void {
        if (row === this._row$.getValue()) {
            this._row$.next(null);
        } else {
            this._row$.next(row);
        }
    }

    public getRow$(): BehaviorSubject<unknown> {
        return this._row$;
    }
}
