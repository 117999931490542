<button
    #tooltip="matTooltip"
    class="er-tooltip"
    [matTooltip]="tipContent"
    [matTooltipPosition]="'above'"
    (click)="tooltip.toggle()"
    aria-label="Button that displays a tooltip"
    data-test="toggletip-button"
>
    <mat-icon class="shrink-0" svgIcon="info-f-rg-24" data-test="toggletip-button-icon"></mat-icon>
</button>
