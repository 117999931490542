<mat-form-field
    [ngClass]="{
        'ng-dirty': control?.dirty,
        'ng-invalid': !control?.valid,
        'ng-pristine': control?.pristine,
        'ng-touched': control?.touched,
        'ng-valid': control?.valid
    }"
>
    <input
        matInput
        [class]="inputClass"
        [disabled]="isDisabled"
        [value]="value"
        (change)="onChange(parseInt($any($event).target.value))"
        (keyup)="onTouched()"
        data-test="input-currency-field"
    />
    <mat-icon class="!pl-1 !pr-0 text-grey-50" matSuffix [svgIcon]="getIcon()"></mat-icon>
</mat-form-field>
