import { NgClass, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

import { ErEnterToClickDirective } from '../../directives/enter-to-click.directive';
import { ErButtonLoadingComponent } from '../button-loading/button-loading.component';

@Component({
    selector: 'er-simple-modal',
    templateUrl: './simple-modal.component.html',
    styleUrl: './simple-modal.component.css',
    encapsulation: ViewEncapsulation.None,
    imports: [ErEnterToClickDirective, ErButtonLoadingComponent, MatDialogModule, MatIconModule, NgClass, NgIf],
    standalone: true
})
export class ErSimpleModalComponent implements OnInit, OnDestroy {
    @Input() public hasCloseIcon = true;
    @Input() public showWarning = false;
    @Input() public hasConfirmButton = true;
    @Input() public confirmClass = 'primary';
    @Input() public confirmLabel = 'confirm';
    @Input() public confirmDisabled = false;
    @Input() public confirmLoading = false;
    @Input() public confirmDataTest = 'modal-confirm';
    @Input() public hasCancelButton = true;
    @Input() public cancelClass = 'secondary';
    @Input() public cancelLabel = 'cancel';
    @Input() public cancelDisabled = false;
    @Input() public cancelDataTest = 'modal-cancel';
    @Input() public hasFullScreenWidth = false;

    @Output() public confirm = new EventEmitter<null>();
    @Output() public cancel = new EventEmitter<null>();

    private _overlayPane: HTMLDivElement | null = null;

    public ngOnInit(): void {
        this._overlayPane = document.querySelector('.cdk-overlay-pane');

        (document.activeElement as HTMLElement).blur();

        if (this._overlayPane && this.hasFullScreenWidth) {
            this._overlayPane.classList.add('bottom-sheet-full');
        }
    }

    public ngOnDestroy(): void {
        if (this._overlayPane && this.hasFullScreenWidth) {
            this._overlayPane.classList.remove('bottom-sheet-full');
        }
    }
}
