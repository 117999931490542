import { Injectable } from '@angular/core';
import { PhoneNumber, PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';

import { IPhoneNumber } from '../../domain/interfaces';
import { PhoneNumberServiceAbstract } from './phone-number.service.abstract';

@Injectable({ providedIn: 'root' })
export class PhoneNumberService extends PhoneNumberServiceAbstract {
    public phoneNumberUtil: PhoneNumberUtil = PhoneNumberUtil.getInstance();

    private readonly _format = PhoneNumberFormat.E164;

    public format(phoneNumber: string, country: string): string {
        try {
            return this.phoneNumberUtil.format(this._parseAndKeepRawInput(phoneNumber, country) as PhoneNumber, this._format);
        } catch {
            return '';
        }
    }

    public parse(phoneNumber: string, country?: string): IPhoneNumber {
        try {
            const parsed = this.phoneNumberUtil.parse(phoneNumber, country);

            return {
                phoneNumber: (parsed?.getNationalNumber() ?? '').toString() ?? null,
                prefix: parsed ? `+${(parsed.getCountryCode() ?? '').toString()}` : '',
                country: this.phoneNumberUtil.getRegionCodeForNumber(parsed) ?? ''
            };
        } catch {
            return {} as IPhoneNumber;
        }
    }

    public stringify(phoneNumber: IPhoneNumber): string {
        return phoneNumber ? phoneNumber.prefix + phoneNumber.phoneNumber : '';
    }

    private _parseAndKeepRawInput(phoneNumber: string, country: string): PhoneNumber | null {
        try {
            return this.phoneNumberUtil.parseAndKeepRawInput(phoneNumber, country);
        } catch {
            return null;
        }
    }

    public getExampleNumber(country: string, prefix: string): string {
        try {
            return this.phoneNumberUtil.format(this.phoneNumberUtil.getExampleNumber(country), this._format).replace(prefix, '');
        } catch {
            return '';
        }
    }

    public isValidNumberForRegion(phoneNumber: string, country: string): boolean {
        const inputNumber: PhoneNumber | null = this._parseAndKeepRawInput(phoneNumber, country);
        return (inputNumber && this.phoneNumberUtil.isValidNumberForRegion(inputNumber, country)) ?? false;
    }
}
