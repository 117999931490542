import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { AuthenticationManagementFactory } from './authentication-management.factory';
import { userManagementProviders } from './authentication-management.providers';
import { AUTHENTICATION_MANAGEMENT_TOKEN } from './authentication-management.token';
import {
    IAuthenticationManagement,
    IAuthenticationManagementConfig
} from './infrastructure/config/authentication-management.interface';

@NgModule({
    imports: [CommonModule]
})
export class AuthenticationManagementModule {
    public static forRoot(
        config: IAuthenticationManagementConfig
    ): ModuleWithProviders<AuthenticationManagementModule> {
        return {
            ngModule: AuthenticationManagementModule,
            providers: [
                {
                    provide: AUTHENTICATION_MANAGEMENT_TOKEN,
                    useFactory: (): IAuthenticationManagement => AuthenticationManagementFactory(config)
                },
                ...userManagementProviders
            ]
        };
    }
}
