import { FormControl, FormGroup } from '@angular/forms';

import { IPhoneNumberForm } from '../interfaces';

export class PhoneNumberForm {
    public static create(): FormGroup<IPhoneNumberForm> {
        return new FormGroup<IPhoneNumberForm>({
            phoneNumber: new FormControl(),
            country: new FormControl(),
            prefix: new FormControl()
        });
    }
}
