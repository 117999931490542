import { Observable } from 'rxjs';

/* istanbul ignore next */
export abstract class TranslateServiceAbstract {
    protected abstract _use(locale: string): void;
    public abstract get$(key: string | string[], interpolateParams?: unknown): Observable<string>;
    public abstract stream$(key: string | string[], interpolateParams?: unknown): Observable<string>;
    public abstract setDefaultLang(key: string): void;
    public abstract instant(key: string | string[], interpolateParams?: unknown): string;
}
