import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { EMessageType } from '../../enums/message-type.enum';
import { IMessageConfig, IMessageType } from '../../interfaces/messages.interfaces';

@Component({
    selector: 'er-message',
    templateUrl: './message.component.html',
    styleUrl: './message.component.css',
    imports: [MatIconModule, NgClass],
    standalone: true
})
export class ErMessageComponent {
    @Input({ required: true }) public type: EMessageType;
    @Input() public background = false;
    @Input() public isRounded = true;
    @Input() public innerClass = '';
    @Input() public noPadding = false;

    public get messageConfig(): IMessageType {
        return IMessageConfig[this.type];
    }
}
