import { Component } from '@angular/core';

import { ErLoaderComponent } from '../loader/loader.component';

@Component({
    selector: 'er-table-loader',
    templateUrl: './table-loader.component.html',
    imports: [ErLoaderComponent],
    standalone: true
})
export class ErTableLoaderComponent {}
