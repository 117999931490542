import { AsyncPipe, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { BehaviorSubject, Subject } from 'rxjs';

import { ErEnterToClickDirective } from '../../directives/enter-to-click.directive';
import { ErGhostDirective } from '../../directives/ghost.directive';

export enum DisplayMode {
    View = 'view',
    Edit = 'edit'
}

@Component({
    selector: 'er-editable-input',
    templateUrl: './editable-input.component.html',
    styleUrl: './editable-input.component.css',
    imports: [AsyncPipe, NgIf, ErEnterToClickDirective, ErGhostDirective, MatIconModule, ReactiveFormsModule],
    standalone: true
})
export class ErEditableInputComponent {
    public displayMode = DisplayMode;

    @Input({ required: true }) public mode$: Subject<DisplayMode> | BehaviorSubject<DisplayMode> = new BehaviorSubject<DisplayMode>(
        DisplayMode.View
    );
    @Input({ required: true }) public control: FormControl = new FormControl('');
    @Input({ required: true }) public placeholder: string;
    @Input({ required: true }) public editLabel: string;

    @Output() public controlChange = new EventEmitter<FormControl>();
    @Output() public save = new EventEmitter<null>();

    public onEdit(): void {
        this.mode$.next(DisplayMode.Edit);
    }

    public onSave(): void {
        this.controlChange.emit(this.control);
        this.save.emit(null);
    }
}
