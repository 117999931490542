import { ETenant } from '@edenred/core/modules/authentication-management/domain/enums';

export abstract class OrchestratorAbstract {
    public abstract getTenant(): ETenant;
    public abstract getLegalSentenceKey(): string;
    public abstract getDataProtectionInformationUrlKey(): string;
    public abstract afterCreateAccount(): void;
    public abstract afterCreateAccountLinkExpired(): void;
    public abstract afterRequestPasswordLink(): void;
    public abstract afterResetPassword(): void;
    public abstract afterResetPasswordLinkExpired(email: string): void;
}
