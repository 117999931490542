<div class="flex items-center justify-center" erEnterToClick>
    <div
        *ngIf="optionParentComponent.multiple"
        class="box-border flex h-4 w-4 items-center justify-center rounded-[.25rem] border"
        [ngClass]="selected ? 'red-100 !border-grey-30 bg-primary' : 'border-grey-30'"
    >
        <mat-icon *ngIf="selected" class="shrink-0 text-base text-white" svgIcon="done-rg-24"></mat-icon>
    </div>
    <span #label class="text-base md:whitespace-nowrap" [ngClass]="{ 'ml-2': optionParentComponent.multiple }">
        <ng-content></ng-content>
    </span>
</div>
