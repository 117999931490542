<div class="flex items-center">
    <mat-icon
        *ngIf="!valid"
        class="mr-2 !h-4 !w-4 !pl-[1px] !pt-[1px] !text-[14px] text-error-100"
        svgIcon="cancel-f-rg-24"
        [ngClass]="{ 'text-error-100': dirty, 'text-grey-30': !dirty }"
    ></mat-icon>
    <mat-icon
        *ngIf="valid"
        class="mr-2 !h-4 !w-4 !pl-[1px] !pt-[1px] !text-[14px] text-success-100"
        svgIcon="check-circle-f-rg-24"
    ></mat-icon>
    <ng-content></ng-content>
</div>
