import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';

@Component({
    selector: 'er-accordion',
    templateUrl: './accordion.component.html',
    imports: [MatExpansionModule, NgClass],
    standalone: true
})
export class ErAccordionComponent {
    @Input({ required: true }) public title: string;
    @Input({ required: true }) public expanded = false;
    @Input({ required: true }) public justify: 'left' | 'center' = 'left';

    @Output() public openStateChange = new EventEmitter<boolean>();

    public toggleOpenState(state: boolean): void {
        this.expanded = state;
        this.openStateChange.emit(state);
    }
}
