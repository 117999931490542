import { EventEmitter, InjectionToken } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface OptionParentComponent<T> {
    multiple?: boolean;
    value$: BehaviorSubject<T | T[]>;
    onChange: (value: unknown) => unknown;
    onTouched: () => unknown;
    writeValue(value: T | T[]): void;
    selectionChange: EventEmitter<unknown>;
}

export const OPTION_PARENT_COMPONENT = new InjectionToken<OptionParentComponent<unknown>>('OPTION_PARENT_COMPONENT');
