import { NgIf } from '@angular/common';
import { Component, forwardRef, Input } from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    FormControl,
    FormsModule,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ReactiveFormsModule,
    ValidationErrors,
    Validators
} from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { ErrorKey, ErValidators } from '@edenred/utilities';
import { LocaleServiceAbstract } from '@edenred-falcon/shared/angular/i18n';
import { omit } from 'ramda';

import { ErFeatureFlagDirective } from '../../directives/feature-flag.directive';
import { ErPasswordToggleDirective } from '../../directives/password-toggle.directive';
import { EComponentFeature } from '../../enums/features';
import { ErErrorComponent } from '../error/error.component';
import { ErPasswordToggleComponent } from '../password-toggle/password-toggle.component';
import { EPinCodeState, PinCode } from './index';

@Component({
    selector: 'er-pin-code-choicer',
    templateUrl: './pin-code-choicer.component.html',
    styleUrl: './pin-code-choicer.component.css',
    imports: [
        ErErrorComponent,
        ErPasswordToggleDirective,
        ErPasswordToggleComponent,
        ErFeatureFlagDirective,
        FormsModule,
        MatRadioModule,
        NgIf,
        ReactiveFormsModule
    ],
    standalone: true,
    providers: [
        {
            provide: ErErrorComponent,
            deps: [LocaleServiceAbstract]
        },
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ErPinCodeChoicerComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => ErPinCodeChoicerComponent),
            multi: true
        }
    ]
})
export class ErPinCodeChoicerComponent implements ControlValueAccessor, Validators {
    @Input() public keepSameLabel: string;
    @Input({ required: true }) public generateLabel: string;
    @Input({ required: true }) public chooseLabel: string;
    @Input() public allowKeepSame = false;

    public EComponentFeature = EComponentFeature;
    public EPinCodeState = EPinCodeState;

    public disabled = false;

    protected stateFormControlProxy: FormControl<EPinCodeState | null> = new FormControl(EPinCodeState.GENERATE_PIN);
    protected codeFormControlProxy: FormControl<string | null> = new FormControl(null);

    public writeValue(pinCode: PinCode): void {
        this.stateFormControlProxy.setValue(pinCode.state);

        if (this.stateFormControlProxy.value === EPinCodeState.CUSTOM_PIN) {
            this.codeFormControlProxy.setValue(this.codeFormControlProxy.value ?? null);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public onChange = (_value: unknown): void => undefined;
    public registerOnChange(onChange: (_value: unknown) => unknown): void {
        this.onChange = onChange;
    }

    public onTouched = (): void => undefined;
    public registerOnTouched(onTouched: () => unknown): void {
        this.onTouched = onTouched;
    }

    public setDisabledState?(disabled: boolean): void {
        this.disabled = disabled;
    }

    public onValueChange(customCode: string): void {
        if (this.stateFormControlProxy.value === EPinCodeState.CUSTOM_PIN) {
            this.codeFormControlProxy.setValue(customCode ?? null);
            this.codeFormControlProxy.markAsDirty();
            this.codeFormControlProxy.markAsTouched();
            this.onChange({ state: this.stateFormControlProxy.value, code: this.codeFormControlProxy.value });
            this.onTouched();
        }
    }

    public validate(_control: AbstractControl<PinCode>): ValidationErrors {
        if (_control.value.state === EPinCodeState.CUSTOM_PIN) {
            this.codeFormControlProxy.setValue(_control.value.code);
        }
        const inheritedErrors = omit([ErrorKey.REQUIRED, ErrorKey.CARD_PIN], _control.errors);
        return { ...inheritedErrors, ...this.codeFormControlProxy.errors };
    }

    public onPinCodeStateChange(state: EPinCodeState): void {
        switch (state) {
            case EPinCodeState.CUSTOM_PIN:
                this.stateFormControlProxy.setValue(state);
                this.codeFormControlProxy.setValue(null);
                this.codeFormControlProxy.markAsPristine();
                this.codeFormControlProxy.addValidators([Validators.required, ErValidators.cardPIN()]);
                this.codeFormControlProxy.updateValueAndValidity();
                this.onChange({ state: this.stateFormControlProxy.value, code: this.codeFormControlProxy.value });
                break;
            default:
                this.stateFormControlProxy.setValue(state);
                this.codeFormControlProxy.setValue(null);
                this.codeFormControlProxy.clearValidators();
                this.codeFormControlProxy.updateValueAndValidity();
                this.onChange({ state: this.stateFormControlProxy.value });
        }
        this.onTouched();
    }
}
