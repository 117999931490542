import { HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';

import { CORE_CONFIG_TOKEN } from '../../../core.module';

export const credentialsInterceptor = (request: HttpRequest<any>, next: HttpHandlerFn): Observable<any> => {
    const { account, mulesoft } = inject(CORE_CONFIG_TOKEN);

    if (!request.headers.has('x-client-id') && !request.headers.has('x-client-secret')) {
        if (account?.host && request.url.includes(account.host)) {
            return next(request.clone({ setHeaders: account.headers }));
        }

        if (mulesoft.domains.find(domain => request.url.includes(domain))) {
            return next(request.clone({ setHeaders: mulesoft.headers }));
        }
    }

    return next(request);
};
