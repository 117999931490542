import { Mapper } from '../../../../../../infrastructure/services/mapper.abstract.service';
import { IResetPasswordPayload } from '../../../../domain/interfaces';
import { IResetPasswordQuery } from '../../interfaces';

export class ResetPasswordQueryMapper extends Mapper<IResetPasswordPayload, IResetPasswordQuery> {
    public map(payload: IResetPasswordPayload): IResetPasswordQuery {
        return this.sanitize({
            username: payload.email,
            new_password: payload.password,
            code: payload.token
        });
    }
}
