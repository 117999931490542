import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { ErExpandableIndicatorForComponent } from './components/indicator/expandable-indicator-for.component';
import { ErExpandableDirective } from './directives/expandable.directive';
import { ErExpandableIndicatorForDirective } from './directives/expandable-indicator-for.directive';
import { ErExpandableRowDirective } from './directives/expandable-row.directive';
import { ErExpandableTriggerForDirective } from './directives/expandable-trigger-for.directive';

@NgModule({
    declarations: [
        ErExpandableIndicatorForDirective,
        ErExpandableDirective,
        ErExpandableRowDirective,
        ErExpandableTriggerForDirective,
        ErExpandableIndicatorForComponent
    ],
    imports: [CommonModule, MatIconModule],
    exports: [
        ErExpandableIndicatorForDirective,
        ErExpandableDirective,
        ErExpandableRowDirective,
        ErExpandableTriggerForDirective
    ]
})
export class ErExpandableModule {}
