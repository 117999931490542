import { NgClass, NgForOf, NgIf } from '@angular/common';
import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { LocaleServiceAbstract, SUPPORTED_LOCALES, TranslateModule } from '@edenred-falcon/shared/angular/i18n';

import { ErGhostDirective } from '../../../directives/ghost.directive';
import { ILanguageCode } from '../interfaces/language-code.interface';
import { I18N_LANGUAGE_LABEL_PREFIX } from '../tokens/i18n.token';

@Component({
    selector: 'er-locale-switch',
    templateUrl: './locale-switch.component.html',
    imports: [ErGhostDirective, MatMenuModule, MatIconModule, NgIf, NgClass, NgForOf, TranslateModule],
    standalone: true
})
export class ErLocaleSwitchComponent implements OnInit {
    @Input() public shouldHideWhenDisabled = false;

    public localeCode: string;
    public selectedLang: string;
    public languages: ILanguageCode[];

    private readonly _destroyRef = inject(DestroyRef);
    private readonly _localeService = inject(LocaleServiceAbstract);
    private readonly _supportedLocales = inject<string[]>(SUPPORTED_LOCALES);
    private readonly _i18LanguageLabelPrefix = inject(I18N_LANGUAGE_LABEL_PREFIX);

    public ngOnInit(): void {
        this.languages = this._getLabels();
        this._localeService
            .getLang$()
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe(lang => {
                this.selectedLang = lang;
            });
    }

    public setLang(languageCode: string): void {
        this._localeService.setLocale(languageCode);
    }

    private _getLabels(): ILanguageCode[] {
        return this._supportedLocales.map((locale: string) => ({
            code: locale,
            label: `${this._i18LanguageLabelPrefix}${locale.substring(0, 2)}`
        }));
    }

    public get disableSwitch(): boolean {
        return this.languages.length < 2;
    }
}
