import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@edenred/authentication/environments/environment';
import { IViewModel } from '@edenred/core';
import { ITermsOfUse } from '@edenred/core/modules/authentication-management/domain/interfaces';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { IGetTermsOfUsePayload } from '../../domain/interfaces';
import { TermsRepositoryAbstract } from '../../domain/repositories/terms.repository.abstract';
import { GetTermsOfUseExceptionHandler } from './handlers/get-terms-of-use-exception.handler';
import { IGetTermsOfUseQuery, IResponse, ITermsOfUseEntity } from './interfaces';
import { GetTermsOfUseQueryMapper } from './mappers/queries/get-terms-of-use-query.mapper';
import { GetTermsOfUsePresenter } from './presenters/get-terms-of-use.presenter';

@Injectable({
    providedIn: 'root'
})
export class EasyfuelTermsRepository extends TermsRepositoryAbstract {
    private readonly _host: string = environment.apis.account.host;

    private readonly _httpClient = inject(HttpClient);
    private readonly _getTermsOfUsePresenter = inject(GetTermsOfUsePresenter);
    private readonly _getTermsOfUseExceptionHandler = inject(GetTermsOfUseExceptionHandler);
    private readonly _getTermsOfUseQueryMapper = inject(GetTermsOfUseQueryMapper);

    public get$(payload: IGetTermsOfUsePayload): Observable<IViewModel<ITermsOfUse>> {
        const endpoint = `${this._host}${environment.apis.account.endpoints.getTermsOfUse}`;

        const query: IGetTermsOfUseQuery = this._getTermsOfUseQueryMapper.map(payload);

        return this._httpClient.get(endpoint, { params: { ...query } }).pipe(
            map(response => this._getTermsOfUsePresenter.getViewModel(response as IResponse<ITermsOfUseEntity>)),
            catchError((response: HttpErrorResponse) =>
                throwError(() => this._getTermsOfUseExceptionHandler.handle(response))
            )
        );
    }
}
