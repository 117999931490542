export const defaultPatterns: Record<string, RegExp> = {
    accountName: /^[a-z\d\s.,'+&/äöü-]+$/i,
    alphanumeric: /^[\w\d\s]*$/i,
    bic: /^(?<bankCode>[a-z]{4})(?<countryCode>[a-z]{2})(?<locationCode>[a-z\d]{2})(?<branchCode>[a-z\d]{3})?$/i,
    cardPin: /^(?!0000)\d{4}$/,
    companyNumber: /^HR([_a-z\d\s])*$/i,
    email: /^[a-z\d!#$%&'*/=?^_`{|}~-]+((\.[a-z\d!#$%&'*/=?^_`{|}~-]+)?)+@(?:[a-z\d](?:[a-z\d-]*[a-z\d])?\.)+[a-z\d](?:[a-z\d-]*[a-z\d])?$/,
    latin1: /^[\x20-\x7e\xa0-\xa3\xa5\xa7\xa9-\xb3\xb5-\xb7\xb9-\xbb\xbf-\xff\u20ac\u0160\u0161\u017d\u017e\u0152\u0153\u0178]+$/i,
    lettersAndNumbers: /(((?=[a-z]+).*(?=\d+))|((?=\d+).*(?=[a-z]+)))/i,
    lowerAndUpperCase: /(((?=[a-z]+).*(?=[A-Z]+))|((?=[A-Z]+).*(?=[a-z]+)))/,
    numbersOnly: /^\d*$/,
    numbersSlashsAndHyphens: /^[/\-\d]*$/,
    plateNumber:
        /(?=(^(?![ -]+).*))(?=(^(?!.*( {2}|-{2}| -|- ))))(?=(^[A-Z\dÄÖÜ]*([A-Z\dÄÖÜ]+[ -]?[A-Z\dÄÖÜ]*){0,2}[A-Z\dÄÖÜ]*([ -]?E)?$))(?=^([A-Z\dÄÖÜ]{1}[ -]?){3,9}([ -]?E)?$)(?=^.*[^ -]$)/g,
    phoneNumber: /^\+\d{1,4}\d{6,14}$/,
    specialCharacters: /(?=[.:;,+*?!^@&$€%#()[\]{}|\\])/
};
