import { ChangeDetectionStrategy, Component, signal } from '@angular/core';

export type TablePlaceholderType = 'icon' | 'icon-text' | 'text';

@Component({
    selector: 'er-table-placeholder',
    templateUrl: './table-placeholder.component.html',
    styleUrl: './table-placeholder.component.css',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErTablePlaceholderComponent {
    public type = signal<TablePlaceholderType>('text');
}
