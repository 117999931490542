import { Component, Input } from '@angular/core';

@Component({
    selector: 'er-tile-footer',
    templateUrl: './tile-footer.component.html'
})
export class ErTileFooterComponent {
    @Input() public border: boolean;
    @Input() public grey: boolean;
    @Input() public row: boolean;
}
