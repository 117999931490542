import { APP_INITIALIZER, Provider } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { I18N_LANGUAGE_LABEL_PREFIX } from '@edenred/components';
import {
    errorProviders,
    FATAL_ERROR_HANDLER_ROUTE_REDIRECTION,
    localeProviders,
    materialProviders
} from '@edenred/core/infrastructure';

import { TermsRepositoryAbstract } from '../domain/repositories/terms.repository.abstract';
import { GetTermsOfUseUseCase } from '../domain/usecases/get-terms-of-use.usecase';
import { GetTermsOfUseUseCaseAbstract } from '../domain/usecases/terms.usecase.abstract';
import { orchestrationFactory, supportedLocalesFactory } from './factories';
import { materialIconRegistryInitializer } from './initializers/material.initializer';
import { OrchestratorAbstract } from './orchestrators';
import { termsRepositoryFactory } from './repositories/factories/terms.repository.factory';
import { GetTermsOfUseQueryMapper } from './repositories/mappers/queries/get-terms-of-use-query.mapper';

export const providers: Provider[] = [
    ...localeProviders(supportedLocalesFactory()),
    ...materialProviders,
    ...errorProviders,
    { provide: FATAL_ERROR_HANDLER_ROUTE_REDIRECTION, useValue: '/whoops' },
    { provide: I18N_LANGUAGE_LABEL_PREFIX, useValue: 'misc.language.label.long.' },
    { provide: OrchestratorAbstract, useFactory: orchestrationFactory },
    {
        provide: GetTermsOfUseUseCaseAbstract,
        useFactory: (termsRepository: TermsRepositoryAbstract) => new GetTermsOfUseUseCase(termsRepository),
        deps: [TermsRepositoryAbstract]
    },
    { provide: TermsRepositoryAbstract, useFactory: termsRepositoryFactory },
    {
        provide: APP_INITIALIZER,
        useFactory: materialIconRegistryInitializer,
        deps: [DomSanitizer, MatIconRegistry],
        multi: true
    },
    GetTermsOfUseQueryMapper
];

export const appProviders = [...providers];

export const testingAppProviders = [...providers];
