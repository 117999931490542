import { Injectable } from '@angular/core';

import { Mapper } from '../../../../../infrastructure/services/mapper.abstract.service';
import { ETenant } from '../../../domain/enums';
import { EPlanCode } from '../enums';

@Injectable({
    providedIn: 'root'
})
export class PlanCodeMapper extends Mapper<ETenant, EPlanCode> {
    public map(tenant: ETenant): EPlanCode {
        switch (tenant) {
            case ETenant.EASYFUEL:
                return EPlanCode.EASYFUEL;
            default:
                return EPlanCode.FALCON;
        }
    }
}
