import { inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Subject, switchMap } from 'rxjs';

import { TranslateServiceAbstract } from '../translate/abstracts/translate.service.abstract';

export interface ITitle {
    label: string;
    interpolateParams?: Record<string, unknown>;
}

@Injectable({
    providedIn: 'root'
})
export class TitleService {
    private readonly _updateTitle$: Subject<ITitle> = new Subject();

    private readonly _title = inject(Title);
    private readonly _translateService = inject(TranslateServiceAbstract);

    constructor() {
        this._updateTitle$
            .pipe(switchMap(title => this._translateService.stream$(title.label, title.interpolateParams)))
            .subscribe((newTitle: string) => {
                this._title.setTitle(newTitle);
            });
    }

    public updateTitle(data: ITitle): void {
        this._updateTitle$.next(data);
    }
}
