import { NgClass, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ErLoaderComponent } from '../loader/loader.component';

@Component({
    selector: 'er-button-loading',
    templateUrl: './button-loading.component.html',
    imports: [ErLoaderComponent, NgClass, NgIf],
    standalone: true
})
export class ErButtonLoadingComponent {
    @Input({ required: true }) public isLoading: boolean | null;
    @Input({ required: true }) public isDisabled: boolean | null;
    @Input({ required: true }) public buttonClass: string;
    @Input() public buttonDataTest = 'button-loading';
    @Input() public type = 'button';

    @Output() public buttonClick: EventEmitter<void> = new EventEmitter();

    public onClick(): void {
        this.buttonClick.emit();
    }
}
