<div class="h-full @container/nodata" data-test="no-data-available">
    <div
        class="flex h-full flex-col items-center justify-between @2xs/nodata:flex-row-reverse @2xs/nodata:items-stretch @xs/nodata:items-center"
    >
        <img
            class="mb-2 max-h-[96px] min-h-[48px] @2xs/nodata:mb-0"
            alt="{{ 'misc.no_data.title' | translate }}"
            title="{{ 'misc.no_data.title' | translate }}"
            [src]="icon"
        />
        <div class="w-full @2xs/nodata:flex @2xs/nodata:flex-col @2xs/nodata:justify-end">
            <p class="m-0 text-sm font-medium text-grey-60">
                {{ 'misc.no_data.title' | translate }}
            </p>
            <p class="mt-2 text-sm text-grey-50">{{ 'misc.no_data.desc' | translate }}</p>
        </div>
    </div>
</div>
