import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
    selector: 'er-layout',
    template: '<router-outlet></router-outlet>',
    imports: [CommonModule, RouterModule],
    standalone: true
})
export class LayoutComponent {}
