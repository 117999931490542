import { Routes } from '@angular/router';

export const appRoutes: Routes = [
    {
        path: '',
        loadChildren: () => import('../../authentication/authentication.routing').then(m => m.authenticationRoutes)
    },
    {
        path: 'password',
        loadChildren: () =>
            import('../../password-management/password-management.routing').then(m => m.passwordManagementRoutes)
    },
    {
        path: 'tenants',
        loadChildren: () => import('../../tenants/tenants.routing').then(m => m.tenantsRoutes)
    },
    {
        path: 'whoops',
        loadChildren: () => import('../../whoops/whoops.routing').then(m => m.whoopsRoutes)
    },
    {
        path: '**',
        redirectTo: ''
    }
];
