import { Component, forwardRef, Injector, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';

@Component({
    selector: 'er-error-message',
    templateUrl: './error-message.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ErErrorMessageComponent),
            multi: true
        }
    ],
    standalone: true
})
export class ErErrorMessageComponent implements ControlValueAccessor, OnInit {
    @Input({ required: true }) public error: string;

    public ngControl: NgControl;

    constructor(private readonly _injector: Injector) {}

    public ngOnInit(): void {
        this.ngControl = this._injector.get(NgControl);
    }

    public writeValue(): void {
        // This method is required by ControlValueAccessor
    }

    public registerOnChange(): void {
        // This method is required by ControlValueAccessor
    }

    public registerOnTouched(): void {
        // This method is required by ControlValueAccessor
    }
}
