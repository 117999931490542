<button
    class="!px-0 !py-0 min-h-0 text-xs {{ style }}"
    matBadgeColor="accent"
    matBadgePosition="above after"
    type="button"
    [disabled]="disabled || isLoading"
    [matBadge]="badge"
    [matBadgeDisabled]="disabled"
    [matBadgeHidden]="isNil(badge) || isLoading"
    [matBadgeSize]="badgeSize"
    [ngClass]="{
        'h-7 w-7': size === EButtonSize.BASE,
        'h-6 w-6': size === EButtonSize.SM,
        'h-5 w-5 ': size === EButtonSize.XS
    }"
>
    <mat-icon
        *ngIf="!isLoading && icon"
        class="!icon-outlined flex items-center justify-center"
        [ngClass]="{
            '!icon-xs ': size === EButtonSize.XS,
            '!icon-sm': size === EButtonSize.SM,
            '!icon-md': size === EButtonSize.BASE
        }"
        [svgIcon]="icon"
    ></mat-icon>
    <er-loader *ngIf="isLoading" [size]="loaderSize" [theme]="loaderTheme"></er-loader>
    <ng-content></ng-content>
</button>
