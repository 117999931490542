export interface IMessageEntity {
    code: string;
    level: string;
    text: string;
}

export interface IMetaEntity {
    status: string;
    count?: number;
    messages?: IMessageEntity[];
    number_of_records?: number;
}

/**
 * The transactions page entity uses snake case.
 * This is a workaround until all paginated resources achieve consistency.
 */
export interface IPageEntity {
    page_number?: number;
    page_total?: number;
    page_item_limit?: number;
    page_total_items?: number;
    pageNumber?: number;
    pageTotal?: number;
    pageItemLimit?: number;
    pageTotalItems?: number;
}

export interface IAddressEntity {
    address_line_1: string;
    address_line_2: string;
    address_line_3: string;
    city: string;
    post_code: string;
    country: string;
    country_code: string;
}
