import { Pipe, PipeTransform } from '@angular/core';
import { LocaleServiceAbstract } from '@edenred-falcon/shared/angular/i18n';
import { DateTime, DateTimeFormatOptions } from 'luxon';
import { isNil } from 'ramda';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({ name: 'erDate', standalone: true })
export class ErDatePipe implements PipeTransform {
    constructor(private readonly _localeService: LocaleServiceAbstract) {}

    public transform(
        value: DateTime | string | null,
        format: string | DateTimeFormatOptions = 'DATE_SHORT'
    ): Observable<string | null> {
        if (isNil(value)) {
            return of(null);
        }

        const dateTime = value instanceof DateTime ? value : DateTime.fromISO(value);
        const dateTimeFormat: DateTimeFormatOptions = (
            typeof format === 'string' ? DateTime[format as keyof typeof DateTime] ?? DateTime.DATE_SHORT : format
        ) as DateTimeFormatOptions;

        return this._localeService
            .getLocale$()
            .pipe(map(locale => dateTime.setLocale(locale).toLocaleString(dateTimeFormat)));
    }
}
