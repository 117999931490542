import { Component, HostBinding, Input } from '@angular/core';

export type TileItemDirection = 'column' | 'row';

@Component({
    selector: 'er-tile-item',
    templateUrl: './tile-item.component.html',
    styleUrls: ['./tile-item.component.css']
})
export class ErTileItemComponent {
    @Input({ required: true }) public label: string;
    @Input() public direction: TileItemDirection = 'row';
    @Input() public labelStyle: string;

    @HostBinding('class')
    public get cssClasses(): { [cssClass: string]: boolean } {
        return {
            'flex-col': this._isColumnDirection(),
            'md:flex-row': this._isColumnDirection(),
            'mb-4': this._isColumnDirection()
        };
    }

    private _isColumnDirection(): boolean {
        return this.direction === 'column';
    }
}
