import { ChangeDetectionStrategy, Component } from '@angular/core';

import { ErLoaderComponent } from '../loader/loader.component';

@Component({
    selector: 'er-input-placeholder',
    templateUrl: './input-placeholder.component.html',
    styleUrl: './input-placeholder.component.css',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [ErLoaderComponent],
    standalone: true
})
export class ErInputPlaceholderComponent {
    public size = 'small' as const;
    public theme = 'placeholder' as const;
}
