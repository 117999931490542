export { ErNgxTranslateModule } from './modules/ngx-translate/ngx-translate.module';
export { LocaleServiceAbstract } from './services/locale/abstracts/locale.service.abstract';
export { LocaleService } from './services/locale/locale.service';
export { TestingLocaleService } from './services/locale/testing/locale.testing.service';
export { ITitle, TitleService } from './services/title/title.service';
export { TitleTranslateStrategy } from './services/title/title-translate.strategy';
export { TranslateServiceAbstract } from './services/translate/abstracts/translate.service.abstract';
export { TestingTranslateService } from './services/translate/testing/translate.testing.service';
export { SUPPORTED_LOCALES } from './tokens/locale';
export { TranslateModule } from './translate.module';
