import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ErPipesModule } from '@edenred/utilities';

import { ErDirectivesModule } from '../../directives.module';
import { ErDatePickerComponent } from './components/date-picker/date-picker.component';
import { ErDatePickerHeaderComponent } from './components/date-picker-header/date-picker-header.component';
import { ErDateRangePickerComponent } from './components/date-range-picker/date-range-picker.component';

@NgModule({
    declarations: [ErDatePickerComponent, ErDateRangePickerComponent, ErDatePickerHeaderComponent],
    imports: [
        CommonModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        MatDatepickerModule,
        MatIconModule,
        ErPipesModule,
        MatInputModule,
        ErDirectivesModule
    ],
    exports: [ErDatePickerHeaderComponent, ErDateRangePickerComponent, ErDatePickerComponent]
})
export class ErDatePickerModule {}
