import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

export const materialIconRegistryInitializer = (domSanitizer: DomSanitizer, matIconRegistry: MatIconRegistry) => (): boolean => {
    const iconsPath = '/assets/apps/authentication/icons';

    const iconsRegular24 = ['arrow-drop-down', 'arrow-drop-up', 'arrow-back', 'close', 'visibility', 'visibility-off'];

    const iconsFilledRegular24 = ['cancel', 'check-circle', 'info'];

    [
        ...iconsRegular24.map(icon => [`${icon}-rg-24`, `${iconsPath}/fill-0-wght-400-grad-0-opsz-24/${icon}.svg`]),
        ...iconsFilledRegular24.map(icon => [`${icon}-f-rg-24`, `${iconsPath}/fill-1-wght-400-grad-0-opsz-24/${icon}.svg`])
    ].forEach(([iconName, iconUrl]) => matIconRegistry.addSvgIcon(iconName, domSanitizer.bypassSecurityTrustResourceUrl(iconUrl)));

    return true;
};
