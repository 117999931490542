import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FatalErrorException } from '@edenred/core';

import { ETermsErrors } from '../../../domain/enums';

@Injectable({
    providedIn: 'root'
})
export class GetTermsOfUseExceptionHandler {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public handle(response: HttpErrorResponse): ETermsErrors {
        throw new FatalErrorException();
    }
}
