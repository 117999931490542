import { Directive, inject, Input, TemplateRef, ViewContainerRef } from '@angular/core';

import { FeatureFlagService } from '../services/feature-flag.service';

@Directive({
    selector: '[erFeatureFlag]',
    standalone: true
})
export class ErFeatureFlagDirective {
    private readonly _viewContainerRef = inject(ViewContainerRef);
    private readonly _templateRef = inject(TemplateRef);
    private readonly _featureFlagService = inject(FeatureFlagService);

    @Input()
    public set erFeatureFlag(feature: string) {
        if (this._featureFlagService.isEnabled(feature)) {
            this._viewContainerRef.createEmbeddedView(this._templateRef);
        } else {
            this._viewContainerRef.clear();
        }
    }
}
