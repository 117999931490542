import { Component, Input } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

type Size = 'tiny' | 'small' | 'medium' | 'large';

type Theme = 'primary' | 'light' | 'placeholder';

@Component({
    selector: 'er-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.css'],
    imports: [MatProgressSpinnerModule],
    standalone: true
})
export class ErLoaderComponent {
    @Input() public size: Size = 'medium';
    @Input() public theme: Theme = 'primary';

    public config = {
        size: {
            tiny: 15,
            small: 20,
            medium: 40,
            large: 60
        },
        theme: {
            primary: 'primary',
            light: 'light',
            placeholder: 'placeholder'
        }
    };
}
