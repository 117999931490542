import { Injectable } from '@angular/core';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as tailwindConfig from '../tailwind.preset';
import { ThemeConfigServiceAbstract } from './theme-config.service.abstract';

@Injectable({
    providedIn: 'root'
})
export class ThemeConfigService extends ThemeConfigServiceAbstract {
    private readonly _config = tailwindConfig;

    public get(): Record<string, unknown> {
        return { ...this._config.theme };
    }
}
