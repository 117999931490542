import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { AfterContentInit, Component, ContentChild, EventEmitter, Inject, Input, Output, ViewEncapsulation } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Observable, of } from 'rxjs';

import { COMPONENTS_ASSETS_PATH } from '../../tokens/components.token';
import { ErLoaderComponent } from '../loader/loader.component';
import { ErTileModule } from '../tile';
import { WidgetPlaceholderDirective } from './widget-placeholder.directive';

export enum EWidgetState {
    LOADING = 'LOADING',
    LOADED = 'LOADED',
    ERROR = 'ERROR'
}

@Component({
    selector: 'er-widget',
    templateUrl: './widget.component.html',
    styleUrl: './widget.component.css',
    encapsulation: ViewEncapsulation.None,
    imports: [AsyncPipe, ErTileModule, ErLoaderComponent, MatIconModule, NgClass, NgIf],
    standalone: true
})
export class ErWidgetComponent implements AfterContentInit {
    @ContentChild(WidgetPlaceholderDirective) public placeholder!: WidgetPlaceholderDirective;

    @Input() public title: string;
    @Input() public subTitle: string;
    @Input() public actionText: string;
    @Input() public isActionDisabled: boolean;
    @Input() public isActionVisible: boolean;
    @Input() public state$: Observable<EWidgetState> = of(EWidgetState.LOADED);
    @Input() public customClass: string;

    @Output() public action: EventEmitter<unknown> = new EventEmitter();

    public EWidgetState = EWidgetState;

    public hasPlaceholder = true;
    public icon = `${this._assetsPath}/icons/no_data.svg`;

    constructor(@Inject(COMPONENTS_ASSETS_PATH) private readonly _assetsPath: string) {}

    public ngAfterContentInit(): void {
        this.hasPlaceholder = this.placeholder !== undefined;
    }
}
