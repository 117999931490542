import { Directive, ElementRef, inject, OnInit } from '@angular/core';

@Directive({
    selector: '[erPreventPaste]',
    standalone: true
})
export class ErPreventPasteDirective implements OnInit {
    private readonly _element = inject(ElementRef);
    public ngOnInit(): void {
        this._element.nativeElement.onpaste = (event: Event): void => event.preventDefault();
    }
}
