import { inject } from '@angular/core';
import { EPlanCode } from '@edenred/core/modules/authentication-management/infrastructure/repositories/enums';

import { EasyfuelOrchestrator, FalconOrchestrator, OrchestratorAbstract } from '../orchestrators';

export const orchestrationFactory = (): OrchestratorAbstract => {
    const params = new URLSearchParams(window.location.search);
    const planCode = params.get('plan_code') ?? EPlanCode.FALCON;
    switch (planCode.toUpperCase() as EPlanCode) {
        case EPlanCode.EASYFUEL:
            return inject(EasyfuelOrchestrator);
        default:
            return inject(FalconOrchestrator);
    }
};
