import { Component, ElementRef, inject, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';

export type TagType = 'none' | 'neutral' | 'info' | 'success' | 'warning' | 'error';

@Component({
    selector: 'er-tag',
    template: '<ng-content></ng-content>',
    styleUrl: './tag.component.css',
    standalone: true
})
export class ErTagComponent implements OnChanges {
    @Input({ required: true }) public type: TagType;

    private readonly _renderer = inject(Renderer2);
    private readonly _element = inject(ElementRef);

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.type?.previousValue) {
            this._renderer.removeClass(this._element.nativeElement, changes.type?.previousValue);
        }

        if (changes.type?.currentValue) {
            this._renderer.addClass(this._element.nativeElement, changes.type.currentValue);
        }
    }
}
