import { NgModule } from '@angular/core';

import { ErAddressPipe } from './address.pipe';
import { ErArrayIfNilPipe } from './array-if-nil.pipe';
import { ErConcatPipe } from './concat.pipe';
import { ErCurrencyPipe } from './currency.pipe';
import { ErDatePipe } from './date.pipe';
import { ErDecimalPipe } from './decimal.pipe';
import { ErHtmlTranslatePipe } from './html-translate.pipe';
import { ErUnitPipe } from './unit.pipe';

@NgModule({
    imports: [
        ErCurrencyPipe,
        ErArrayIfNilPipe,
        ErDatePipe,
        ErUnitPipe,
        ErConcatPipe,
        ErDecimalPipe,
        ErAddressPipe,
        ErHtmlTranslatePipe
    ],
    exports: [
        ErDatePipe,
        ErConcatPipe,
        ErCurrencyPipe,
        ErDecimalPipe,
        ErUnitPipe,
        ErAddressPipe,
        ErHtmlTranslatePipe,
        ErArrayIfNilPipe,
        ErUnitPipe,
        ErHtmlTranslatePipe
    ]
})
export class ErPipesModule {}
