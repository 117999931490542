<mat-form-field
    class="flex flex-row-reverse items-center justify-center rounded border border-grey-30 bg-white"
    erEnterToClick
    tabindex="0"
    [ngClass]="{
        'border-grey-30 hover:border-grey-30 focus:border-grey-30 ': opened,
        'bg-grey-20 text-grey-50': disabled
    }"
    (click)="toggle()"
>
    <mat-label class="truncate font-medium">
        <span class="text-grey-70">{{ label }} {{ format(value) }}</span>
    </mat-label>
    <mat-icon class="ml-auto h-7 w-7" [svgIcon]="opened ? 'expand-less-rg-24' : 'expand-more-rg-24'"></mat-icon>
    <input
        class="!hidden"
        matInput
        [disabled]="disabled"
        [matDatepicker]="datepicker"
        [max]="max"
        [min]="min"
        [value]="selectedDate"
        (dateChange)="onDateChange($event.value)"
    />
    <mat-datepicker
        #datepicker
        [calendarHeaderComponent]="header"
        [opened]="opened"
        (closed)="toggle()"
    ></mat-datepicker>
</mat-form-field>
