/* istanbul ignore file */
import { Observable, of } from 'rxjs';

import { TranslateServiceAbstract } from '../abstracts/translate.service.abstract';

export class TestingTranslateService extends TranslateServiceAbstract {
    protected _use(): void {
        // This method is required by TranslateServiceAbstract class.
    }

    public get$(key: string): Observable<string> {
        return of(key);
    }

    public stream$(key: string): Observable<string> {
        return of(key);
    }

    public setDefaultLang(): void {
        // This method is required by TranslateServiceAbstract class.
    }

    public instant(key: string): string {
        return key;
    }
}
