import { Component, inject, Input } from '@angular/core';
import { BreakpointService } from '@edenred/utilities';

import { EIconStyle } from '../../../../enums';

@Component({
    selector: 'er-table-button-action',
    templateUrl: './table-button-action.component.html',
    styleUrls: ['./table-button-action.component.css']
})
export class ErTableButtonActionComponent {
    @Input() public icon: string;
    @Input() public badge: number;

    public EIconStyle = EIconStyle;

    public breakpointService = inject(BreakpointService);
}
