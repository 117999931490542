<div *ngIf="(mode$ | async) === displayMode.View; then viewMode; else editMode" class="flex flex-col items-center"></div>
<ng-template #viewMode>
    <span *ngIf="control.value" data-test="er-editable-input-view-mode-value">{{ control.value }}</span>
    <span
        *ngIf="!control.value"
        class="text-xs italic leading-6 text-grey-30 md:text-base"
        data-test="er-editable-input-view-mode-placeholder"
    >
        {{ placeholder }}
    </span>
    <button
        class="ml-2 min-h-0 cursor-pointer !p-0 disabled:pointer-events-none"
        erEnterToClick
        erGhost
        tabindex="0"
        (click)="onEdit()"
        data-test="er-editable-input-view-mode-edit-button"
    >
        <mat-icon class="!icon-sm" svgIcon="edit-rg-20"></mat-icon>
    </button>
</ng-template>
<ng-template #editMode>
    <div class="flex w-full flex-row items-center justify-self-center">
        <input class="my-[2px]" autofocus placeholder="{{ placeholder }}" type="text" [formControl]="control" />
        <button
            class="ml-2 min-h-0 cursor-pointer !p-0 disabled:pointer-events-none"
            erEnterToClick
            erGhost
            tabindex="0"
            (click)="onSave()"
        >
            <mat-icon class="shrink-0 !icon-sm" svgIcon="done-rg-20"></mat-icon>
        </button>
    </div>
</ng-template>
