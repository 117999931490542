import { Directive, inject, Input, OnInit, Renderer2, TemplateRef, ViewContainerRef } from '@angular/core';

import { ErLoaderComponent } from '../components/loader/loader.component';

@Directive({
    selector: '[erActionLoader]',
    standalone: true
})
export class ErActionLoaderDirective implements OnInit {
    private _hostClasses: string;

    private readonly _templateRef = inject(TemplateRef<unknown>);
    private readonly _viewContainer = inject(ViewContainerRef);
    private readonly _renderer = inject(Renderer2);

    public ngOnInit(): void {
        this._hostClasses = this._templateRef.createEmbeddedView(null).rootNodes[0].className;
    }

    @Input() public set erActionLoader(condition: boolean) {
        this._viewContainer.clear();

        if (condition) {
            const cmpRef = this._viewContainer.createComponent(ErLoaderComponent);
            cmpRef.instance.size = 'small';
            cmpRef.instance.theme = 'placeholder';
            if (this._hostClasses) {
                this._hostClasses.split(' ').forEach((cssClass: string) => {
                    this._renderer.addClass(cmpRef.location.nativeElement, cssClass);
                });
            }
        } else {
            this._viewContainer.createEmbeddedView(this._templateRef);
        }
    }
}
