<header class="flex w-full flex-wrap items-center">
    <h5 class="!m-0 max-w-[18rem] grow break-words !pb-0 lg:max-w-full" mat-dialog-title>
        <ng-content select="[simple-modal-title]"></ng-content>
    </h5>
    <mat-icon
        *ngIf="hasCloseIcon"
        class="ml-auto mr-4 shrink-0 cursor-pointer !icon-sm"
        svgIcon="close-rg-24"
        (click)="cancel.emit(null)"
        data-test="modal-close"
    ></mat-icon>
</header>
<mat-dialog-content>
    <ng-content select="[simple-modal-content]"></ng-content>
</mat-dialog-content>
<mat-dialog-content *ngIf="showWarning" class="warning">
    <ng-content select="[simple-modal-warning-content]"></ng-content>
</mat-dialog-content>
<mat-dialog-actions
    *ngIf="hasCancelButton || hasConfirmButton"
    class="-mx-5 flex flex-col-reverse !justify-end !border-t !border-t-grey-20 bg-grey-10 !p-5 md:flex-row md:gap-4 md:p-5"
>
    <button
        *ngIf="hasCancelButton"
        erEnterToClick
        [attr.data-test]="cancelDataTest"
        [disabled]="cancelDisabled"
        [ngClass]="cancelClass"
        (click)="cancel.emit(null)"
    >
        {{ cancelLabel }}
    </button>

    <er-button-loading
        *ngIf="hasConfirmButton"
        class="mb-3 w-full md:mb-0 md:w-auto"
        erEnterToClick
        cdkFocusRegionstart
        [buttonClass]="confirmClass"
        [buttonDataTest]="confirmDataTest"
        [isDisabled]="confirmDisabled || confirmLoading"
        [isLoading]="confirmLoading"
        [ngClass]="confirmClass"
        (buttonClick)="confirm.emit(null)"
    >
        {{ confirmLabel }}
    </er-button-loading>
</mat-dialog-actions>
