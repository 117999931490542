export enum EPinCodeState {
    KEEP_SAME_PIN = 'KEEP_SAME_PIN',
    GENERATE_PIN = 'GENERATE_PIN',
    CUSTOM_PIN = 'CUSTOM_PIN'
}

export type PinCode =
    | {
          state: EPinCodeState.KEEP_SAME_PIN;
      }
    | {
          state: EPinCodeState.GENERATE_PIN;
      }
    | {
          state: EPinCodeState.CUSTOM_PIN;
          code: string;
      };
