import { EMessageType } from '../enums/message-type.enum';

export interface IMessageType {
    icon: string;
    background: string;
    iconBackground: string;
    iconColor: string;
}

export const IMessageConfig: { [key: string]: IMessageType } = {
    [EMessageType.SUCCESS]: {
        icon: 'check-circle-f-rg-24',
        background: 'bg-success-20',
        iconBackground: '',
        iconColor: 'text-success-100'
    },
    [EMessageType.WARNING]: {
        icon: 'error-f-rg-24',
        background: 'bg-warning-20',
        iconBackground: '',
        iconColor: 'text-warning-100'
    },
    [EMessageType.INFO]: {
        icon: 'info-f-rg-24',
        background: 'bg-info-20',
        iconBackground: '',
        iconColor: 'text-info-100'
    },
    [EMessageType.ERROR]: {
        icon: 'warning-f-rg-24',
        background: 'bg-error-20',
        iconBackground: '',
        iconColor: 'text-error-100'
    }
};
