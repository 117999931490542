import { inject, Injectable } from '@angular/core';

import { FEATURES } from '../tokens/features.token';

@Injectable({
    providedIn: 'root'
})
export class FeatureFlagService {
    private readonly _features: Record<string, boolean> = inject(FEATURES);

    public isEnabled(feature: string): boolean {
        return this._features[feature] ?? true;
    }
}
