/* eslint-disable @typescript-eslint/no-explicit-any */
import { isNil } from 'ramda';
import { catchError, EMPTY, Observable, OperatorFunction, throwError } from 'rxjs';

/**
 * On error, if error matches, tap
 * @param matches Filter error by value
 * @returns value
 */
export const completeOnError =
    <T, R>(matches: T[], fn: (value: T) => R): OperatorFunction<any, any> =>
        (source$: Observable<unknown>): Observable<unknown> =>
            source$.pipe(
                catchError((value: T): Observable<R> => {
                    if ((!isNil(value) && isNil(matches)) || matches.includes(value)) {
                        fn(value);
                        return EMPTY;
                    }

                    return throwError(() => value);
                })
            );
