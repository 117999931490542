export const CARD_PRINTED_NAME_MAX_LENGTH = 19;
export const CARD_PRINTED_COMPANY_MAX_LENGTH = 26;

export function isCardPrintedNamePatternValid(input: string): boolean {
    const pattern = /^[A-Z0-9 ,.\-'+&/ÜÖÄ]+$/i;
    return pattern.test(input);
}

export function isCardPrintedNameLengthValid(input: string): boolean {
    return input?.length <= CARD_PRINTED_NAME_MAX_LENGTH;
}

export function isCardPrintedCompanyPatternValid(input: string): boolean {
    const pattern = /^[A-Z0-9 ,.\-'&/ÜÖÄ]+$/i;
    return pattern.test(input);
}

export function isCardPrintedCompanyLengthValid(input: string): boolean {
    return input?.length <= CARD_PRINTED_COMPANY_MAX_LENGTH;
}

export function isCardPrintedLicensePlatePatternValid(input: string): boolean {
    const pattern = /^[A-Z0-9 \-ÜÖÄ]+$/i;
    return pattern.test(input);
}
