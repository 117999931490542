/* eslint-disable @typescript-eslint/naming-convention */
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    forwardRef,
    Inject,
    Optional,
    ViewEncapsulation
} from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';
import { MatCalendar, MatCalendarHeader, MatDatepickerIntl } from '@angular/material/datepicker';

@Component({
    selector: 'er-date-picker-header',
    templateUrl: './date-picker-header.component.html',
    styleUrls: ['./date-picker-header.component.css'],
    exportAs: 'erDatepickerHeader',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErDatePickerHeaderComponent<D> extends MatCalendarHeader<D> {
    constructor(
        @Optional() private readonly dateAdapter: DateAdapter<D>,
        @Optional() @Inject(MAT_DATE_FORMATS) private readonly dateFormats: MatDateFormats,
        intl: MatDatepickerIntl,
        @Inject(forwardRef(/* istanbul ignore next */ () => MatCalendar)) calendar: MatCalendar<D>,
        changeDetectorRef: ChangeDetectorRef
    ) {
        super(intl, calendar, dateAdapter, dateFormats, changeDetectorRef);
    }

    public override get periodButtonText(): string {
        if (!this.calendar.activeDate) {
            return '';
        }

        if (this.calendar.currentView === 'month') {
            return this.dateAdapter.format(this.calendar.activeDate, this.dateFormats.display.monthYearLabel);
        }

        return this.dateAdapter.getYearName(this.calendar.activeDate);
    }
}
