import { Mapper } from '../../../../../../infrastructure/services/mapper.abstract.service';
import { IRetrieveUsernameIdPayload } from '../../../../domain/interfaces';
import { IRetrieveUsernameIdQuery } from '../../interfaces';

export class RetrieveUsernameIdQueryMapper extends Mapper<IRetrieveUsernameIdPayload, IRetrieveUsernameIdQuery> {
    public map(payload: IRetrieveUsernameIdPayload): IRetrieveUsernameIdQuery {
        return this.sanitize({
            username: payload.username
        });
    }
}
