<div class="desktop-filters flex items-center">
    <ng-content></ng-content>
</div>
<button
    class="ml-4 cursor-pointer font-sans text-base font-normal"
    erTertiary
    type="button"
    [disabled]="clearable"
    (click)="clear()"
    data-test="clear"
>
    {{ labels.clear }}
</button>
