import { NgClass, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { ErDirectivesModule } from '../../directives.module';

@Component({
    selector: 'er-link',
    templateUrl: './link.component.html',
    styleUrl: './link.component.css',
    imports: [ErDirectivesModule, MatIconModule, NgIf, NgClass],
    standalone: true
})
export class ErLinkComponent {
    @Input() public icon: string;
}
