import {
    IAuthenticationManagement,
    IAuthenticationManagementConfig
} from './infrastructure/config/authentication-management.interface';

export const AuthenticationManagementFactory = (
    authenticationManagementConfig: IAuthenticationManagementConfig
): IAuthenticationManagement => ({
    hosts: {
        account: authenticationManagementConfig.hosts.account as string,
        userManagement: authenticationManagementConfig.hosts.userManagement as string
    },
    endpoints: {
        activateAccount: authenticationManagementConfig.endpoints.activateAccount as string,
        forgetPassword: authenticationManagementConfig.endpoints.forgetPassword as string,
        resetPassword: authenticationManagementConfig.endpoints.resetPassword as string,
        retrieveUsernameId: authenticationManagementConfig.endpoints.retrieveUsernameId as string
    }
});
