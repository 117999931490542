import { formatCurrency, getCurrencySymbol } from '@angular/common';
import { inject, Pipe, PipeTransform } from '@angular/core';
import { LocaleServiceAbstract } from '@edenred-falcon/shared/angular/i18n';
import { map, Observable, of, switchMap } from 'rxjs';

@Pipe({ name: 'erCurrency', standalone: true })
export class ErCurrencyPipe implements PipeTransform {
    private readonly _defaultCurrencyCode = 'EUR';

    private readonly _localeService = inject(LocaleServiceAbstract);

    public transform(
        value: number | string | Observable<number | string>,
        currencyCode: string = this._defaultCurrencyCode,
        digitsInfo = '1.2-2'
    ): Observable<string | null> {
        const value$ = value instanceof Observable ? value : of(value);

        return this._localeService
            .getLocale$()
            .pipe(switchMap(locale => this.format(value$, currencyCode, digitsInfo, locale)));
    }

    public format(
        value$: Observable<number | string>,
        currencyCode: string,
        digitsInfo: string,
        locale: string
    ): Observable<string | null> {
        return value$.pipe(
            map(value =>
                formatCurrency(
                    value as number,
                    locale,
                    getCurrencySymbol(currencyCode, 'wide'),
                    currencyCode,
                    digitsInfo
                )
            )
        );
    }
}
