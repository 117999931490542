import { inject, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TranslateServiceAbstract } from '@edenred-falcon/shared/angular/i18n';
import { Observable, tap } from 'rxjs';

@Pipe({ name: 'erHtmlTranslate', standalone: true })
export class ErHtmlTranslatePipe implements PipeTransform {
    private readonly _translateService = inject(TranslateServiceAbstract);
    private readonly _sanitizer = inject(DomSanitizer);

    public transform(key: string, params?: { [key: string]: unknown }): Observable<SafeHtml> {
        return this._translateService
            .stream$(key, params)
            .pipe(tap((translation: string) => this._sanitizer.bypassSecurityTrustHtml(translation)));
    }
}
