import { inject, Injectable } from '@angular/core';

import { IMetadata } from '../../../../../domain/interfaces/interfaces';
import { Presenter } from '../../../../../domain/services/presenter/presenter.abstract';
import { IUser } from '../../../domain/interfaces';
import { IRetrieveUsernameIdResponse } from '../interfaces';
import { UserMapper } from '../mappers/entities/user.mapper';

@Injectable({
    providedIn: 'root'
})
export class RetrieveUsernameIdPresenter extends Presenter<IRetrieveUsernameIdResponse, IUser> {
    private readonly _mapper = inject(UserMapper);

    protected getData(response: IRetrieveUsernameIdResponse): IUser {
        return this._mapper.map(response.data);
    }

    public getMetadata(): IMetadata {
        return {} as IMetadata;
    }
}
