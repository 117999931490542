import { Component, Input } from '@angular/core';

@Component({
    selector: 'er-expandable-indicator-for',
    templateUrl: './expandable-indicator-for.component.html',
    styleUrls: ['./expandable-indicator-for.component.css']
})
export class ErExpandableIndicatorForComponent {
    @Input({ required: true }) public expanded: boolean;
}
