<div *ngIf="!!icon" class="relative mr-2 flex items-start md:w-6" [ngClass]="{ 'text-grey-30': greyed }">
    <mat-icon class="!icon-sm {{ iconColor }}" [svgIcon]="icon" data-test="icon"></mat-icon>
</div>
<div class="w-full">
    <p
        class="mt-0 overflow-hidden text-ellipsis whitespace-pre-wrap text-sm font-medium md:text-base"
        [ngClass]="{ 'text-grey-30': greyed }"
        data-test="er-multiline-title"
    >
        <ng-content select="[title]"></ng-content>
    </p>
    <p class="mt-1 overflow-hidden text-ellipsis whitespace-normal md:text-sm" data-test="er-multiline-content">
        <ng-content></ng-content>
    </p>
</div>
