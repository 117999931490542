import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
    selector: 'er-toggletip',
    templateUrl: './toggletip.component.html',
    styleUrl: './toggletip.component.css',
    imports: [MatButtonModule, MatTooltipModule, MatIconModule],
    standalone: true
})
export class ErToggletipComponent {
    @Input({ required: true }) public tipContent: string;
}
