import { ModuleWithProviders, NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { TranslateServiceAbstract } from '../../services/translate/abstracts/translate.service.abstract';
import { TestingTranslateService } from '../../services/translate/testing/translate.testing.service';

@NgModule()
export class TestingTranslateModule {
    public static forRoot(): ModuleWithProviders<TestingTranslateModule> {
        return {
            ngModule: TestingTranslateModule,
            providers: [
                {
                    provide: TranslateServiceAbstract,
                    useClass: TestingTranslateService
                },
                TranslateModule.forRoot().providers ?? []
            ]
        };
    }
}
