import { Observable } from 'rxjs';

export interface IMetadata {
    pageIndex: number;
    pageSize: number;
    total: number;
    totalCards?: number;
}

export interface IViewModel<T> {
    data?: T;
    metadata?: IMetadata;
}

export interface IPaginator {
    length?: number;
    pageIndex: number;
    pageSize: number;
    previousPageIndex?: number;
}

export type SortDirection = '' | 'asc' | 'desc';

export interface ISort {
    sortBy: string;
    sortDirection: SortDirection;
}

export interface IValueLabel<T = unknown> {
    value: T;
    label: string;
}

export interface IValueObservableLabel {
    value: unknown;
    label$: Observable<string>;
}

export interface ISliderConfig {
    min: string;
    max: string;
    step: number;
    unit: string;
}

export interface IAddress {
    addressLine1: string;
    addressLine2: string;
    addressLine3: string;
    city: string;
    postCode: string;
    country: string;
    countryCode?: string;
}

export interface IPhoneNumber {
    phoneNumber: string;
    prefix: string;
    country: string;
}
