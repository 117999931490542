import { Component, inject, ViewEncapsulation } from '@angular/core';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

@Component({
    selector: 'er-modal',
    templateUrl: './modal.component.html',
    styleUrl: './modal.component.css',
    encapsulation: ViewEncapsulation.None,
    imports: [MatDialogModule, MatIconModule],
    standalone: true
})
export class ErModalComponent {
    protected dialogRef = inject(MatDialogRef<ErModalComponent>);

    public close(data: unknown = null): void {
        this.dialogRef.close(data);
    }
}
