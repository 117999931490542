import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ETenant } from '@edenred/core/modules/authentication-management/domain/enums';

import { OrchestratorAbstract } from './orchestrator.abstract';

@Injectable({
    providedIn: 'root'
})
export class EasyfuelOrchestrator extends OrchestratorAbstract {
    private readonly _router = inject(Router);

    public getTenant(): ETenant {
        return ETenant.EASYFUEL;
    }

    public afterCreateAccount(): void {
        void this._router.navigate(['/tenants/easyfuel/download-app']);
    }

    public afterCreateAccountLinkExpired(): void {
        void this._router.navigate(['/tenants/easyfuel/check-your-inbox']);
    }

    public afterRequestPasswordLink(): void {
        void this._router.navigate(['/tenants/easyfuel/check-your-inbox']);
    }

    public afterResetPassword(): void {
        void this._router.navigate(['/tenants/easyfuel/password-modified']);
    }

    public afterResetPasswordLinkExpired(email: string): void {
        void this._router.navigate(['/password/reset/request-new-link'], {
            queryParams: {
                email
            }
        });
    }

    public getLegalSentenceKey(): string {
        return 'easyfuel.create_password.legal_sentence';
    }

    public getDataProtectionInformationUrlKey(): string {
        return 'easyfuel.create_password.legal_sentence.data_protection_information.link';
    }
}
