import { inject, Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { NavigationStart, Router } from '@angular/router';
import { BreakpointService } from '@edenred/utilities';
import { path } from 'ramda';
import { filter, tap } from 'rxjs';

import { EMessageType } from '../../../enums/message-type.enum';
import { ErAlertComponent } from '../alert.component';

@Injectable()
export class ErAlertService {
    private _snackBarRef: MatSnackBarRef<ErAlertComponent> | null = null;

    private readonly _matSnackBar = inject(MatSnackBar);
    private readonly _breakpointService = inject(BreakpointService);
    private readonly _router = inject(Router);

    constructor() {
        this._openOnNavigationChange();
    }

    private _openOnNavigationChange(): void {
        this._router.events
            .pipe(
                filter(event => event instanceof NavigationStart),
                tap(() => {
                    const currentNav = this._router.getCurrentNavigation();
                    if (path(['extras', 'state', 'message'], currentNav)) {
                        this._snackBarRef = this.open(
                            path(['extras', 'state', 'message'], currentNav) as string,
                            path(['extras', 'state', 'type'], currentNav) as EMessageType
                        );
                    } else if (this._snackBarRef) {
                        this._snackBarRef.dismiss();
                    }
                })
            )
            .subscribe();
    }

    public open(
        messageContent: string,
        messageType: EMessageType = EMessageType.SUCCESS
    ): MatSnackBarRef<ErAlertComponent> {
        this._snackBarRef = this._matSnackBar.openFromComponent(ErAlertComponent, {
            horizontalPosition: this._breakpointService.isDesktopScreen() ? 'right' : 'center',
            verticalPosition: 'top',
            duration: 6000,
            data: { content: messageContent, type: messageType },
            panelClass: '!shadow-none'
        });

        return this._snackBarRef;
    }
}
