import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, inject, Input, Renderer2 } from '@angular/core';

@Component({
    selector: 'er-cookie-consent',
    templateUrl: './cookie-consent.component.html',
    standalone: true
})
export class ErCookieConsentComponent implements AfterViewInit {
    @Input({ required: true }) public id: string;

    private readonly _renderer = inject(Renderer2);
    private readonly _document = inject(DOCUMENT);

    public ngAfterViewInit(): void {
        const s = this._renderer.createElement('script');
        this._renderer.setAttribute(s, 'type', 'text/javascript');
        this._renderer.setAttribute(s, 'src', 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js');
        this._renderer.setAttribute(s, 'data-domain-script', this.id);
        this._renderer.appendChild(this._document.body, s);
    }
}
