import { map, Observable } from 'rxjs';

import { mapOnError } from '../../../../domain/rxjs/operators/map-on-error.operator';
import { EUserErrors } from '../enums/exceptions';
import { AuthenticationManagementRepositoryAbstract } from '../repositories/authentication-management.repository.abstract';
import { AuthenticationManagementUseCaseAbstract } from './authentication-management.usecase.abstract';

export class AuthenticationManagementUseCase extends AuthenticationManagementUseCaseAbstract {
    constructor(private readonly _repository: AuthenticationManagementRepositoryAbstract) {
        super();
    }

    public isUsernameAvailable$(username: string): Observable<boolean> {
        return this._repository.retrieveUsernameId$({ username }).pipe(
            map(() => false),
            mapOnError([EUserErrors.USER_NOT_FOUND], () => true)
        );
    }
}
