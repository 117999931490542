import { Component, DestroyRef, ElementRef, EventEmitter, HostListener, inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { map } from 'rxjs';

import { OPTION_PARENT_COMPONENT, OptionParentComponent } from './../tokens/option-parent.interface';

@Component({
    selector: 'er-option',
    templateUrl: './option.component.html',
    styleUrls: ['./option.component.css']
})
export class ErOptionComponent<T> implements OnInit {
    @Input({ required: true }) public value: T;
    @Input() public labelType: 'html' | 'text' = 'html';

    @Output() public markForCheck: EventEmitter<null> = new EventEmitter();
    @ViewChild('label') public labelRef: ElementRef;

    public optionParentComponent: OptionParentComponent<unknown> = inject(OPTION_PARENT_COMPONENT);

    public selected: boolean;

    private readonly _destroyRef = inject(DestroyRef);

    public ngOnInit(): void {
        this.optionParentComponent.value$
            .pipe(
                takeUntilDestroyed(this._destroyRef),
                map(value => {
                    if (this.optionParentComponent.multiple && Array.isArray(value)) {
                        this.selected = value.includes(this.value);
                    } else {
                        this.selected = value === this.value;
                    }
                })
            )
            .subscribe();
    }

    public get label(): string {
        if (this.labelType === 'html') {
            return this.labelRef?.nativeElement.innerHTML;
        }

        return this.labelRef?.nativeElement.innerText;
    }

    @HostListener('click')
    public toggle(): void {
        if (this.optionParentComponent.multiple) {
            this._toggleMultiple();
        } else {
            this.optionParentComponent.writeValue(this.value);
            this.optionParentComponent.onChange(this.value);
            this.optionParentComponent.onTouched();
            this.optionParentComponent.selectionChange.emit(this.value);
        }
        this.markForCheck.emit(null);
    }

    private _toggleMultiple(): void {
        const values = (this.optionParentComponent.value$.getValue() as T[]) ?? [];

        if (this.selected) {
            const index = values.findIndex((value: T) => value === this.value);
            values.splice(index, 1);
        } else {
            values.push(this.value);
        }

        this.optionParentComponent.writeValue(values);
        this.optionParentComponent.onTouched();
        this.optionParentComponent.onChange(values);
        this.optionParentComponent.selectionChange.emit(values);
    }
}
