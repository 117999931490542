import { inject, Injectable } from '@angular/core';
import { TranslateService as NgxTranslateService } from '@ngx-translate/core';
import { distinctUntilChanged, Observable } from 'rxjs';

import { LocaleServiceAbstract } from '../../../services/locale/abstracts/locale.service.abstract';
import { TranslateServiceAbstract } from '../../../services/translate/abstracts/translate.service.abstract';

@Injectable({
    providedIn: 'root'
})
export class TranslateService extends TranslateServiceAbstract {
    // eslint-disable-next-line @typescript-eslint/prefer-readonly
    private _translateService = inject(NgxTranslateService);
    private readonly _localeService = inject(LocaleServiceAbstract);

    constructor() {
        super();

        this._localeService
            .getLang$()
            .pipe(distinctUntilChanged())
            .subscribe(lang => {
                if (lang) {
                    this._use(lang);
                }
            });

        this.setDefaultLang(this._localeService.getLang());
    }

    protected _use(lang: string): void {
        this._translateService.use(lang);
    }

    public get$(key: string | string[], interpolateParams?: { [key: string]: string }): Observable<string> {
        return this._translateService.get(key, interpolateParams);
    }

    public stream$(key: string | string[], interpolateParams?: { [key: string]: string }): Observable<string> {
        return this._translateService.stream(key, interpolateParams);
    }

    public instant(key: string | string[], interpolateParams?: object): string {
        return this._translateService.instant(key, interpolateParams);
    }

    public setDefaultLang(key?: string): void {
        if (key) {
            this._translateService.setDefaultLang(key);
        }
    }
}
