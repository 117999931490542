import { AsyncPipe, NgClass, NgForOf, NgIf } from '@angular/common';
import { AfterViewInit, Component, forwardRef, inject, Injector, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import {
    ControlValueAccessor,
    FormControl,
    FormControlDirective,
    FormControlName,
    FormGroupDirective,
    FormsModule,
    NG_VALUE_ACCESSOR,
    NgControl,
    ReactiveFormsModule
} from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatSlider, MatSliderModule, MatSliderThumb } from '@angular/material/slider';
import { ErCurrencyPipe, ErPipesModule } from '@edenred/utilities';
import { LocaleServiceAbstract, TranslateModule } from '@edenred-falcon/shared/angular/i18n';

import { ErSmallDirective } from '../../directives/small.directive';
import { ENumberFormat } from '../../enums';
import { ErButtonIconComponent } from '../button-icon/button-icon.component';

@Component({
    selector: 'er-slider-row',
    templateUrl: './slider-row.component.html',
    styleUrl: './slider-row.component.css',
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        AsyncPipe,
        ErButtonIconComponent,
        ErPipesModule,
        ErSmallDirective,
        FormsModule,
        MatIconModule,
        MatSliderModule,
        NgClass,
        NgIf,
        NgForOf,
        ReactiveFormsModule,
        TranslateModule
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => ErSliderRowComponent)
        },
        {
            provide: ErCurrencyPipe,
            deps: [LocaleServiceAbstract]
        }
    ]
})
export class ErSliderRowComponent implements OnInit, AfterViewInit, ControlValueAccessor {
    @ViewChild(MatSlider) private readonly _slider: MatSlider;
    @ViewChild(MatSliderThumb) private readonly _sliderThumb: MatSliderThumb;

    @Input({ required: true }) public minValue: number;
    @Input({ required: true }) public maxValue: number;
    @Input() public minValueFormat: string = ENumberFormat.NO_DECIMALS;
    @Input() public maxValueFormat: string = ENumberFormat.NO_DECIMALS;
    @Input() public maxSelectedValue: number;

    @Input() public label: string;
    @Input({ required: true }) public step: number;
    @Input({ required: true }) public unit: string;
    @Input() public isLabelFormatted = true;
    @Input() public disabled = false;
    @Input() public hint: string;

    public NUMBER_FORMAT = ENumberFormat;

    public sliderMask: number[];
    public value: number;
    public touched = false;

    private readonly _currencyPipe: ErCurrencyPipe = inject(ErCurrencyPipe);

    private _formattedLabel: string;
    private _formControl: FormControl<number> = new FormControl();

    constructor(private readonly _injector: Injector) {}

    public ngOnInit(): void {
        const length = (this.maxValue - this.minValue) / this.step;
        this.sliderMask = new Array(length + 1).fill(null).map((_value, index) => index * this.step + this.minValue);
    }

    public ngAfterViewInit(): void {
        const ngControl = this._injector.get(NgControl, null);

        if (ngControl) {
            if (ngControl instanceof FormControlName) {
                this._formControl = this._injector.get(FormGroupDirective).getControl(ngControl);
            } else {
                this._formControl = (ngControl as FormControlDirective)?.form;
            }
        }
    }

    public writeValue(value: number): void {
        this.value = value;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public onChange = (_value: unknown): void => undefined;

    public registerOnChange(onChange: (_value: unknown) => unknown): void {
        this.onChange = onChange;
    }

    public onTouched = (): void => undefined;

    public registerOnTouched(onTouched: () => unknown): void {
        this.onTouched = onTouched;
    }

    public setDisabledState(disabled: boolean): void {
        this.disabled = disabled;
    }

    public onValueChange(value: number): void {
        this.value = value > this._max ? this._max : value;

        this._slider._input.value = this.value;
        this._formControl.setValue(this.value);
        this._sliderThumb._updateThumbUIByValue();
        this.onChange(this.value);
    }

    private get _max(): number {
        return this.maxSelectedValue ?? this.maxValue;
    }

    public getLabel = (value: number): string => value.toString();

    public getFormattedLabel = (value: number): string => {
        this._currencyPipe.transform(value, this.unit, ENumberFormat.NO_DECIMALS).subscribe(newValue => {
            this._formattedLabel = newValue ?? '';
        });
        return this._formattedLabel;
    };
}
