import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { LanguageHelper } from '../../helpers/language.helper';
import { SUPPORTED_LOCALES } from '../../tokens/locale';
import { StoreServiceAbstract } from '../store/abstracts/store.abstract.service';
import { LocaleServiceAbstract } from './abstracts/locale.service.abstract';

const STORE_LOCAL_KEY = 'locale';

@Injectable({
    providedIn: 'root'
})
export class LocaleService implements LocaleServiceAbstract {
    private readonly _supportedLocales = inject(SUPPORTED_LOCALES);
    private readonly _store = inject(StoreServiceAbstract);
    private readonly _locale$: BehaviorSubject<string> = new BehaviorSubject(this._currentLocale);

    public getLocale$(): Observable<string> {
        return this._locale$.asObservable();
    }

    public getLocale(): string {
        return this._locale$.getValue();
    }

    public getLang$(): Observable<string> {
        return this._locale$.asObservable().pipe(map(locale => LanguageHelper.getLangOfLocale(locale)));
    }

    public getLang(): string {
        return LanguageHelper.getLangOfLocale(this._locale$.getValue());
    }

    public getSupportedLocales(): string[] {
        return this._supportedLocales;
    }

    public setLocale(locale: string): void {
        const validLocale = this._supportedLocales.find(e => e === locale) ?? this._supportedLocales[0];
        document.querySelector('html')?.setAttribute('lang', validLocale);
        this._store.set(STORE_LOCAL_KEY, validLocale);
        this._locale$.next(validLocale);
    }

    private get _currentLocale(): string {
        let currentLocale = this._store.get(STORE_LOCAL_KEY) ?? window.navigator.language;

        if (!this._supportedLocales.includes(currentLocale)) {
            [currentLocale] = this._supportedLocales;
        }

        return currentLocale;
    }
}
