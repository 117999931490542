/* istanbul ignore file */

import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';

import { IViewModel } from '../../../../domain/interfaces';
import { IRetrieveUsernameIdPayload, IUser } from '../interfaces';
import { AuthenticationManagementRepositoryAbstract } from './authentication-management.repository.abstract';

export class MockAuthenticationManagementRepository extends AuthenticationManagementRepositoryAbstract {
    public forgetPassword$(): Observable<null> {
        return of(null).pipe(delay(1));
    }

    public resetPassword$(): Observable<IViewModel<boolean>> {
        return of({ data: true }).pipe(delay(1));
    }

    public createPassword$(): Observable<null> {
        return of(null).pipe(delay(1));
    }

    public retrieveUsernameId$(payload: IRetrieveUsernameIdPayload): Observable<IViewModel<IUser>> {
        return of({ data: { id: '819ac404-9c40-4209-98ed-af749ed35986', username: payload.username } }).pipe(delay(1));
    }
}
