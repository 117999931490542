import { Mapper } from '../../../../../../infrastructure/services/mapper.abstract.service';
import { ETenant } from '../../../../domain/enums/tenants';
import { IActivateAccountPayload } from '../../../../domain/interfaces';
import { IActivateAccountBody } from '../../interfaces';

export class ActivateAccountBodyMapper extends Mapper<IActivateAccountPayload, IActivateAccountBody> {
    public map(payload: IActivateAccountPayload): IActivateAccountBody {
        const body: IActivateAccountBody = {
            username: payload.username,
            new_password: payload.password,
            code: payload.code
        };

        if (payload.tenant === ETenant.EASYFUEL) {
            body.terms_of_usage = {
                accepted: true,
                version: payload?.termsOfUse?.version
            };
        }

        return this.sanitize(body);
    }
}
