import { IMetadata, IViewModel } from '../../interfaces';

export abstract class Presenter<T, U> {
    public getViewModel(response: T): IViewModel<U> {
        return {
            data: this.getData(response),
            metadata: this.getMetadata(response) as IMetadata
        };
    }

    protected abstract getMetadata(input: T): IMetadata | null;
    protected abstract getData(input: T): U;
}
