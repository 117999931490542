import { Pipe, PipeTransform } from '@angular/core';
import { IAddress } from '@edenred/core';

const ADDRESS_FORMATS_SPLIT = /(:?s|S|t|c|z|C|,)([\s\S]*)/;

enum AddressParts {
    s = 'addressLine1', // street
    S = 'addressLine2', // secondary address
    t = 'addressLine3', // ternary address
    c = 'city', // city
    z = 'postCode', // zip or post code
    C = 'country' // country
}

@Pipe({
    name: 'erAddress',
    standalone: true
})
export class ErAddressPipe implements PipeTransform {
    public transform(address: IAddress, format: string): string {
        const parts = this._getFormatParts(format);
        let formattedAddress = '';

        parts.forEach(part => {
            const addressPart = this._getAddressPart(address, part);

            if (addressPart) {
                formattedAddress += ` ${addressPart}`;
            }
        });

        return formattedAddress.replace(' ,', ',').replace(/^[,\s]*|[,\s]*$/g, '');
    }

    private _getFormatParts(format: string): string[] {
        let addressFormat = format;
        let parts: string[] = [];
        let match;

        while (addressFormat) {
            match = ADDRESS_FORMATS_SPLIT.exec(addressFormat);
            if (match) {
                parts = parts.concat(match.slice(1));
                const part = parts.pop();
                if (!part) {
                    break;
                }
                addressFormat = part;
            } else {
                parts.push(addressFormat);
                break;
            }
        }

        return parts;
    }

    private _getAddressPart(address: IAddress, part: string): string {
        const addressPart = AddressParts[part as keyof typeof AddressParts];
        const addressPartString = addressPart ? address[addressPart] : part;

        return addressPartString ?? '';
    }
}
