import { Injectable } from '@angular/core';
import { Mapper } from '@edenred/core/infrastructure';

import { IPointOfInterest } from '../interfaces';

@Injectable({
    providedIn: 'root'
})
export class PointOfInterestMapper extends Mapper<google.maps.Marker, IPointOfInterest> {
    public map(marker: google.maps.Marker): IPointOfInterest {
        const position = marker.getPosition() as google.maps.LatLng;

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return this.sanitize({
            icon: null,
            position: {
                latitude: position.lat(),
                longitude: position.lng()
            }
        });
    }
}
