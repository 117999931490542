import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import * as Sentry from '@sentry/angular-ivy';

import { appConfig } from './app/app.config';
import { environment } from './environments/environment';
import { LayoutComponent } from './modules/app/infrastructure/components/layout/layout.component';

Sentry.init({
    dsn: environment.errorReporting.sentry.dsn,
    environment: environment.errorReporting.sentry.environment,
    tracesSampleRate: 1.0,
    attachStacktrace: true,
    autoSessionTracking: true,
    enabled: environment.errorReporting.sentry.enabled
});

if (environment.production) {
    enableProdMode();
}

void bootstrapApplication(LayoutComponent, appConfig);
