import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    template: ''
})
export abstract class ErModal<T, R = unknown> {
    protected abstract _dialogRef: MatDialogRef<T>;

    public cancel(): void {
        this.closeModal(null as R);
    }

    public abstract confirm(): void;

    protected closeModal(data: R): void {
        this._dialogRef.close(data);
    }
}
