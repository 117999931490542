import { inject, Injectable } from '@angular/core';
import { IViewModel } from '@edenred/core';
import { ITermsOfUse } from '@edenred/core/modules/authentication-management/domain/interfaces';
import { TranslateService } from '@ngx-translate/core';
import { map, Observable } from 'rxjs';

import { IGetTermsOfUsePayload } from '../../domain/interfaces';
import { TermsRepositoryAbstract } from '../../domain/repositories/terms.repository.abstract';

@Injectable({
    providedIn: 'root'
})
export class FalconTermsRepository extends TermsRepositoryAbstract {
    private readonly _translateService = inject(TranslateService);

    public get$(payload: IGetTermsOfUsePayload): Observable<IViewModel<ITermsOfUse>> {
        return this._translateService.get('create_password.legal_sentence.terms_of_use.link').pipe(
            map((url: string) => ({
                data: {
                    countryCode: payload.countryCode as string,
                    version: 'latest',
                    url
                }
            }))
        );
    }
}
