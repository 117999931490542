import { Directive, ElementRef, inject, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Subscription } from 'rxjs';

import { ErExpandableDirective } from './expandable.directive';

@Directive({
    selector: '[erExpandableRow]'
})
export class ErExpandableRowDirective implements OnInit, OnDestroy {
    @Input() public erExpandableRow: unknown;

    private _subscription: Subscription;

    private readonly _expandable = inject(ErExpandableDirective);
    private readonly _elementRef = inject(ElementRef);
    private readonly _renderer = inject(Renderer2);

    public ngOnInit(): void {
        this._subscription = this._expandable.getRow$().subscribe((row: unknown) => {
            if (this.erExpandableRow === row) {
                this._renderer.removeStyle(this._elementRef.nativeElement, 'display');
                this._renderer.addClass(this._elementRef.nativeElement, 'expanded');
            } else {
                this._renderer.removeClass(this._elementRef.nativeElement, 'expanded');
                this._renderer.setStyle(this._elementRef.nativeElement, 'display', 'none');
            }
        });
    }

    public ngOnDestroy(): void {
        this._subscription.unsubscribe();
    }
}
