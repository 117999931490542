import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgIf } from '@angular/common';
import { Component, inject, InjectionToken, OnInit } from '@angular/core';
import {
    ControlContainer,
    ControlValueAccessor,
    FormControl,
    FormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ReactiveFormsModule
} from '@angular/forms';
import { IPhoneNumber, PhoneNumberService, PhoneNumberServiceAbstract } from '@edenred/core';
import { isVoid, KeyboardHelper } from '@edenred/utilities';

import { ErErrorComponent } from '../error/error.component';
import { ErLabelComponent } from '../label/label.component';
import { ErSelectModule } from '../select';
import COUNTRIES_LIST from './countries-list.data';
import { PhoneNumberForm } from './form/phone-number.form';
import { IPhoneData, IPhoneNumberForm } from './interfaces';

export const COUNTRIES_PHONE_OPTIONS = new InjectionToken<string[]>('COUNTRIES_PHONE_OPTION');
export const PHONE_DEFAULT_COUNTRY = new InjectionToken<string>('PHONE_DEFAULT_COUNTRY');

@Component({
    selector: 'er-phone-number',
    templateUrl: './phone-number.component.html',
    styleUrl: './phone-number.component.css',
    imports: [ErLabelComponent, ErSelectModule, ErErrorComponent, NgIf, ReactiveFormsModule, ScrollingModule],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: ErPhoneNumberComponent,
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: ErPhoneNumberComponent,
            multi: true
        },
        {
            provide: COUNTRIES_PHONE_OPTIONS,
            useValue: [
                'FR',
                'DE',
                'GB',
                'IT',
                'BE',
                'CZ',
                'DK',
                'GR',
                'IS',
                'IE',
                'LV',
                'LI',
                'LT',
                'ME',
                'NL',
                'PT',
                'RO',
                'RS',
                'ES',
                'CH',
                'UA'
            ]
        },
        {
            provide: PHONE_DEFAULT_COUNTRY,
            useValue: 'DE'
        },
        {
            provide: PhoneNumberServiceAbstract,
            useClass: PhoneNumberService
        }
    ],
    standalone: true
})
export class ErPhoneNumberComponent implements OnInit, ControlValueAccessor {
    public countriesList: IPhoneData[];
    public placeholder = '';
    public selectedOption: IPhoneData;
    public form: FormGroup<IPhoneNumberForm> = PhoneNumberForm.create();

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public onChange: (value: unknown) => unknown = () => {};
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public onTouched: () => unknown = () => {};

    private readonly _controlContainer = inject(ControlContainer);
    private readonly _phoneNumberService = inject(PhoneNumberServiceAbstract);
    private readonly _countriesPhoneOptions: string[] = inject(COUNTRIES_PHONE_OPTIONS);
    private readonly _defaultCountry: string = inject(PHONE_DEFAULT_COUNTRY);

    public ngOnInit(): void {
        this.countriesList = this._getCountriesList();
    }

    public onPhoneNumberChange(): void {
        this.onTouched();
        this._emitValue();
    }

    private _emitValue(): void {
        this.onChange(this.form.controls.phoneNumber.value?.length ? this.form.getRawValue() : null);
    }

    public onPrefixChange(prefix: string): void {
        this.selectedOption = this.countriesList.find(country => country.prefix === prefix) as IPhoneData;
        this.placeholder = this._getPlaceholder(this.selectedOption.country);
        this.form.setValue({
            phoneNumber: '',
            prefix,
            country: this.selectedOption.country
        });
        this._emitValue();
        this.onTouched();

        this._controlContainer.control?.markAsUntouched();
    }

    public onlyPositiveNumber(event: KeyboardEvent): boolean {
        return KeyboardHelper.onlyPositiveNumber(event);
    }

    public writeValue(phoneControl: IPhoneNumber): void {
        if (phoneControl) {
            if (this._phoneNumberService.isValidNumberForRegion(phoneControl?.phoneNumber, phoneControl?.country)) {
                this.selectedOption = this.countriesList.find(country => country.prefix === phoneControl?.prefix) as IPhoneData;

                this.form.patchValue({
                    phoneNumber: phoneControl?.phoneNumber,
                    prefix: this.selectedOption?.prefix,
                    country: this.selectedOption?.country
                });
            } else {
                this.selectedOption = this.countriesList.find(country => country.country === this._defaultCountry) as IPhoneData;

                this.form.patchValue({
                    phoneNumber: phoneControl?.phoneNumber,
                    prefix: this.selectedOption?.prefix,
                    country: this.selectedOption?.country
                });
            }
        }

        this.placeholder = this._getPlaceholder(this.form.controls?.country.value);
    }

    public registerOnChange(fn: () => unknown): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: () => unknown): void {
        this.onTouched = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.form.disable();
        } else {
            this.form.enable();
        }
    }

    public validate(control: FormControl): null | { invalidPhoneNumber: boolean } {
        const phoneControl: IPhoneNumber = control.value;

        if (
            !phoneControl?.phoneNumber?.length ||
            this._phoneNumberService.isValidNumberForRegion(phoneControl.phoneNumber, phoneControl.country)
        ) {
            return null;
        }
        return {
            invalidPhoneNumber: true
        };
    }

    private _getPlaceholder(countryCode: string): string {
        const { prefix = '' } = this.countriesList.find(({ country }) => country === countryCode) ?? {};
        return this._phoneNumberService.getExampleNumber(countryCode, prefix);
    }

    private _getCountriesList(): IPhoneData[] {
        if (!Array.isArray(this._countriesPhoneOptions) || isVoid(this._countriesPhoneOptions)) {
            return COUNTRIES_LIST;
        }

        return COUNTRIES_LIST.filter(country => this._countriesPhoneOptions.includes(country.country));
    }
}
