import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';

import { ErDirectivesModule } from '../../directives.module';
import { ErDropdownComponent } from '../dropdown/dropdown.component';
import { ErOptionComponent } from './option/option.component';
import { ErSelectComponent } from './select.component';

@NgModule({
    declarations: [ErSelectComponent, ErOptionComponent],
    imports: [CommonModule, ErDropdownComponent, ReactiveFormsModule, MatIconModule, ErDirectivesModule],
    exports: [ErSelectComponent, ErOptionComponent]
})
export class ErSelectModule {}
