<mat-form-field
    class="flex flex-row-reverse items-center justify-center rounded border border-grey-30"
    erEnterToClick
    tabindex="0"
    [ngClass]="{
        'focus:shadow[0_0_4px_#D1D8E0] border-grey-30': opened,
        'bg-grey-10': disabled,
        'bg-white hover:border-grey-80': !disabled
    }"
    (click)="toggle()"
>
    <mat-label class="truncate font-medium" data-test="date-range-label">
        <span [class.text-grey-30]="disabled" [class.text-primary]="!disabled">
            {{ label }} {{ format(selectedDateRange) }}
        </span>
    </mat-label>
    <mat-icon
        class="ml-auto h-7 w-7"
        [class.text-grey-30]="disabled"
        [svgIcon]="opened ? 'expand-less-rg-20' : 'expand-more-rg-20'"
        data-test="date-range-icon"
    ></mat-icon>

    <mat-date-range-input class="!hidden" [max]="max" [min]="min" [rangePicker]="picker" data-test="date-range-input">
        <input matStartDate [value]="selectedDateRange.start" (dateChange)="onStartDateChange($any($event.value))" />
        <input matEndDate [value]="selectedDateRange.end" (dateChange)="onEndDateChange($any($event.value))" />
    </mat-date-range-input>

    <mat-date-range-picker
        #picker
        [calendarHeaderComponent]="header"
        [disabled]="disabled"
        [opened]="opened"
        (closed)="toggle()"
        data-test="date-range-picker"
    ></mat-date-range-picker>
</mat-form-field>
