import { Directive, inject, OnInit, ViewContainerRef } from '@angular/core';
import { ContainerService } from '@edenred/utilities';

/**
 * This directive is a helper for LayoutService. Set host as layout container.
 */
@Directive({
    selector: '[erLayoutContainer]',
    exportAs: 'erLayoutContainer',
    standalone: true
})
export class ErLayoutContainerDirective implements OnInit {
    private readonly _containerService = inject(ContainerService);
    private readonly _viewContainerRef = inject(ViewContainerRef);

    public ngOnInit(): void {
        this._containerService.container = this._viewContainerRef.element.nativeElement;
    }
}
