<button class="clear-button {{ buttonClass }}" type="button" (click)="slideToggle()" data-test="accordion-button">
    {{ buttonTitle }}
</button>

<div
    class="origin-top transition-transform ease-out"
    [ngClass]="{
        'h-0 scale-y-0': !expanded,
        'overflow-hidden': isToggling
    }"
    data-test="accordion-content"
>
    <ng-content></ng-content>
</div>
