import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@edenred/authentication/environments/environment';
import { ETenant } from '@edenred/core/modules/authentication-management/domain/enums';

import { OrchestratorAbstract } from './orchestrator.abstract';

const DELAY_BEFORE_REDIRECT = 3_000;

@Injectable({
    providedIn: 'root'
})
export class FalconOrchestrator extends OrchestratorAbstract {
    private readonly _router = inject(Router);

    public getTenant(): ETenant {
        return ETenant.FALCON;
    }

    public afterCreateAccount(): void {
        window.location.href = environment.extranet.host;
    }

    public afterCreateAccountLinkExpired(): void {
        window.location.href = environment.extranet.host;
    }

    public afterRequestPasswordLink(): void {
        void this._router.navigate(['/password/check-your-inbox']);
    }

    public afterResetPassword(): void {
        setTimeout(() => {
            /* istanbul ignore next */
            window.location.href = environment.extranet.host;
        }, DELAY_BEFORE_REDIRECT);
    }

    public afterResetPasswordLinkExpired(email: string): void {
        void this._router.navigate(['/password/reset/request-new-link'], {
            queryParams: {
                email
            }
        });
    }

    public getLegalSentenceKey(): string {
        return 'create_password.legal_sentence';
    }

    public getDataProtectionInformationUrlKey(): string {
        return 'create_password.legal_sentence.data_protection_information.link';
    }
}
