<er-dropdown [disabled]="disabled" data-test="select-dropdown">
    <ng-container dropdown-trigger>
        <span *ngIf="label?.length" class="mr-2 font-normal">{{ label }}</span>
        <ng-container *ngIf="multiple; then multipleSelect; else select"></ng-container>
    </ng-container>
    <ng-container dropdown-content>
        <div *ngIf="multiple && multipleToggle" class="flex min-w-[200px] justify-between px-4 pb-3 pt-5 text-xs font-normal">
            <div
                *ngIf="someOptionsSelected"
                class="cursor-pointer uppercase"
                erEnterToClick
                (click)="toggleOptions(false)"
                data-test="clear"
            >
                {{ multipleClearLabel }}
            </div>
            <div
                *ngIf="!everyOptionsSelected"
                class="ml-auto cursor-pointer uppercase"
                erEnterToClick
                (click)="toggleOptions(true)"
                data-test="select-all"
            >
                {{ multipleSelectAllLabel }}
            </div>
        </div>
        <div class="max-h-[360px] overflow-y-auto overflow-x-hidden">
            <ng-content></ng-content>
        </div>
    </ng-container>
</er-dropdown>

<ng-template #select>
    <div #labelTemplate>
        <ng-content select="[label]"></ng-content>
    </div>
    <ng-container *ngIf="!labelTemplate?.hasChildNodes()">
        <span class="font-normal" [ngClass]="{ 'text-grey-30': !labels.length }" data-test="select-value">
            {{ labels[0] || placeholder }}
        </span>
    </ng-container>
</ng-template>

<ng-template #multipleSelect>
    <div class="inline-block min-w-[1.5rem] rounded-full bg-red-100 px-2 text-center text-white" data-test="select-value">
        <ng-container *ngIf="selectedOptionsCount$() | async as selectedOptionsCount">
            <span *ngIf="selectedOptionsCount < queryListOptionComponent.length">{{ selectedOptionsCount }}</span>
            <span *ngIf="selectedOptionsCount === queryListOptionComponent.length" class="uppercase">
                {{ multipleAllLabel }}
            </span>
        </ng-container>
    </div>
</ng-template>
