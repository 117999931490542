import { Directive, ElementRef, HostListener, inject, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Subscription } from 'rxjs';

import { ErExpandableDirective } from './expandable.directive';

@Directive({
    selector: '[erExpandableTriggerFor]'
})
export class ErExpandableTriggerForDirective implements OnInit, OnDestroy {
    @Input() public erExpandableTriggerFor: unknown;

    private _subscription: Subscription;

    private readonly _expandable = inject(ErExpandableDirective);
    private readonly _elementRef = inject(ElementRef);
    private readonly _renderer = inject(Renderer2);

    @HostListener('click')
    public onClick(): void {
        this._expandable.setRow(this.erExpandableTriggerFor);
    }

    public ngOnInit(): void {
        this._subscription = this._expandable.getRow$().subscribe((row: unknown) => {
            if (this.erExpandableTriggerFor === row) {
                this._renderer.addClass(this._elementRef.nativeElement, 'expanded-trigger');
            } else {
                this._renderer.removeClass(this._elementRef.nativeElement, 'expanded-trigger');
            }
        });
    }

    public ngOnDestroy(): void {
        this._subscription.unsubscribe();
    }
}
