import { HttpEvent, HttpHandlerFn, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, tap } from 'rxjs';

import { IPageEntity } from '../../repositories/interfaces';

export const xPaginationInterceptor = (request: HttpRequest<any>, next: HttpHandlerFn): Observable<any> => {
    const xTotalCountHeader = 'X-Total-Count';

    return next(request).pipe(
        tap((response: HttpEvent<any>) => {
            if (response instanceof HttpResponse && response.headers.has(xTotalCountHeader)) {
                response.body.paginator = {
                    pageTotal: response.headers.get(xTotalCountHeader) as unknown as number,
                    pageNumber: request.params.get('page_index') as unknown as number,
                    pageItemLimit: request.params.get('page_size') as unknown as number,
                    pageTotalItems: response.body.data.length
                } as IPageEntity;
            }
        })
    );
};
