<div class="mt-3 flex">
    <er-select
        class="mr-1 w-[150px]"
        [formControl]="form.controls.prefix"
        (selectionChange)="onPrefixChange($event)"
        data-test="phone-number-country"
    >
        <cdk-virtual-scroll-viewport class="h-[200px] w-[150px]" appendOnly itemSize="20">
            <er-option *cdkVirtualFor="let country of countriesList" [attr.data-test]="country.prefix" [value]="country.prefix">
                <div class="flex items-center whitespace-nowrap">
                    <span
                        class="country-flag"
                        [style.background-position]="country.backgroundPosition + 'px 0px'"
                        [style.height]="country.height + 'px'"
                    ></span>
                    <span class="ml-1" [attr.data-test]="'phone-number-country-' + country.code">
                        {{ country.code }} {{ country.prefix }}
                    </span>
                </div>
            </er-option>
        </cdk-virtual-scroll-viewport>
        <ng-container label>
            <ng-container *ngIf="selectedOption">
                <span
                    class="country-flag"
                    [attr.data-test]="'phone-number-country-' + selectedOption.country"
                    [style.background-position]="selectedOption.backgroundPosition + 'px 0px'"
                    [style.height]="selectedOption.height + 'px'"
                ></span>
                <span data-test="phone-number-country-prefix">{{ selectedOption.prefix }}</span>
            </ng-container>
        </ng-container>
    </er-select>

    <input
        class="m-0"
        type="text"
        [formControl]="form.controls.phoneNumber"
        [placeholder]="placeholder"
        (input)="onPhoneNumberChange()"
        (keypress)="onlyPositiveNumber($event)"
        data-test="phone-number-id"
    />
</div>
