import { Component, Input } from '@angular/core';

@Component({
    selector: 'er-tile-block-item',
    templateUrl: './tile-block-item.component.html',
    styleUrls: ['./tile-block-item.component.css']
})
export class ErTileBlockItemComponent {
    @Input({ required: true }) public label: string;
}
