import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { FatalErrorException } from '../../../../../domain/exceptions/fatal-error/fatal-error.exception';

@Injectable({
    providedIn: 'root'
})
export class ForgotPasswordExceptionHandler {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public handle(_response: HttpErrorResponse): void {
        throw new FatalErrorException();
    }
}
