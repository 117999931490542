import { ErrorKey } from './error.config';

export const VALIDATION_ERROR = {
    ACCOUNT_NAME: { [ErrorKey.ACCOUNT_NAME]: true },
    ALPHA_NUMERIC: { [ErrorKey.ALPHA_NUMERIC]: true },
    ONLY_NUMERIC: { [ErrorKey.ONLY_NUMERIC]: true },
    PHONE_NUMBER: { [ErrorKey.INVALID_PHONE]: true },
    MIX_OF_LETTERS_AND_NUMBERS: { [ErrorKey.MIX_OF_LETTERS_AND_NUMBERS]: true },
    MIX_OF_LOWER_AND_UPPER_LETTERS: {
        [ErrorKey.MIX_OF_LOWER_AND_UPPER_LETTERS]: true
    },
    CONTAINS_SPECIAL_CHARACTERS: {
        [ErrorKey.CONTAINS_SPECIAL_CHARACTERS]: true
    },
    IBAN: { [ErrorKey.INVALID_IBAN_FORMAT]: true },
    BIC: { [ErrorKey.INVALID_BIC_FORMAT]: true },
    EMAIL: { [ErrorKey.INVALID_EMAIL]: true },
    DATE: { [ErrorKey.INVALID_DATE]: true },
    CARD_PIN: { [ErrorKey.CARD_PIN]: true },
    LATIN_1: { [ErrorKey.LATIN_1]: true },
    NON_GERMAN_PLATE_NUMBER: { [ErrorKey.NON_GERMAN_PLATE_NUMBER]: true },
    PLATE_NUMBER: { [ErrorKey.PLATE_NUMBER]: true },
    PLATE_EXISTING: { [ErrorKey.PLATE_EXISTING]: true },
    DRIVER_EXISTING: { [ErrorKey.DRIVER_EXISTING]: true },
    USER_EXISTING: { [ErrorKey.USER_EXISTING]: true },
    EMAIL_DIFFERENT: { [ErrorKey.EMAIL_DIFFERENT]: true },
    PASSWORD_DONT_MATCH: { [ErrorKey.PASSWORD_DONT_MATCH]: true },
    IN: { [ErrorKey.INVALID_VALUE]: true },
    NO_WHITESPACE: {
        [ErrorKey.NO_WHITESPACE]: true
    },
    FORMAT_ISSUE: {
        [ErrorKey.FORMAT_ISSUE]: true
    },
    REQUIRED: { [ErrorKey.REQUIRED]: true },
    CARD_PRINTED_COMPANY_NAME_FORMAT: {
        [ErrorKey.CARD_PRINTED_COMPANY_NAME_FORMAT]: true
    },
    CARD_PRINTED_COMPANY_NAME_LENGTH: {
        [ErrorKey.CARD_PRINTED_COMPANY_NAME_LENGTH]: true
    },
    CARD_PRINTED_DRIVER_NAME_FORMAT: {
        [ErrorKey.CARD_PRINTED_DRIVER_NAME_FORMAT]: true
    },
    CARD_PRINTED_DRIVER_NAME_LENGTH: {
        [ErrorKey.CARD_PRINTED_DRIVER_NAME_LENGTH]: true
    },
    CARD_PRINTED_LICENSE_PLATE: {
        [ErrorKey.CARD_PRINTED_LICENSE_PLATE]: true
    },
    INVALID_VAT: {
        [ErrorKey.INVALID_VAT]: true
    },
    INVALID_TAX_ID: {
        [ErrorKey.INVALID_TAX_ID]: true
    }
};
