import { Observable, Subject } from 'rxjs';

import { PermanentStore } from './abstracts/permanent-store.abstract';

export class LocalStorageStoreService implements PermanentStore<string> {
    private readonly _subject$: Subject<Storage> = new Subject();

    public set(key: string, value: string): this {
        localStorage.setItem(key, value);
        this._subject$.next(localStorage);

        return this;
    }

    public delete(key: string): boolean {
        localStorage.removeItem(key);
        this._subject$.next(localStorage);

        return true;
    }

    public has(key: string): boolean {
        return localStorage.getItem(key) !== null;
    }

    public get(key: string): string | null {
        return localStorage.getItem(key);
    }

    public clear(): void {
        localStorage.clear();
        this._subject$.next(localStorage);
    }

    public asObservable$(): Observable<Storage> {
        return this._subject$.asObservable();
    }
}
