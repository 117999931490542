import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@edenred-falcon/shared/angular/i18n';

import { ErTileComponent } from './tile.component';
import { ErTileBlockComponent } from './tile-block/tile-block.component';
import { ErTileBlockIconItemComponent } from './tile-block-icon-item/tile-block-icon-item.component';
import { ErTileBlockItemComponent } from './tile-block-item/tile-block-item.component';
import { ErTileDividerComponent } from './tile-divider/tile-divider.component';
import { ErTileFooterComponent } from './tile-footer/tile-footer.component';
import { ErTileHeaderComponent } from './tile-header/tile-header.component';
import { ErTileItemComponent } from './tile-item/tile-item.component';
import { ErTileNoDataAvailableComponent } from './tile-no-data-available/tile-no-data-available.component';

@NgModule({
    declarations: [
        ErTileComponent,
        ErTileHeaderComponent,
        ErTileFooterComponent,
        ErTileDividerComponent,
        ErTileItemComponent,
        ErTileBlockComponent,
        ErTileBlockItemComponent,
        ErTileBlockIconItemComponent,
        ErTileNoDataAvailableComponent
    ],
    imports: [CommonModule, MatIconModule, TranslateModule],
    exports: [
        ErTileComponent,
        ErTileHeaderComponent,
        ErTileFooterComponent,
        ErTileDividerComponent,
        ErTileItemComponent,
        ErTileBlockComponent,
        ErTileBlockItemComponent,
        ErTileBlockIconItemComponent,
        ErTileNoDataAvailableComponent
    ]
})
export class ErTileModule {}
