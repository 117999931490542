<div
    #tooltip="matTooltip"
    class="wrapper"
    [matTooltip]="content"
    [matTooltipDisabled]="isLarge"
    [ngClass]="{
        'border-red-60 bg-red-20': isActive,
        'border-grey-50 bg-grey-10': !isActive,
        'block w-5 md:w-6': !isLarge,
        'flex w-fit': isLarge
    }"
    (click)="toggle($event)"
    data-test="button-preferred-trigger"
>
    <mat-icon
        class="!overflow-visible !icon-xs"
        svgIcon="favorite-f-rg-24"
        [ngClass]="{ 'text-red-100': isActive, 'text-grey-50': !isActive }"
    ></mat-icon>
    <span *ngIf="isLarge" class="pl-2" data-test="button-preferred-large-content">{{ content }}</span>
</div>
