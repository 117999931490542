import { AsyncPipe, NgClass, NgIf, UpperCasePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { CUSTOM_DATE_FORMAT } from '@edenred/core/infrastructure';
import { ErPipesModule } from '@edenred/utilities';
import { DateTimeFormatOptions } from 'luxon';

import { ErFeatureFlagDirective } from '../../directives/feature-flag.directive';
import { EComponentFeature } from '../../enums/features';
import { ECardDesignStatus } from './card-design-status.enum';

@Component({
    selector: 'er-card-design',
    templateUrl: './black-card.svg',
    styleUrl: './card-design.component.css',
    imports: [AsyncPipe, NgIf, NgClass, UpperCasePipe, ErPipesModule, ErFeatureFlagDirective],
    standalone: true
})
export class ErCardDesignComponent {
    public eComponentFeature = EComponentFeature;
    public eCardDesignStatus = ECardDesignStatus;

    @Input() public status: string;
    @Input() public maskedCardPan: string;
    @Input() public nickname: string;
    @Input() public expiryDate: string;
    @Input() public expirationDateLabel: string;
    @Input({ required: true }) public accountName: string;
    @Input() public isLoading: boolean;
    @Input() public cardSequenceNumber: number;
    @Input() public cardSequenceNumberLabel: string;

    public expiryDateFormat: DateTimeFormatOptions = CUSTOM_DATE_FORMAT.DATE_TWO_DIGIT_SHORT;

    public get slicedNickName(): string {
        return this.nickname ? this.nickname.slice(0, 21).toUpperCase() : '';
    }

    public get cardNumber(): string {
        return `${this.maskedCardPan ? this.maskedCardPan.slice(-4).toUpperCase() : 'XXXX'}`;
    }

    public get isFrozen(): boolean {
        return this.status === this.eCardDesignStatus.FROZEN;
    }

    public get cardStatus(): string {
        return this.status.toLocaleLowerCase();
    }

    public get isDisabled(): boolean {
        return this.status === this.eCardDesignStatus.BLOCKED || this.status === this.eCardDesignStatus.ON_ORDER;
    }

    public get isCanceled(): boolean {
        return this.status === this.eCardDesignStatus.CANCELED;
    }
}
