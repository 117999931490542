import { Injectable } from '@angular/core';
import { Mapper } from '@edenred/core';

import { IGetTermsOfUsePayload } from '../../../../domain/interfaces';
import { IGetTermsOfUseQuery } from '../../interfaces';

@Injectable({
    providedIn: 'root'
})
export class GetTermsOfUseQueryMapper extends Mapper<IGetTermsOfUsePayload, IGetTermsOfUseQuery> {
    public map(payload: IGetTermsOfUsePayload): IGetTermsOfUseQuery {
        return this.sanitize({
            country_code: payload.countryCode ?? 'DEU'
        });
    }
}
