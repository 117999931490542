<div
    class="{{ messageConfig.background }}"
    [ngClass]="{
        '!bg-transparent': !background,
        'p-4': background,
        'px-0 py-4': !background,
        rounded: isRounded,
        '!p-0': noPadding
    }"
>
    <div class="flex items-start {{ innerClass }}">
        <mat-icon
            class="mr-2 shrink-0 !overflow-visible rounded-full !icon-md md:mt-[2px]"
            [ngClass]="[messageConfig.iconColor, messageConfig.iconBackground]"
            [svgIcon]="messageConfig.icon"
        ></mat-icon>
        <ng-content></ng-content>
    </div>
</div>
