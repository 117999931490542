import { ErrorHandler, inject, Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';

import { FATAL_ERROR_HANDLER_ROUTE_REDIRECTION } from '../../tokens';

@Injectable({
    providedIn: 'root'
})
export class FatalErrorHandler implements ErrorHandler {
    private readonly _route = inject(FATAL_ERROR_HANDLER_ROUTE_REDIRECTION, { optional: true });
    private readonly _zone = inject(NgZone);
    private readonly _router = inject(Router);

    public handleError(e: Error): void {
        if (this._route && (e.message?.includes('FatalErrorException') || e.name?.includes('FatalErrorException'))) {
            void this._zone.run(() => this._router.navigate([this._route]));
        }
    }
}
