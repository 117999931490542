<label class="w-full">
    <h6
        class="mt-0 flex items-end text-sm text-grey-70"
        [ngClass]="{ 'justify-between xl:justify-start': tipDirection === 'right', required }"
    >
        <div>
            <span>{{ label }}</span>
            <span *ngIf="!isRequired" class="ml-2">{{ optionalText }}</span>
        </div>
        <span *ngIf="tipContent" class="m-0 h-[18px]">
            <er-toggletip class="!-mt-[7px]" [tipContent]="tipContent"></er-toggletip>
        </span>
    </h6>
    <p *ngIf="hint?.length" class="mt-2 text-grey-70">{{ hint }}</p>
    <div #content class="relative">
        <ng-content></ng-content>
    </div>
</label>
