/* eslint-disable @typescript-eslint/no-explicit-any */
import { isNil } from 'ramda';
import { catchError, Observable, throwError } from 'rxjs';

import { FatalErrorException } from '../../exceptions/fatal-error/fatal-error.exception';

/**
 * Throws a FatalErrorException in case of error
 * @param matches (optional) Filter error by value
 * @returns Observable
 */
export const throwFatalOnError =
    (matches?: unknown[]): (<T>(source: Observable<T>) => Observable<T>) =>
        <T>(source$: Observable<T>): Observable<T> =>
            source$.pipe(
                catchError((value: any): Observable<T> => {
                    if ((!isNil(value) && isNil(matches)) || matches?.includes(value)) {
                        return throwError(() => new FatalErrorException(value.message));
                    }
                    return throwError(() => value);
                })
            );
