<mat-radio-group
    [disabled]="disabled"
    [ngModel]="stateFormControlProxy.value"
    (ngModelChange)="onPinCodeStateChange($event)"
>
    <ng-container *erFeatureFlag="EComponentFeature.PIN_CHOICER_KEEP_SAME_CODE">
        <mat-radio-button *ngIf="allowKeepSame" [value]="EPinCodeState.KEEP_SAME_PIN" data-test="keep-pin-radio">
            {{ keepSameLabel }}
        </mat-radio-button>
    </ng-container>
    <mat-radio-button [value]="EPinCodeState.GENERATE_PIN" data-test="generate-pin-radio">
        {{ generateLabel }}
    </mat-radio-button>
    <ng-container *erFeatureFlag="EComponentFeature.PIN_CHOICER_CUSTOM_CODE">
        <mat-radio-button [value]="EPinCodeState.CUSTOM_PIN" data-test="choose-pin-radio">
            {{ chooseLabel }}
        </mat-radio-button>
    </ng-container>
</mat-radio-group>

<ng-container *erFeatureFlag="EComponentFeature.PIN_CHOICER_CUSTOM_CODE">
    <div *ngIf="stateFormControlProxy.value === EPinCodeState.CUSTOM_PIN" class="w-[120px]">
        <input
            *erPasswordToggle
            autocomplete="off"
            maxlength="4"
            type="password"
            [value]="codeFormControlProxy.value"
            (keyup)="onValueChange($any($event.target).value)"
            data-test="custom-pin"
        />
    </div>
    <er-error [control]="codeFormControlProxy" data-test="custom-pin-error"></er-error>
</ng-container>
