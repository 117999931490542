import { NgClass, NgIf } from '@angular/common';
import { Component, Input, ViewChild } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltip, MatTooltipModule } from '@angular/material/tooltip';

@Component({
    selector: 'er-button-preferred',
    templateUrl: './button-preferred.component.html',
    styleUrl: './button-preferred.component.css',
    imports: [NgClass, NgIf, MatIconModule, MatTooltipModule],
    standalone: true
})
export class ErButtonPreferredComponent {
    @Input({ required: true }) public isLarge: boolean;
    @Input() public content: string;
    @Input() public isActive = true;

    @ViewChild('tooltip') public tooltip: MatTooltip;

    public toggle($event: Event): void {
        this.tooltip.toggle();
        $event.stopImmediatePropagation();
    }
}
