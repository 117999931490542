import { Component, Input } from '@angular/core';

@Component({
    selector: 'er-tile-block-icon-item',
    templateUrl: './tile-block-icon-item.component.html',
    styleUrls: ['./tile-block-icon-item.component.css']
})
export class ErTileBlockIconItemComponent {
    @Input({ required: true }) public iconSrc: string;
    @Input() public iconAlt!: string;
    @Input({ required: true }) public title: string;
}
