import { Injectable } from '@angular/core';
import { Mapper } from '@edenred/core';
import { ITermsOfUse } from '@edenred/core/modules/authentication-management/domain/interfaces';

import { ITermsOfUseEntity } from '../../interfaces';

@Injectable({
    providedIn: 'root'
})
export class TermsOfUseMapper extends Mapper<ITermsOfUseEntity, ITermsOfUse> {
    public map(data: ITermsOfUseEntity): ITermsOfUse {
        return this.sanitize({
            countryCode: data.country_code,
            version: data.terms_of_usage.last_tofu_version,
            url: data.terms_of_usage.url
        });
    }
}
