import { ConnectedPosition, OverlayModule } from '@angular/cdk/overlay';
import { AsyncPipe, NgClass } from '@angular/common';
import { Component, ElementRef, inject, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { BehaviorSubject, fromEvent, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { ErEnterToClickDirective } from '../../directives/enter-to-click.directive';

@Component({
    selector: 'er-dropdown',
    templateUrl: 'dropdown.component.html',
    styleUrl: 'dropdown.component.css',
    imports: [AsyncPipe, OverlayModule, MatIconModule, NgClass, ErEnterToClickDirective],
    standalone: true
})
export class ErDropdownComponent {
    @Input({ required: true }) public disabled: boolean;

    public width$: Observable<number> = fromEvent(window, 'resize').pipe(
        startWith({}),
        map(() => this.elementRef.nativeElement.clientWidth)
    );

    public positions: ConnectedPosition[] = [
        {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top'
        },
        {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top'
        },
        {
            originX: 'start',
            originY: 'top',
            overlayX: 'start',
            overlayY: 'bottom'
        },
        {
            originX: 'end',
            originY: 'top',
            overlayX: 'end',
            overlayY: 'bottom'
        }
    ];

    public opened$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    public elementRef = inject(ElementRef);

    public toggle(): void {
        if (!this.disabled) {
            this.opened$.next(!this.opened$.getValue());
        }
    }
}
