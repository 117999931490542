import { EPlanCode } from '@edenred/core/modules/authentication-management/infrastructure/repositories/enums';

import { environment } from '../../../../environments/environment';

export const supportedLocalesFactory = (): string[] => {
    const params = new URLSearchParams(window.location.search);
    const planCode = params.get('plan_code') ?? EPlanCode.FALCON;

    switch (planCode.toUpperCase() as EPlanCode) {
        case EPlanCode.EASYFUEL:
            return environment.supportedLocales.easyfuel;
        default:
            return environment.supportedLocales.default;
    }
};
