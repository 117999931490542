import { StoreServiceAbstract } from './abstracts/store.abstract.service';

export class LocalStorageService extends StoreServiceAbstract {
    public set(key: string, value: string): void {
        localStorage.setItem(key, value);
    }

    public get(key: string): string {
        return localStorage.getItem(key) ?? '';
    }
}
