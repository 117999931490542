import { DateTime } from 'luxon';

export class DateHelper {
    public static toISO(utcDate: string): string {
        const date = DateTime.fromISO(utcDate);

        if (!date.isValid) {
            return '';
        }

        return date.toISO()?.toString() ?? '';
    }

    public static toUTC(isoDate: string): string {
        const date = DateTime.fromISO(isoDate);

        if (!date.isValid) {
            return '';
        }

        return date.toUTC().toString();
    }
}
