<er-table-filters-desktop
    *ngIf="isDesktopScreen$() | async; else mobile"
    class="flex w-full items-center justify-end"
    [labels]="labels"
    data-test="table-filters-desktop"
>
    <ng-container *ngTemplateOutlet="content"></ng-container>
</er-table-filters-desktop>

<ng-template #mobile>
    <er-table-filters-mobile [labels]="labels" data-test="table-filters-mobile">
        <ng-container *ngTemplateOutlet="content"></ng-container>
    </er-table-filters-mobile>
</ng-template>

<ng-template #content>
    <ng-content></ng-content>
</ng-template>
