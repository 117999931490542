import { NgClass, NgIf } from '@angular/common';
import { Component, HostListener, Input } from '@angular/core';

import { ErToggletipComponent } from '../toggletip/toggletip.component';

@Component({
    selector: 'er-label',
    templateUrl: './label.component.html',
    styleUrl: './label.component.css',
    imports: [ErToggletipComponent, NgClass, NgIf],
    standalone: true
})
export class ErLabelComponent {
    @Input({ required: true }) public label: string;
    @Input() public required: boolean;
    @Input() public hint: string;
    @Input() public tipContent: string;
    @Input() public tipDirection: 'left' | 'right' = 'left';
    @Input() public optionalText = '(optional)';

    public get isRequired(): boolean {
        return this.required !== false;
    }

    @HostListener('click')
    public onClick(): boolean {
        return false;
    }
}
