import { Component, Input } from '@angular/core';

@Component({
    selector: 'er-tile',
    templateUrl: './tile.component.html',
    styleUrls: ['./tile.component.css']
})
export class ErTileComponent {
    @Input() public emphasized: boolean;
}
