<div
    #origin="cdkOverlayOrigin"
    class="flex flex-row items-center justify-center rounded border !border-grey-30 p-[.44rem]"
    erEnterToClick
    cdk-overlay-origin
    [ngClass]="{
        'focus-within:shadow-focus focus:shadow-focus': opened$ | async,
        'border-grey-30 bg-grey-10 text-grey-30 hover:cursor-default hover:border-grey-30': disabled,
        'hover:cursor-pointer hover:!border-grey-80 ': !disabled
    }"
    (click)="toggle()"
>
    <div class="truncate">
        <ng-content select="[dropdown-trigger]"></ng-content>
    </div>
    <mat-icon class="ml-auto h-7 w-7" [svgIcon]="(opened$ | async) ? 'expand-less-rg-20' : 'expand-more-rg-20'"></mat-icon>
</div>

<ng-template
    cdk-connected-overlay
    cdkConnectedOverlayBackdropClass="''"
    cdkConnectedOverlayHasBackdrop
    [cdkConnectedOverlayOpen]="(opened$ | async) ?? false"
    [cdkConnectedOverlayOrigin]="origin"
    [cdkConnectedOverlayPositions]="positions"
    (backdropClick)="toggle()"
>
    <div class="dropdown-content mt-2 min-w-min rounded border-none bg-white shadow-3" [style.width.px]="width$ | async">
        <ng-content select="[dropdown-content]"></ng-content>
    </div>
</ng-template>
