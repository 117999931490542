<div #origin="cdkOverlayOrigin" class="trigger cursor-pointer" cdk-overlay-origin (click)="open()">
    <ng-content select="[popover-knob]"></ng-content>
</div>

<ng-template
    cdk-connected-overlay
    cdkConnectedOverlayBackdropClass="''"
    cdkConnectedOverlayHasBackdrop
    [cdkConnectedOverlayOpen]="opened"
    [cdkConnectedOverlayOrigin]="origin"
    [cdkConnectedOverlayPositions]="positions"
    (attach)="focus()"
    (backdropClick)="close()"
    (detach)="close()"
>
    <div class="popover-content rounded-lg shadow-3">
        <ng-content select="[popover-content]"></ng-content>
    </div>
</ng-template>
