import { Component, inject, Input, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';

import { ILabels } from '../../../interfaces';

@Component({
    selector: 'er-table-filters-desktop',
    templateUrl: './table-filters-desktop.component.html',
    styleUrls: ['./table-filters-desktop.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class ErTableFiltersDesktopComponent {
    @Input({ required: true }) public labels: Pick<ILabels, 'clear'>;

    private readonly _formGroupDirective = inject(FormGroupDirective);

    public get clearable(): boolean {
        return this._getForm().pristine;
    }

    public clear(): void {
        this._getForm().reset({});
    }

    private _getForm(): FormGroup {
        return this._formGroupDirective.form;
    }
}
