<er-button-icon
    badgeSize="small"
    icon="tune-f-rg-24"
    [badge]="isPristine() ? null : '!'"
    [style]="'secondary'"
    (click)="open()"
    data-test="filters-button"
></er-button-icon>
<ng-template #template>
    <div
        class="mobile-content flex h-full w-full flex-col bg-grey-10 px-5 py-6 transition-all duration-200"
        [ngClass]="{
            'translate-x-3/4 opacity-0 ': !isVisible,
            'translate-x-0 opacity-100': isVisible
        }"
        data-test="table-filters-modal"
    >
        <div class="flex w-full items-center font-medium">
            <div class="w-full">{{ labels['title'] || 'Filters' }}</div>

            <span *ngIf="clearable" class="ml-auto mr-5 cursor-pointer font-sans text-sm uppercase" (click)="clear()" data-test="clear">
                {{ labels['clear'] || 'Clear' }}
            </span>

            <er-button-icon class="ml-auto" icon="close-rg-24" (click)="close()" data-test="close"></er-button-icon>
        </div>
        <div class="mobile-filters mt-6 flex flex-col">
            <ng-content></ng-content>
        </div>
        <div class="mt-auto">
            <button erPrimary (click)="close()" data-test="done">{{ labels['done'] || 'Done' }}</button>
        </div>
    </div>
</ng-template>
