export interface CoreConfig {
    account?: ApiConfig;
    flex?: ApiConfig;
    mulesoft: ApiConfig;
}

export interface ApiConfig {
    host?: string;
    domains: string[];
    headers: HttpHeaders;
}

export interface HttpHeaders {
    [key: string]: string;
}

export const TESTING_CORE_CONFIG: CoreConfig = {
    account: {
        domains: [],
        headers: {}
    },
    mulesoft: {
        domains: [],
        headers: {}
    }
};
