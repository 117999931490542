import { defaultPatterns } from '../patterns';

export const patterns: Record<string, RegExp> = {
    ...defaultPatterns,
    // eslint-disable-next-line no-control-regex
    email: /^(?:[a-z\d!#$%&'*/=?^_`{|}~-]+(?:\.[a-z\d!#$%&'*/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z\d](?:[a-z\d-]*[a-z\d])?\.)+[a-z\d](?:[a-z\d-]*[a-z\d])?|\[(?:(?:(2(5[0-5]|[0-4]\d)|1\d\d|[1-9]?\d))\.){3}(?:(2(5[0-5]|[0-4]\d)|1\d\d|[1-9]?\d)|[a-z\d-]*[a-z\d]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i,
    iban: /^de\d{20}$/i,
    plateNumber: /^[A-ZÄÖÜ]{1,3}[- ]?[A-ZÄÖÜ]{1,2}[- ]?\d{1,4}([- ]?E)?$/i,
    taxId: /^\b(?:\d{2,3}(?:\s?\/\s?|\s)\d{3}(?:\s?\/\s?|\s)\d{5}|\d{3}(?:\s?\/\s?|\s)\d{4}(?:\s?\/\s?|\s)\d{4}|\d{5}(?:\s?\/\s?|\s)\d{5})\b$/,
    vat: /^de\d{9}$/i
};
