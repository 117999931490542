/* eslint-disable @typescript-eslint/no-explicit-any */
import { isNil } from 'ramda';
import { catchError, Observable, OperatorFunction, throwError } from 'rxjs';

/**
 * On error, if error matches, map to new value
 * @param matches Filter error by value
 * @returns value
 */
export const switchMapOnError =
    <T, R>(matches: T[], fn: (value: T) => Observable<R>): OperatorFunction<any, any> =>
        (source$: Observable<unknown>): Observable<unknown> =>
            source$.pipe(
                catchError((value: T): Observable<R> => {
                    if ((!isNil(value) && isNil(matches)) || matches.includes(value)) {
                        return fn(value);
                    }

                    return throwError(() => value);
                })
            );
