import { Observable } from 'rxjs';

export abstract class LocaleServiceAbstract {
    public abstract getLocale$(): Observable<string>;
    public abstract getLocale(): string;
    public abstract getLang$(): Observable<string>;
    public abstract getLang(): string | undefined;
    public abstract setLocale(locale: string): void;
    public abstract getSupportedLocales(): string[];
}
