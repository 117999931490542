import { NgTemplateOutlet } from '@angular/common';
import { AfterViewInit, Component, ElementRef, inject, TemplateRef } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
    selector: 'er-password-toggle',
    templateUrl: './password-toggle.component.html',
    styleUrl: './password-toggle.component.css',
    imports: [MatIconModule, NgTemplateOutlet],
    standalone: true
})
export class ErPasswordToggleComponent implements AfterViewInit {
    public input: HTMLInputElement;

    public isPassword = true;

    public template: TemplateRef<unknown>;

    private readonly _elementRef = inject(ElementRef);

    public ngAfterViewInit(): void {
        this.input = this._elementRef.nativeElement.querySelector('input');
        this.input.classList.add('pr-8');
    }

    public toggle(event: Event): void {
        event.preventDefault();
        event.stopPropagation();
        this.isPassword = !this.isPassword;
        this.input.setAttribute('type', this.isPassword ? 'password' : 'text');
    }
}
