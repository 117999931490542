import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { from, Observable, shareReplay } from 'rxjs';

import { MAPS_CONFIG_TOKEN, MapsConfig } from '../maps.config';

@Injectable({
    providedIn: 'root'
})
export class ApiLoaderService {
    private readonly _http = inject(HttpClient);
    private readonly _config: MapsConfig = inject(MAPS_CONFIG_TOKEN);
    private readonly _document = inject(DOCUMENT);

    private readonly _script: HTMLScriptElement = document.createElement('script');
    // eslint-disable-next-line @typescript-eslint/prefer-readonly
    private _src = `${this._config.baseUrl}/js?key=${this._config.apiKey}&libraries=geometry,places&callback=Function.prototype`;
    private readonly _loadGoogleMaps$: Observable<boolean> = from(
        new Promise<boolean>(resolve => {
            /* istanbul ignore next */
            this._script.onload = (): void => {
                resolve(true);
            };
            /* istanbul ignore next */
            this._script.onerror = (): void => {
                resolve(false);
            };
            this._script.src = this._src;
            this._document.body.appendChild(this._script);
        })
    ).pipe(shareReplay(1));

    public load$(): Observable<boolean> {
        return this._loadGoogleMaps$;
    }
}
