import { ConnectedPosition, OverlayModule } from '@angular/cdk/overlay';
import { DOCUMENT } from '@angular/common';
import { Component, inject, Input } from '@angular/core';

@Component({
    selector: 'er-popover',
    templateUrl: './popover.component.html',
    styleUrl: './popover.component.css',
    imports: [OverlayModule],
    standalone: true
})
export class ErPopoverComponent {
    private readonly _document: Document = inject(DOCUMENT);

    @Input() public positions: ConnectedPosition[] = [
        {
            originX: 'start',
            originY: 'top',
            overlayX: 'end',
            overlayY: 'top'
        },
        {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'center',
            overlayY: 'bottom'
        }
    ];

    public opened: boolean;

    public open(): void {
        this.opened = true;
    }

    public focus(): void {
        (this._document.body.querySelector('.popover-content') as HTMLElement)?.focus();
    }

    public close(): void {
        this.opened = false;
    }
}
