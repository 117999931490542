import { Directive, HostListener } from '@angular/core';

@Directive({
    selector: '[erEnterToClick]',
    standalone: true
})
export class ErEnterToClickDirective {
    @HostListener('keydown.enter', ['$event'])
    public convertToClick($event: Event): void {
        ($event.target as HTMLElement).click();
    }
}
