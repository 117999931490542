import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, inject, Input, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import { DateRange } from '@angular/material/datepicker';
import { DateTime } from 'luxon';

import { ErDatePickerBase } from '../date-picker-base';

@Component({
    selector: 'er-date-range-picker',
    templateUrl: './date-range-picker.component.html',
    styleUrl: './date-range-picker.component.css',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => ErDateRangePickerComponent),
            multi: true
        },
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ErDateRangePickerComponent),
            multi: true
        }
    ]
})
export class ErDateRangePickerComponent extends ErDatePickerBase<DateRange<DateTime>> implements ControlValueAccessor {
    @Input() public includeLastDay = true;
    @Input() public override min = DateTime.now().minus({ month: 12 });
    @Input() public override max = DateTime.now();
    @Input({ required: true }) public labels: { start: string; end: string } = { start: 'Start date', end: 'End date' };

    public selectedDateRange: DateRange<DateTime> = new DateRange<DateTime>(null, null);

    public get value(): DateRange<DateTime> {
        return this.selectedDateRange;
    }

    private readonly _changeDetectorRef = inject(ChangeDetectorRef);

    public validate(formControl: FormControl<DateRange<DateTime>>): ValidationErrors | null {
        return formControl?.value?.start && formControl?.value?.end ? null : { required: true };
    }

    public writeValue(range: DateRange<DateTime>): void {
        this.selectedDateRange = range ?? {};
        this._changeDetectorRef.markForCheck();
    }

    public onStartDateChange(date: DateTime): void {
        this.selectedDateRange = new DateRange(date, null);
        this.onChange(this.selectedDateRange);
        this._changeDetectorRef.markForCheck();
    }

    public onEndDateChange(date: DateTime): void {
        this.selectedDateRange = new DateRange(this.selectedDateRange.start, this.includeLastDay ? date?.endOf('day') ?? null : date);
        this.onChange(this.selectedDateRange);
        this._changeDetectorRef.markForCheck();
    }

    public format(dateRange: DateRange<DateTime>): string {
        const end = dateRange.end ? dateRange.end.toLocaleString(this.dateFormat) : this.labels.end;
        return dateRange?.start ? `${this.value.start?.toLocaleString(this.dateFormat)} - ${end}` : '';
    }

    public setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
        this._changeDetectorRef.markForCheck();
    }
}
