import { isNil } from 'ramda';

export class TextHelper {
    public static capitalize(str: string): string {
        return str.charAt(0).toLocaleUpperCase() + str.substring(1).toLocaleLowerCase();
    }

    public static toTitleCase(str: string): string {
        const latin1charsWithoutSpaceChars =
            '\x21-\x2c\x2e-\x7E\xA0-\xA3\xA5\xA7\xA9-\xB3\xB5-\xB7\xB9-\xBB\xBF-\xFF\u20AC\u0160\u0161\u017D\u017E\u0152\u0153\u0178';
        const regex = new RegExp(`[${latin1charsWithoutSpaceChars}][${latin1charsWithoutSpaceChars}]*`, 'g');
        return str.replace(regex, function (txt) {
            const split = txt.split("'");
            const transformStr = (value: string): string => value.charAt(0).toUpperCase() + value.substring(1).toLowerCase();

            if (split.length > 1) {
                const [tail] = split.slice(1).concat();
                const [head] = split;
                return `${head}'${transformStr(tail)}`;
            }

            return transformStr(txt);
        });
    }

    public static insensitiveCompare(strA: string | null | undefined, strB: string | null | undefined): boolean {
        if (isNil(strA) || isNil(strB)) {
            return false;
        }

        const formattedStrA = strA.trim().toLowerCase().replace(/\s+/g, ' ');
        const formattedStrB = strB.trim().toLowerCase().replace(/\s+/g, ' ');

        return formattedStrA === formattedStrB;
    }
}
