import { Component, Input } from '@angular/core';

type HintType = 'info' | 'error' | 'success' | 'warning';

@Component({
    selector: 'er-hint',
    templateUrl: './hint.component.html',
    styleUrl: './hint.component.css',
    standalone: true
})
export class ErHintComponent {
    @Input({ required: true }) public type: HintType;
}
