import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { IViewModel } from '../../../../domain/interfaces';
import { AUTHENTICATION_MANAGEMENT_TOKEN } from '../../authentication-management.token';
import {
    IActivateAccountPayload,
    IForgotPasswordPayload,
    IResetPasswordPayload,
    IRetrieveUsernameIdPayload,
    IUser
} from '../../domain/interfaces';
import { AuthenticationManagementRepositoryAbstract } from '../../domain/repositories/authentication-management.repository.abstract';
import { IAuthenticationManagement } from '../config/authentication-management.interface';
import { CreatePasswordExceptionHandler } from './handlers/create-password-exception.handler';
import { ForgotPasswordExceptionHandler } from './handlers/forgot-password-exception.handler';
import { RetrieveUsernameIdExceptionHandler } from './handlers/retrieve-username-id-exception.handler';
import {
    IActivateAccountBody,
    IForgotPasswordQuery,
    IResetPasswordQuery,
    IRetrieveUsernameIdQuery,
    IRetrieveUsernameIdResponse
} from './interfaces';
import { ActivateAccountBodyMapper } from './mappers/bodies/activate-account-body.mapper';
import { ForgotPasswordQueryMapper } from './mappers/queries/forgot-password-query.mapper';
import { ResetPasswordQueryMapper } from './mappers/queries/reset-password-query.mapper';
import { RetrieveUsernameIdQueryMapper } from './mappers/queries/retrieve-username-id-query.mapper';
import { RetrieveUsernameIdPresenter } from './presenters/retrieve-username-id.presenter';

@Injectable({ providedIn: 'root' })
export class AuthenticationManagementRepository extends AuthenticationManagementRepositoryAbstract {
    private readonly _authenticationManagement: IAuthenticationManagement = inject(AUTHENTICATION_MANAGEMENT_TOKEN);
    private readonly _http = inject(HttpClient);
    private readonly _retrieveUsernameIdPresenter = inject(RetrieveUsernameIdPresenter);
    private readonly _forgotPasswordQueryMapper = inject(ForgotPasswordQueryMapper);
    private readonly _resetPasswordQueryMapper = inject(ResetPasswordQueryMapper);
    private readonly _createPasswordBodyMapper = inject(ActivateAccountBodyMapper);
    private readonly _retrieveUsernameIdQueryMapper = inject(RetrieveUsernameIdQueryMapper);
    private readonly _createPasswordExceptionHandler = inject(CreatePasswordExceptionHandler);
    private readonly _forgetPasswordExceptionHandler = inject(ForgotPasswordExceptionHandler);
    private readonly _retrieveUsernameIdExceptionHandler = inject(RetrieveUsernameIdExceptionHandler);

    public forgetPassword$(payload: IForgotPasswordPayload): Observable<null> {
        const endpoint = `${this._authenticationManagement.hosts.userManagement}${this._authenticationManagement.endpoints.forgetPassword}`;
        const body: IForgotPasswordQuery = this._forgotPasswordQueryMapper.map(payload);

        return this._http.post(endpoint, body).pipe(
            map(() => null),
            catchError((error: HttpErrorResponse) =>
                throwError(() => this._forgetPasswordExceptionHandler.handle(error))
            )
        );
    }

    public resetPassword$(payload: IResetPasswordPayload): Observable<IViewModel<boolean>> {
        const endpoint = `${this._authenticationManagement.hosts.userManagement}${this._authenticationManagement.endpoints.resetPassword}`;
        const body: IResetPasswordQuery = this._resetPasswordQueryMapper.map(payload);

        return this._http.post(endpoint, body).pipe(map(() => ({ data: true })));
    }

    public createPassword$(payload: IActivateAccountPayload): Observable<null> {
        const endpoint = `${this._authenticationManagement.hosts.userManagement}${this._authenticationManagement.endpoints.activateAccount}`;
        const body: IActivateAccountBody = this._createPasswordBodyMapper.map(payload);

        return this._http.post(endpoint, body).pipe(
            map(() => null),
            catchError((error: HttpErrorResponse) =>
                throwError(() => this._createPasswordExceptionHandler.handle(error))
            )
        );
    }

    public retrieveUsernameId$(payload: IRetrieveUsernameIdPayload): Observable<IViewModel<IUser>> {
        const endpoint = `${this._authenticationManagement.hosts.userManagement}${this._authenticationManagement.endpoints.retrieveUsernameId}`;
        const body: IRetrieveUsernameIdQuery = this._retrieveUsernameIdQueryMapper.map(payload);

        return this._http.post(endpoint, body).pipe(
            map(response => this._retrieveUsernameIdPresenter.getViewModel(response as IRetrieveUsernameIdResponse)),
            catchError((error: HttpErrorResponse) =>
                throwError(() => this._retrieveUsernameIdExceptionHandler.handle(error))
            )
        );
    }
}
