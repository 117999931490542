<er-tile class="h-full px-4 pb-4" [ngClass]="{ 'pt-2': title || actionText, 'pt-4': !title && !actionText }">
    <div *ngIf="title || actionText" class="flex w-full flex-row items-center justify-between">
        <h6 class="m-0 min-h-7 p-2 leading-10">
            {{ title }}
        </h6>
        <button
            *ngIf="(state$ | async) === EWidgetState.LOADED && isActionVisible !== false"
            class="clear-button w-auto text-grey-50"
            [disabled]="isActionDisabled"
            (click)="action.emit()"
        >
            {{ actionText }}
        </button>
    </div>
    <er-tile-block class="flex h-full flex-col {{ customClass }}">
        <ng-container *ngIf="(state$ | async) === EWidgetState.LOADING && hasPlaceholder">
            <ng-content select="[placeholder]" />
        </ng-container>

        <ng-container *ngIf="(state$ | async) === EWidgetState.LOADED || ((state$ | async) === EWidgetState.LOADING && !hasPlaceholder)">
            <ng-content />
        </ng-container>

        <ng-container *ngIf="(state$ | async) === EWidgetState.ERROR">
            <er-tile-no-data-available />
        </ng-container>
    </er-tile-block>
</er-tile>
