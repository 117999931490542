import { clone, filter, is, isEmpty, isNil } from 'ramda';

export abstract class Mapper<T, U> {
    private _isObjectLike(value: unknown): boolean {
        return typeof value === 'object' && value !== null;
    }

    private _isPlainObject(value: unknown): boolean {
        return this._isObjectLike(value) && !Array.isArray(value);
    }

    public abstract map(data: T): U;

    public sanitize<K = U>(data: K): K {
        return this._removeFalsy<K>(data);
    }

    public recursivelySanitize<K = U>(data: K): K {
        return this._recursivelyRemoveFalsy<K>(data);
    }

    private _removeFalsy<K>(obj: K): K {
        if (this._isPlainObject(obj)) {
            const junkValues = new Set([undefined, null, '']);

            return Object.entries(obj as object)
                .filter(([, value]) => !junkValues.has(value as string))
                .reduce((r, [key, value]) => ({ ...r, [key]: this._removeFalsy(value) }), {}) as K;
        }

        return obj;
    }

    private _recursivelyRemoveFalsy<K>(obj: K): K {
        const notJunk: (v: unknown) => boolean = value =>
            !(isNil(value) || isEmpty(value) || is(Function, value) || Number.isNaN(value));

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        const prune = current => {
            Object.keys(current).forEach(key => {
                if (this._isObjectLike(current[key])) {
                    current[key] = filter(notJunk, prune(current[key]));
                }
            });

            return filter(notJunk, current);
        };

        return prune(clone(obj));
    }
}
