import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IViewModel } from '../../domain';
import { FatalErrorException } from '../../domain/exceptions/fatal-error/fatal-error.exception';

@Injectable({
    providedIn: 'root'
})
export class HttpExceptionHandler<T = null> {
    public handle(response: HttpErrorResponse): Observable<IViewModel<T>> {
        throw new FatalErrorException(response.error);
    }
}
