import { Directive, ElementRef, inject, OnInit, Renderer2 } from '@angular/core';

@Directive({
    selector: '[erSecondary]',
    standalone: true
})
export class ErSecondaryDirective implements OnInit {
    private readonly _element = inject(ElementRef);
    private readonly _renderer = inject(Renderer2);

    public ngOnInit(): void {
        this._renderer.addClass(this._element.nativeElement, 'secondary');
    }
}
