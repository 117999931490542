@switch (type()) { @case ('icon-text') {
<div class="flex w-full flex-row" data-test="table-placeholder-icon-text">
    <div class="aspect-square rounded-full bg-grey-20"></div>
    <div class="ml-4 h-full w-3/4 rounded bg-grey-20"></div>
</div>
} @case ('icon') {
<div class="flex flex-row" data-test="table-placeholder-icon">
    <div class="aspect-square rounded-full bg-grey-20"></div>
</div>
} @default {
<div class="h-full w-full rounded bg-grey-20" data-test="table-placeholder-default"></div>
} }
