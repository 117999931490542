import { ErrorHandler, Provider } from '@angular/core';
import { Router } from '@angular/router';
import { createErrorHandler, TraceService } from '@sentry/angular-ivy';

import { CustomErrorHandler, FatalErrorHandler } from '../exceptions';
import { CUSTOM_ERROR_HANDLER } from '../tokens';

export const errorProviders: Provider[] = [
    {
        provide: CUSTOM_ERROR_HANDLER,
        multi: true,
        useValue: createErrorHandler({
            showDialog: false,
            logErrors: true
        })
    },
    {
        provide: TraceService,
        deps: [Router]
    },
    {
        provide: CUSTOM_ERROR_HANDLER,
        multi: true,
        useClass: FatalErrorHandler
    },
    {
        provide: ErrorHandler,
        useClass: CustomErrorHandler
    }
];
