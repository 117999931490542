import { Injectable } from '@angular/core';

import { Mapper } from '../../services/mapper.abstract.service';

@Injectable({
    providedIn: 'root'
})
export class SemmLanguageMapper extends Mapper<string, string> {
    public map(languageCode: string): string {
        switch (languageCode) {
            case 'de':
                return 'de';
            default:
                return 'en';
        }
    }
}
