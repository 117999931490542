import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { IMetadata } from '@edenred/core';

@Component({
    selector: 'er-table-paginator',
    templateUrl: './table-paginator.component.html',
    styleUrl: './table-paginator.component.css',
    encapsulation: ViewEncapsulation.None
})
export class ErTablePaginatorComponent {
    @Input({ required: true }) public metadata: IMetadata;
    @Output() public page: EventEmitter<PageEvent> = new EventEmitter();
}
