<label class="group relative inline-flex flex-row items-start" [ngClass]="{ 'text-grey-50': isDisabled }" (click)="toggle($event)">
    <div
        class="mr-3 inline-block h-5 w-5 shrink-0 overflow-hidden rounded-[.25rem] border border-grey-50 text-white focus-within:border-grey-30 focus-within:bg-white focus-within:drop-shadow hover:ring-1 hover:ring-primary hover:ring-offset-1"
        [ngClass]="{
            'border-grey-30 bg-grey-10 hover:ring-0 hover:ring-offset-0': isDisabled,
            'group-hover:border-primary': !isDisabled
        }"
    >
        <input
            #input
            class="absolute h-1 w-1 appearance-none border-none opacity-0 outline-none clip-path-none"
            type="checkbox"
            data-test="er-checkbox-input"
        />

        <mat-icon
            *ngIf="!undetermined && formControl?.value"
            class="-ml-[1px] -mt-[1px] bg-primary"
            svgIcon="done-rg-24"
            [ngClass]="{ 'bg-grey-20': isDisabled }"
        ></mat-icon>

        <mat-icon
            *ngIf="undetermined"
            class="-ml-[1px] -mt-[1px] bg-primary"
            svgIcon="horizontal-rule-rg-24"
            [ngClass]="{ 'bg-grey-20': isDisabled }"
        ></mat-icon>
    </div>
    <ng-content></ng-content>
</label>
