import { AsyncPipe, NgIf } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { EErrorMessages, ErrorKey } from '@edenred/utilities';
import { TranslateServiceAbstract } from '@edenred-falcon/shared/angular/i18n';
import { Observable, of } from 'rxjs';

@Component({
    selector: 'er-error',
    templateUrl: './error.component.html',
    imports: [AsyncPipe, MatIconModule, NgIf],
    standalone: true
})
export class ErErrorComponent {
    @Input({ required: true }) public control: FormControl<unknown>;

    private readonly _translateService = inject(TranslateServiceAbstract);

    public get key$(): Observable<string | null> {
        const [errorKey] = Object.keys(this.control.errors ?? {});

        if (errorKey && EErrorMessages[errorKey as ErrorKey]) {
            const { key, vars } = EErrorMessages[errorKey as ErrorKey](this.control);
            return this._translateService.stream$(key, vars);
        }

        return of(null);
    }
}
