import { OverlayModule } from '@angular/cdk/overlay';
import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { ErPipesModule } from '@edenred/utilities';
import { TranslateModule } from '@edenred-falcon/shared/angular/i18n';

import { ErDirectivesModule } from '../../directives.module';
import { ErGhostDirective } from '../../directives/ghost.directive';
import { ErButtonIconComponent } from '../button-icon/button-icon.component';
import { ErDatePickerModule } from '../datepicker/date-picker.module';
import { ErExpandableModule } from '../expandable/expandable.module';
import { ErTableButtonActionComponent } from './components/button-action/table-button-action.component';
import { ErTableFiltersDesktopComponent } from './components/filters/desktop/table-filters-desktop.component';
import { ErTableFiltersComponent } from './components/filters/filters/table-filters.component';
import { ErTableFiltersMobileComponent } from './components/filters/mobile/table-filters-mobile.component';
import { ErTableFooterComponent } from './components/footer/table-footer.component';
import { ErTablePaginatorComponent } from './components/paginator/table-paginator.component';
import { ErTablePlaceholderComponent } from './components/placeholder/table-placeholder.component';

@NgModule({
    declarations: [
        ErTablePlaceholderComponent,
        ErTableFooterComponent,
        ErTablePaginatorComponent,
        ErTableFiltersComponent,
        ErTableButtonActionComponent,
        ErTableFiltersDesktopComponent,
        ErTableFiltersMobileComponent
    ],
    imports: [
        CommonModule,
        CdkTableModule,
        MatPaginatorModule,
        ErPipesModule,
        TranslateModule,
        MatSortModule,
        ErButtonIconComponent,
        ReactiveFormsModule,
        MatIconModule,
        ErDatePickerModule,
        ErDirectivesModule,
        ErExpandableModule,
        MatIconModule,
        OverlayModule,
        ErGhostDirective
    ],
    exports: [
        ErTablePlaceholderComponent,
        ErTableFooterComponent,
        ErTablePaginatorComponent,
        ErTableFiltersComponent,
        ErTableFiltersDesktopComponent,
        ErTableFiltersMobileComponent,
        ErExpandableModule,
        ErTableButtonActionComponent,
        CdkTableModule,
        MatSortModule
    ]
})
export class ErTableModule {}
