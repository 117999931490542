import { IViewModel } from '@edenred/core';
import { ITermsOfUse } from '@edenred/core/modules/authentication-management/domain/interfaces';
import { Observable } from 'rxjs';

import { IGetTermsOfUsePayload } from '../interfaces';
import { TermsRepositoryAbstract } from '../repositories/terms.repository.abstract';
import { GetTermsOfUseUseCaseAbstract } from './terms.usecase.abstract';

export class GetTermsOfUseUseCase extends GetTermsOfUseUseCaseAbstract {
    constructor(private readonly _repository: TermsRepositoryAbstract) {
        super();
    }

    public get$(payload: IGetTermsOfUsePayload): Observable<IViewModel<ITermsOfUse>> {
        return this._repository.get$(payload);
    }
}
