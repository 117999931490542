import { NgClass } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

import { EMessageType } from '../../enums/message-type.enum';
import { IMessageConfig, IMessageType } from '../../interfaces/messages.interfaces';

interface IAlertData {
    type: EMessageType;
    content: string;
}

@Component({
    selector: 'er-alert',
    templateUrl: './alert.component.html',
    imports: [MatIconModule, NgClass],
    standalone: true
})
export class ErAlertComponent {
    public data: IAlertData = inject(MAT_SNACK_BAR_DATA);
    public snackBarRef = inject(MatSnackBarRef<ErAlertComponent>);

    public types: Record<string, IMessageType> = IMessageConfig;
}
