<div
    class="pointer-events-auto mt-[76px] flex items-center rounded p-4 shadow-3 md:mt-0"
    [ngClass]="types[data.type].background"
    data-test="alert"
>
    <mat-icon
        class="mr-auto shrink-0 rounded-full"
        [attr.data-test]="'message-type-' + data.type"
        [ngClass]="types[data.type].iconColor + types[data.type].iconBackground"
        [svgIcon]="types[data.type].icon"
    ></mat-icon>
    <div class="mx-2 font-sans">
        {{ data.content }}
    </div>
    <mat-icon
        class="ml-auto shrink-0 cursor-pointer text-primary"
        svgIcon="close-rg-24"
        (click)="snackBarRef.dismiss()"
        data-test="alert-close"
    ></mat-icon>
</div>
