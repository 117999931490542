import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
    Component,
    inject,
    Input,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation
} from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { BreakpointService, isVoid } from '@edenred/utilities';
import { pickBy } from 'ramda';
import { delay, EMPTY, Observable, of, Subscription, tap } from 'rxjs';

import { ILabels } from '../../../interfaces';

@Component({
    selector: 'er-table-filters-mobile',
    templateUrl: './table-filters-mobile.component.html',
    styleUrls: ['./table-filters-mobile.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class ErTableFiltersMobileComponent implements OnInit, OnDestroy {
    @Input({ required: true }) public labels: ILabels;
    @ViewChild('template') public template: TemplateRef<unknown>;

    private _overlayRef: OverlayRef;
    private _subscription: Subscription;

    public isVisible = false;

    private readonly _overlay = inject(Overlay);
    private readonly _breakpointService = inject(BreakpointService);
    private readonly _vcr = inject(ViewContainerRef);
    private readonly _formGroupDirective = inject(FormGroupDirective);

    public ngOnInit(): void {
        this._subscription = this.isDesktopScreen$().subscribe((isDesktop: boolean) => {
            if (this._overlayRef?.hasAttached() && isDesktop) {
                this.close();
            }
        });
    }

    public get clearable(): boolean {
        return !this._getForm().pristine;
    }

    public clear(): void {
        this._getForm().reset({});
        this._getForm().markAsPristine();
    }

    public isPristine(): boolean {
        return (
            this._getForm().pristine ||
            isVoid(pickBy((value: unknown) => !isVoid(value), this._getForm().getRawValue()))
        );
    }

    public close(): void {
        this.isVisible = false;

        of(EMPTY)
            .pipe(
                delay(200),
                tap(() => {
                    this._overlayRef?.detach();
                    this._overlayRef?.dispose();
                })
            )
            .subscribe();
    }

    public isDesktopScreen$(): Observable<boolean> {
        return this._breakpointService.isDesktopScreen$();
    }

    public open(): void {
        this._overlayRef = this._overlay.create({
            positionStrategy: this._overlay.position().global(),
            width: '100vw',
            height: '100vh'
        });

        this._overlayRef.attach(new TemplatePortal(this.template, this._vcr));

        of(EMPTY)
            .pipe(
                delay(0),
                tap(() => {
                    this.isVisible = true;
                })
            )
            .subscribe();
    }

    public ngOnDestroy(): void {
        this._subscription.unsubscribe();
        this.close();
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private _getForm(): FormGroup<any> {
        return this._formGroupDirective.form;
    }
}
