/* istanbul ignore file */

import { Observable } from 'rxjs';

export abstract class Store<T = string> {
    public abstract set(key: T, value: unknown): this;
    public abstract delete(key: T): boolean;
    public abstract get(key: T): unknown;
    public abstract has(key: T): boolean;
    public abstract clear(): void;
    public abstract asObservable$(): Observable<unknown>;
}
