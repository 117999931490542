<div class="m-0 flex flex-row items-center justify-between p-3">
    <button
        class="contents whitespace-nowrap"
        cdkAriaLive="polite"
        mat-button
        [attr.aria-label]="periodButtonLabel"
        (click)="currentPeriodClicked()"
        data-test="datepicker-switch-view"
    >
        <span class="text-base" data-test="datepicker-period-text">{{ periodButtonText }}</span>
        <svg
            class="ml-2 inline-block h-2 w-2"
            focusable="false"
            viewBox="0 0 10 5"
            [ngClass]="{ 'rotate-180': calendar.currentView !== 'month' }"
        >
            <polygon class="mat-calendar-arrow" points="0,0 5,5 10,0" />
        </svg>
    </button>

    <div class="flex-auto"></div>

    <button
        class="contents p-2"
        mat-icon-button
        [attr.aria-label]="prevButtonLabel"
        [disabled]="!previousEnabled()"
        [ngClass]="{ 'text-red-100': previousEnabled(), 'text-grey-30': !previousEnabled() }"
        (click)="previousClicked()"
        data-test="datepicker-previous-period"
    >
        <mat-icon svgIcon="chevron-left-rg-20"></mat-icon>
    </button>

    <button
        class="contents p-2"
        mat-icon-button
        [attr.aria-label]="nextButtonLabel"
        [disabled]="!nextEnabled()"
        [ngClass]="{ 'text-red-100': nextEnabled(), 'text-grey-30': !nextEnabled() }"
        (click)="nextClicked()"
        data-test="datepicker-next-period"
    >
        <mat-icon svgIcon="chevron-right-rg-20"></mat-icon>
    </button>
</div>
