import { inject, Injectable, OnDestroy } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { LocaleServiceAbstract, TranslateServiceAbstract } from '@edenred-falcon/shared/angular/i18n';
import { Subject, takeUntil } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class IntlPaginatorService extends MatPaginatorIntl implements OnDestroy {
    public pagination = '';

    private readonly _destroy$: Subject<null> = new Subject();
    private readonly _translateService = inject(TranslateServiceAbstract);
    private readonly _localeService = inject(LocaleServiceAbstract);

    constructor() {
        super();
        this._init();
    }

    public override getRangeLabel: (page: number, pageSize: number, length: number) => string = (
        page: number,
        pageSize: number,
        length: number
    ) => {
        if (length === 0 || pageSize === 0) {
            return this._translateService.instant('general.pagination_one_page', { length });
        }

        const maxLength = Math.max(length, 0);

        const startIndex = page * pageSize;

        const endIndex = this._getEndIndex(startIndex, maxLength, pageSize);

        return this._translateService.instant('general.pagination', {
            startIndex: startIndex + 1,
            endIndex,
            total: maxLength
        });
    };

    /* istanbul ignore next */
    public ngOnDestroy(): void {
        this._destroy$.next(null);
        this._destroy$.complete();
    }

    private _init(): void {
        this._localeService
            .getLang$()
            .pipe(
                takeUntil(this._destroy$),
                switchMap(() =>
                    this._translateService.get$(['general.pagination_next_page', 'general.pagination_previous_page'])
                )
            )
            .subscribe(translation => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                this.nextPageLabel = translation['general.pagination_next_page'];
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                this.previousPageLabel = translation['general.pagination_previous_page'];
                this.changes.next();
            });
    }

    // Material rule
    // https://github.com/angular/components/blob/main/src/material/paginator/paginator-intl.ts
    private _getEndIndex(startIndex: number, maxLength: number, pageSize: number): number {
        return startIndex < maxLength ? Math.min(startIndex + pageSize, maxLength) : startIndex + pageSize;
    }
}
