import { AsyncPipe, NgIf } from '@angular/common';
import { Component, EventEmitter, forwardRef, inject, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    FormControl,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    ValidatorFn
} from '@angular/forms';
import { EErrorMessages, ErrorKey } from '@edenred/utilities';
import { TranslateModule, TranslateServiceAbstract } from '@edenred-falcon/shared/angular/i18n';
import { delay, distinctUntilChanged, Observable, of, Subject, takeUntil } from 'rxjs';

export interface ErWarningStatus {
    enabled: boolean;
}

@Component({
    selector: 'er-warning',
    standalone: true,
    templateUrl: './warning.component.html',
    imports: [AsyncPipe, NgIf, TranslateModule],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ErWarningComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => ErWarningComponent),
            multi: true
        }
    ]
})
export class ErWarningComponent implements OnChanges, OnDestroy, Validator, ControlValueAccessor, OnInit {
    @Input({ required: true }) public validators: ValidatorFn[];

    @Output() public statusChange: EventEmitter<ErWarningStatus> = new EventEmitter<ErWarningStatus>();

    private _control: FormControl;
    private readonly _proxyControl = new FormControl();
    private readonly _onDestroy$ = new Subject();
    private readonly _statusChange$ = new Subject<boolean>();

    private readonly _translateService = inject(TranslateServiceAbstract);

    public writeValue(value: string): void {
        this._proxyControl.setValue(value);
    }

    public registerOnChange(): void {
        // This method is required by ControlValueAccessor interface.
    }

    public registerOnTouched(): void {
        // This method is required by ControlValueAccessor interface.
    }

    public get key$(): Observable<string | null> {
        const [errorKey] = Object.keys(this._proxyControl.errors ?? {});

        if (errorKey && EErrorMessages[errorKey as ErrorKey]) {
            const { key, vars } = EErrorMessages[errorKey as ErrorKey](this._proxyControl);
            return this._translateService.stream$(key, vars);
        }

        return of(null);
    }

    public ngOnChanges(): void {
        this._proxyControl.clearValidators();
        this._proxyControl.addValidators(this.validators);
        this._proxyControl.updateValueAndValidity();
    }

    public ngOnInit(): void {
        this._statusChange$.pipe(distinctUntilChanged(), delay(0), takeUntil(this._onDestroy$)).subscribe(enabled => {
            this.statusChange.emit({ enabled });
        });
    }

    public get enabled(): boolean {
        return this._proxyControl.invalid && this._control.valid && this._control.enabled;
    }

    public ngOnDestroy(): void {
        this._onDestroy$.next(null);
        this._onDestroy$.complete();
    }

    public setDisabledState(): void {
        if (this._control) {
            this.validate(this._control);
        }
    }

    public validate(control: AbstractControl): ValidationErrors | null {
        this._control = control as FormControl<string>;
        this._proxyControl.setValue(control.value);
        this._statusChange$.next(this.enabled);

        return null;
    }
}
